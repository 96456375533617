import React, { ReactElement } from "react";

import { Web3Context } from "../useWeb3Context";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { useWeb3React } from "@web3-react/core";
import { Provider } from "@ethersproject/abstract-provider";
import { ethers, providers } from "ethers";
import { useAuthorizedConnection } from "../useAuthorizedConnection";

export type Web3Data = {
  connectWallet: (connector: AbstractConnector) => Promise<void>;
  disconnectWallet: () => void;
  account: string;
  connected: boolean;
  provider: Provider;
  chainId: number;
  signer: ethers.providers.JsonRpcSigner | undefined;
};

export const Web3ContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const {
    account,
    chainId,
    library: provider,
    activate,
    active,
    deactivate,
  } = useWeb3React<providers.Web3Provider>();

  useAuthorizedConnection();

  const mockAddress = "0x7063e5BDFeeE983403dD7E2858bA669a5AF4B061";

  const url = `https://api.yeti.finance/v1/sanction/${account}`;

  if (active) {
    try {
      fetch(url, { method: "GET", mode: "cors" })
        .then(function (response) {
          if (response.ok) {
            return response.json();
          }
          const err = new Error("sanction Error");
          throw err;
        })
        .then(function (result) {
          if (result !== undefined) {
            // console.log("sanction:", result);
            if (result["sanctioned"]) {
              deactivate();
            }
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <Web3Context.Provider
      value={{
        web3ProviderData: {
          connectWallet: activate,
          disconnectWallet: deactivate,
          provider: provider
            ? provider
            : new ethers.providers.JsonRpcProvider(
                "https://api.avax.network/ext/bc/C/rpc"
              ),
          connected: active,
          chainId: chainId || 43114,
          account: account?.toLowerCase() || mockAddress,
          signer: provider?.getSigner(),
        },
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
