import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Hide,
  Show,
  // Hide,
  // Hide,
  Text,
  useDisclosure,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import AdjustBorrowAmount from "../AdjustBorrowAmount";
import AdjustCollateral from "../AdjustCollateral";
import ConfirmChangesModal from "../ConfirmChangesModal";
import { TransactionProgressDonut } from "../../../Components/Transaction";
import { LiquityStoreState, Decimal, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { useLiquity } from "../../../Hooks/LiquityContext";
import tokenData from "../../../TokenData";
import {
  checkCollateral,
  checkBorrow,
  getAffectedCollateral,
  calculateTotalYUSDFromLever,
  calculateVcValue,
  calculateAvcValue,
  calculateUsdValue,
  getTroveBalance,
} from "./AdjustTrove.utils";
import { format, formatWithDecimals } from "../../../Utils/number";
import { CoinMode } from "../../../Types";
import { FormApi } from "final-form";
import CollateralChart from "./CollateralChart";
import { BorrowSummary } from "../../Dashboard";

type AdjustTroveComponentsProps = {
  disconnected: boolean;
  borrowMode: "normal" | "lever" | "unlever";
  setBorrowMode: any;
  values: { [key: string]: any };
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
};

const selector = ({
  trove,
  underlyingPrices,
  prices,
  tokenBalances,
  borrowingRate,
  decimals,
  safetyRatios,
  recoveryRatios,
  underlyingPerReceiptRatios,
  receiptPerUnderlyingRatios,
}: LiquityStoreState) => ({
  borrowingRate,
  trove,
  underlyingPrices,
  prices,
  tokenBalances,
  decimals,
  safetyRatios,
  recoveryRatios,
  underlyingPerReceiptRatios,
  receiptPerUnderlyingRatios,
});

const BreakException = {};

const AdjustTroveComponents: React.FC<AdjustTroveComponentsProps> = ({
  disconnected,
  borrowMode,
  setBorrowMode,
  values,
  form,
}) => {
  const {
    trove,
    underlyingPrices,
    prices,
    tokenBalances,
    borrowingRate,
    decimals,
    safetyRatios,
    recoveryRatios,
    underlyingPerReceiptRatios,
    receiptPerUnderlyingRatios,
  } = useLiquitySelector(selector);

  const { liquity } = useLiquity();

  const [leverSave, setLeverSave] = useState<"saved" | "unsaved">("unsaved");

  const [isCapExceed, setIsCapExceed] = useState(false);

  const {
    isOpen: isConfirmChangesOpen,
    onOpen: onConfirmChangesOpen,
    onClose: onConfirmChangesClose,
  } = useDisclosure();
  const toast = useToast();

  const toastProps: UseToastOptions = {
    status: "error",
    duration: 4000,
    isClosable: true,
    position: "top-right",
  };

  // Shape collateral
  useEffect(() => {
    tokenData.map(
      (token) =>
        (token["troveBalance"] = formatWithDecimals(
          trove.collaterals[token.address],
          decimals[token.address].toNumber()
        ))
    );
    //TODO
    tokenData.map(
      (token) =>
        (token["walletBalance"] = formatWithDecimals(
          tokenBalances[
            token.underlying == "" ? token.address : token.underlying
          ],
          token.underlyingDecimals
        ))
    );
  }, [tokenBalances, trove.collaterals]);

  //TODO move those into utils
  // Validate changes
  const validate = (
    values: { [key: string]: string | number },
    ICR: number,
    troveBalancePost: number
  ) => {
    try {
      // Data Engineering
      const affectedTokens = Object.keys(values).filter(
        (value) => !value.includes("mode")
      );
      const affectedCollateral = tokenData.filter((collateral) =>
        affectedTokens.includes(collateral.token)
      );

      // Collateral Error Checks
      const totalYUSDFromLever: Decimal = calculateTotalYUSDFromLever(
        adjustedCollateral,
        underlyingPrices,
        values,
        safetyRatios
      );
      // eslint-disable-next-line prefer-const
      let [changes, collateralApproved, message, description] = checkCollateral(
        affectedCollateral,
        values,
        receiptPerUnderlyingRatios
      );
      if (!collateralApproved) {
        toast({
          title: message,
          description: description,
          ...toastProps,
        });
        throw BreakException;
      }

      if (troveBalancePost < 2000 && trove.status == "open") {
        toast({
          title: "Invalid Action",
          description:
            "You are paying back more YUSD than minimum debt required to keep trove open (2000 YUSD). Please reduce debt repayment amount or close trove.",
          ...toastProps,
        });
        throw BreakException;
      } else if (troveBalancePost < 2000) {
        toast({
          title: "Invalid Action",
          description:
            "Your net borrow amount must be greater than 2000 YUSD in order to open a Trove.",
          ...toastProps,
        });
        throw BreakException;
      }

      if (!changes && values["YUSD"] === 0) {
        toast({
          title: "Invalid Action",
          description:
            "Please make some changes to your trove before confirming changes",
          ...toastProps,
        });
        throw BreakException;
      }
      // console.log(values);
      let borrowApproved;
      // eslint-disable-next-line prefer-const
      [borrowApproved, message, description] = checkBorrow(
        values,
        totalYUSDFromLever,
        trove.debt["debt"],
        ICR
      );
      if (!borrowApproved) {
        toast({
          title: message,
          description: description,
          ...toastProps,
        });
        throw BreakException;
      }

      if (
        (changes || values["YUSD"] !== 0) &&
        borrowApproved &&
        collateralApproved
      ) {
        onConfirmChangesOpen();
      }
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  };

  const leverOptions = [
    { key: "normal", value: "Normal" },
    { key: "lever", value: "Leverage" },
  ];

  if (trove.status === "open") {
    leverOptions.push({ key: "unlever", value: "Deleverage" });
  }

  /**
   * Set and update fees
   */
  // TODO: right now we can get the fee for all collaterals, but this could be optimized by filtering the list for collaterals that user added and collaterals that have balance
  const collateral = tokenData.filter(
    (token) => values[token.token + "mode"] !== undefined
  );
  const fees: TroveMappings = {};
  // console.log("AC", ratios)
  collateral.map((token) => (fees[token.address] = Decimal.ZERO));
  const [depositFees, setFees] = useState<TroveMappings>(fees);
  //let amountInForLeverage: TroveMappings = {};
  const getFees = () => {
    const amountsIn: TroveMappings = {};
    const amountsOut: TroveMappings = {};

    if (Object.keys(values).length !== 0) {
      for (let i = 0; i < collateral.length; i++) {
        const token = collateral[i].token;
        const address = collateral[i].address;
        const dec = collateral[i].underlyingDecimals;
        const valuesWithFee: number =
          collateral[i].additionalFee !== undefined && mode[token] === "deposit"
            ? values[token] * (1 - collateral[i].additionalFee!)
            : values[token];
        if (values[token] != null) {
          if (values[token] !== 0) {
            const amount = Decimal.fromWithPrecision(
              isNaN(valuesWithFee) || valuesWithFee < 0
                ? 0
                : +valuesWithFee * format(receiptPerUnderlyingRatios[address]),
              dec
            );
            if (values[token + "mode"] === "deposit") {
              amountsIn[address] = amount;
            } else {
              amountsOut[address] = amount;
              // To show the fee even for assets which have no adjust
              amountsIn[address] = Decimal.fromWithPrecision(1, dec).div(1e6);
            }
          }
        } else {
          // To show the fee even for assets which have no adjust
          amountsIn[address] = Decimal.fromWithPrecision(1, dec).div(1e6);
        }
      }
      //amountInForLeverage = amountsIn;
      liquity
        .getDepositFee(amountsIn, amountsOut)
        .then((fees) => {
          setFees(fees);
          setIsCapExceed(false);
        })
        .catch((e) => {
          const eMSG = e.data.message;
          if (eMSG === "execution reverted: Collateral input exceeds cap") {
            setIsCapExceed(false);
          }
        });
    }
  };

  /**
   * set and update adjustedCollateral
   */

  const coins: CoinMode = {};
  tokenData.map((coin) => {
    coins[coin.token] = coin.isDeprecated == true ? "withdraw" : "deposit";
  });
  if (values["YUSDmode"] !== undefined) {
    coins["YUSD"] = values["YUSDmode"];
  } else {
    coins["YUSD"] = "deposit";
  }
  if (borrowMode !== "unlever") {
    coins["YUSD"] = "deposit";
  } else {
    coins["YUSD"] = "withdraw";
  }
  const [mode, setMode] = useState<CoinMode>(coins);
  const [adjustedCollateral, setAdjustedCollateral] = useState(
    getAffectedCollateral(values)
  );
  /**
   * set vcValue
   */

  const [vc, stableVc] = calculateVcValue(
    borrowMode,
    adjustedCollateral,
    prices,
    values,
    safetyRatios,
    receiptPerUnderlyingRatios
  );

  const [vcValue, setVcValue] = useState(vc);

  const [stableVC, setStableVc] = useState(stableVc);

  const [usdValue, setUsdValue] = useState(
    calculateUsdValue(
      borrowMode,
      adjustedCollateral,
      prices,
      values,
      receiptPerUnderlyingRatios
    )
  );

  const [avcValue, setAvcValue] = useState(
    calculateAvcValue(
      borrowMode,
      adjustedCollateral,
      prices,
      values,
      recoveryRatios,
      receiptPerUnderlyingRatios
    )
  );

  const [troveBalance, setTroveBalance] = useState<number>(+trove.debt["debt"]);

  useEffect(() => {
    setAdjustedCollateral(getAffectedCollateral(values));
    getFees();
    const [currVc, currStableVc] = calculateVcValue(
      borrowMode,
      adjustedCollateral,
      prices,
      values,
      safetyRatios,
      receiptPerUnderlyingRatios
    );
    setVcValue(currVc);
    setStableVc(currStableVc);
    setAvcValue(
      calculateAvcValue(
        borrowMode,
        adjustedCollateral,
        prices,
        values,
        recoveryRatios,
        receiptPerUnderlyingRatios
      )
    );
    setUsdValue(
      calculateUsdValue(
        borrowMode,
        adjustedCollateral,
        prices,
        values,
        receiptPerUnderlyingRatios
      )
    );
    setTroveBalance(
      getTroveBalance(
        mode,
        values,
        +trove.debt["debt"],
        borrowingRate,
        underlyingPrices,
        depositFees,
        safetyRatios,
        trove,
        adjustedCollateral,
        borrowMode
      )
    );
  }, [values]);

  const getMaxBorrow = () => {
    const [vcValue] = calculateVcValue(
      "lever",
      adjustedCollateral,
      underlyingPrices,
      values,
      safetyRatios,
      receiptPerUnderlyingRatios
    );
    const maxAmount = vcValue / 1.1 - troveBalance; // TODO : Rounding error?
    if (maxAmount < 0) {
      return 0;
    }
    return maxAmount;
  };

  const calculateHealthColor = () => {
    // const health = calculateHealth(troveHealth);
    const health = (troveBalance / (getMaxBorrow() + troveBalance)) * 100;

    if (health < 90) {
      return "yellow";
    } else if (health < 80) {
      return "green";
    } else {
      return "red";
    }
  };

  // const troveHealth =
  //   stableVC * 1.1 > troveBalance && stableVC / format(vcValue) > 0.99
  //     ? calculateHealthStableTrove(healthRatio)
  //     : calculateHealth(healthRatio);

  const [balance, setBalance] = useState<number>(0);

  return (
    <>
      {/* <Box display="flex" gap="24px">
        <Box display="flex" flexDir="column">
          <Text color="text-secondary" textStyle="subheading_display">
            Deposit Balance
          </Text>
          <Text color="white" textStyle="number_md">
            {" "}
            ${getNum(usdValue, 2)}{" "}
          </Text>
        </Box>

        <Box display="flex" flexDir="column">
          <Text textStyle="subheading_display" color="text-secondary">
            Borrow Balance
          </Text>
          <Text fontSize="24px" fontWeight="500" color="#DFE3E9">
            ${getNum(troveBalance)}
          </Text>
        </Box>
      </Box> */}
      {/* <Box display="flex" flexDir="column" pt="16px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              textStyle="subheading"
              color="text-secondary"
              fontWeight="normal"
            >
              Collateral Ratio{" "}
              <Tooltip>Ratio of risk adjusted value in trove to debt</Tooltip>
            </Text>
            <Text textStyle="card_text">
              {isNaN((currVcValue * 100) / troveBalance)
                ? "0%"
                : `${((currVcValue * 100) / troveBalance).toFixed(3)}%`}
            </Text>
          </Box>
          <Flex direction={["column", "row"]} alignItems="center" pt="12px">
            <Progress
              value={troveHealth}
              size="sm"
              w="100%"
              colorScheme={calculateHealthColor(troveHealth)}
              bg="surface-highlight"
            />
          </Flex>
          <Text
            textStyle="text_xs"
            color="text-secondary"
            fontWeight="normal"
            pt="4px"
          >
            110% MIN
          </Text>
        </Box> */}

      {/* <Box display="flex" flexDir="column" pt="16px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        ></Box>
        <Text color="green.300"> max borrow: {getMaxBorrow()}</Text>
        <Box alignItems="center" pt="12px">
          <Progress
            value={(troveBalance / (getMaxBorrow() + troveBalance)) * 100}
            // size="sm"
            colorScheme={calculateHealthColor()}
            height="4px"
            color="red.500"
            // colorScheme="gray"
            bg="#33374D"
            rounded="full"
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              textStyle="subheading"
              color="text-secondary"
              fontWeight="normal"
            >
              Borrow limit used:
              {((troveBalance / (getMaxBorrow() + troveBalance)) * 100).toFixed(
                2
              )}
              %
            </Text>
            <Text textStyle="card_text" color="white">
              Borrow Limit:
              {(getMaxBorrow() + troveBalance).toFixed(2)}
            </Text>
          </Box>
        </Box>
      </Box> */}
      <Box display="flex" gap="24px">
        <Box flexDir="column" display="flex" w="full" gap="0px">
          {/* <Flex justify="space-between" align="center">
          <Toggle
                options={leverOptions}
                size="md"
                onChange={v => {
                  setBorrowMode(v as "normal" | "lever" | "unlever");
                  form.reset();
                }}
              />
        </Flex> */}
          {isConfirmChangesOpen && (
            <ConfirmChangesModal
              isOpen={isConfirmChangesOpen}
              onClose={onConfirmChangesClose}
              values={values}
              collateral={adjustedCollateral}
              borrowMode={borrowMode}
              mode={mode}
              depositFees={depositFees}
              currVcValue={vcValue}
              avcValue={avcValue}
            />
          )}
          {/* <Box w="full" h="full" mb="24px"> */}
          {/* Placeholder for collateral chart */}

          <Box mb="24px">
            <CollateralChart usdValue={usdValue} />
          </Box>

          <Box mt="24px">
            <BorrowSummary />
          </Box>

          <Show below="lg">
            <Box display="flex" flexDir="column">
              <AdjustBorrowAmount
                values={values}
                collateral={tokenData}
                validateFunc={validate}
                borrowFee={(+borrowingRate.mul(100)).toFixed(3)}
                leverSave={leverSave}
                borrowMode={borrowMode}
                depositFees={depositFees}
                adjustedCollateral={adjustedCollateral}
                mode={mode}
                setMode={setMode}
                vcValue={vcValue}
                form={form}
                troveBalance={troveBalance}
                stableVC={stableVC}
                currVcValue={vcValue}
                currUSDValue={usdValue}
                setBorrowMode={setBorrowMode}
                disconnected={disconnected}
              />
            </Box>
          </Show>

          <AdjustCollateral
            values={values}
            collateral={tokenData}
            form={form}
            borrowMode={borrowMode}
            leverSave={leverSave}
            setLeverSave={setLeverSave}
            depositFees={depositFees}
            currVcValue={vcValue}
            currUSDValue={usdValue}
            mode={mode}
            setMode={setMode}
            troveBalance={troveBalance}
          />
          {/* </Box> */}
          {/* <BorrowSummary /> */}

          {/* <Flex align="center" mt={4} mx={6}>
              <Text textStyle="body2">YUSD Borrow Fee: {(+borrowingRate.mul(100)).toFixed(3)}%</Text>
              <Spacer />
              <Button variant="newPrimary" onClick={() => validate(values)}>
                Confirm Changes
              </Button>
            </Flex> */}
          {isCapExceed ? (
            <Flex
              align="center"
              bg={"yellow.500"}
              py={3}
              px={5}
              position="fixed"
              bottom={4}
              right={4}
              overflow="hidden"
              borderRadius="xl"
              maxW="90%"
            >
              <Box mr={3} w={10} h={10}>
                <TransactionProgressDonut state={"failed"} />
              </Box>
              <Box>
                <Flex>
                  <Text textStyle="subtitle1">
                    Collateral input exceeds cap
                  </Text>
                </Flex>
              </Box>
            </Flex>
          ) : null}
        </Box>

        <Hide below="lg">
          <Box display="flex" flexDir="column">
            <AdjustBorrowAmount
              values={values}
              collateral={tokenData}
              validateFunc={validate}
              borrowFee={(+borrowingRate.mul(100)).toFixed(3)}
              leverSave={leverSave}
              borrowMode={borrowMode}
              depositFees={depositFees}
              adjustedCollateral={adjustedCollateral}
              mode={mode}
              setMode={setMode}
              vcValue={vcValue}
              form={form}
              troveBalance={troveBalance}
              stableVC={stableVC}
              currVcValue={vcValue}
              currUSDValue={usdValue}
              setBorrowMode={setBorrowMode}
              disconnected={disconnected}
            />
          </Box>
        </Hide>
      </Box>
    </>
  );
};

export default AdjustTroveComponents;
