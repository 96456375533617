import { createIcon } from "@chakra-ui/react";

const aWETH = createIcon({
  displayName: "aWETH",
  viewBox: "-280 0 1277.37 1277.39",
  path: (
    <g>
      <polygon
        fill="#853d74"
        fillRule="nonzero"
        points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 "
      />
      <polygon
        fill="#B6509E"
        fillRule="nonzero"
        points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33 "
      />
      <polygon
        fill="#853d74"
        fillRule="nonzero"
        points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 "
      />
      <polygon
        fill="#B6509E"
        fillRule="nonzero"
        points="392.07,1277.38 392.07,956.52 -0,724.89 "
      />
      <polygon
        fill="#261222"
        fillRule="nonzero"
        points="392.07,882.29 784.13,650.54 392.07,472.33 "
      />
      <polygon
        fill="#47233f"
        fillRule="nonzero"
        points="0,650.54 392.07,882.29 392.07,472.33 "
      />
    </g>
  ),
});

export default aWETH;
