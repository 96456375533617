const Button = {
  baseStyle: {
    fontWeight: "bold",
    // fontFamily: "Open Sans",
    fontSize: "lg",
    _focus: {
      outline: "none",
      boxShadow: "none",
    },
  },
  variants: {
    newPrimary: {
      bg: "primary-default",
      borderRadius: "full",
      height: "42px",
      px: "16px",
      // shadow: "button",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "600",
      // shadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px;",
      color: "white",
      _hover: {
        backgroundColor: "primary-hover",
      },
      _active: {
        backgroundColor: "primary-default",
      },
    },
    text: {
      bg: "",
      color: "primary-default",
      _hover: {
        color: "primary-hover",
        // transform: "scale(1.05)",
      },
      _active: {
        color: "primary-default",
      },
    },
    dark: {
      bg: "#272932",
      borderWidth: "1px",
      borderColor: "#323644",
      borderRadius: "12px",
      height: "42px",
      px: "14px",
      shadow: "button",
      color: "#EAEDF0",
      fontSize: "15px",
      fontWeight: "600",
      _hover: {
        bg: "#30333F",
        transform: "scale(0.98)",
        shadow: "button-hover",
      },
      _active: {
        bg: "#31333E",
      },
    },
    surface: {
      bg: "surface-highlight",
      // borderWidth: "1px",
      // borderColor: "border",
      borderRadius: "full",
      height: "42px",
      px: "16px",
      // shadow: "button",
      color: "text-primary",
      fontSize: "13.5px",
      alignItems: "center",
      fontWeight: "600",
      _disabled: {
        bg: "surface-highlight",
        color: "text-secondary",
      },
      // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      _hover: {
        // color: "primary-default",
        // transform: "scale(0.98)",
        bg: "sh-hover",
        // shadow: "button-hover",
      },
      _active: {
        bg: "surface-highlight",
      },
    },
    outline: {
      bg: "",
      borderWidth: "1px",
      borderColor: "border",
      borderRadius: "12px",
      height: "48px",
      px: "16px",
      // shadow: "button",
      color: "text-primary",
      fontSize: "14px",
      alignItems: "center",
      fontWeight: "600",
      _disabled: {
        bg: "text-tertiary",
        color: "text-secondary",
      },
      // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      _hover: {
        // color: "primary-default",
        // transform: "scale(0.98)",
        bg: "sh-hover",
        // shadow: "button-hover",
      },
      _active: {
        bg: "surface-highlight",
      },
    },
    link: {
      bg: "",
      // borderWidth: "1px",
      // borderColor: "primary-default",
      // borderRadius: "12px",
      // shadow: "button",
      borderRadius: "0px",
      color: "white",
      fontSize: "16px",
      alignItems: "center",
      fontWeight: "700",
      // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      _hover: {
        // color: "primary-default",
        // transform: "scale(0.98)",
        // bg: "sh-hover",
        // borderColor: "white",
        color: "white",
        // shadow: "button-hover",
      },
      _active: {
        // bg: "surface-highlight",
      },
    },
    contrast: {
      bg: "contrast",
      color: "background",
      border: "1px",
      height: "42px",
      borderColor: "contrast-border",
      borderRadius: "8px",
      shadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px;",
      _hover: {
        backgroundColor: "contrast-hover",
        color: "background",
      },
      _active: {
        backgroundColor: "contrast-hover",
      },
    },
  },
  sizes: {
    AdjustInput: {
      height: "40%",
      fontSize: "46%",
      borderRadius: "2px",
    },
    xs: {
      height: "inputlike.xs",
    },
    sm: {
      py: "8px",
    },
    md: {
      height: "inputlike.md",
    },
    lg: {
      height: "inputlike.lg",
    },
  },
};

export default Button;
