import React, { useState } from "react";
import { BoxProps, Container } from "@chakra-ui/react";
// import Footer from "../../Footer";
// import Snowfall from "react-snowfall";

type PageWrapperProps = { snow?: 0 | 1 } & BoxProps;

const PageWrapper: React.FC<PageWrapperProps> = ({ children, ...props }) => {
  const [snow, setSnow] = useState<0 | 1>(0);

  const toggleSnow = () => {
    if (snow === 0) {
      setSnow(1);
    } else {
      setSnow(0);
    }
  };

  const height = window.innerHeight;

  return (
    <>
      <Container
        maxW="1150px"
        display="flex"
        flexDir="column"
        {...props}
        px="16px"
        h={height}
      >
        {/* {snow === 1 && <Snowfall snowflakeCount={100} style={{ zIndex: 1 }} />} */}
        {children}
      </Container>
      {/* <Footer /> */}
    </>
  );
};

export default PageWrapper;
