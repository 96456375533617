import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { PoolCard } from "../../PageComponents/Pool";
import { BoostFarmCard, FarmCard } from "../../PageComponents/Farm";
import TJFarmPool from "../../PageComponents/DEXPools/TJFarmPool";
import PLPPool from "../../PageComponents/DEXPools/PLPPool";
// import Tooltip from "../../Components/Tooltip";

export type PoolProps = {
  disconnected?: boolean;
};

const Pool: React.FC<PoolProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        w="full"
        h="324px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Box>
        <Box>
          <Text color="#DFE3E9" textStyle="display_sm">
            Farm
          </Text>
        </Box>
        {/* <Flex direction={["column", null, "row"]} flex={1} px={6}>
       
      </Flex>
      <Flex direction={["column", null, "row"]} flex={1} mt={6} px={6}>
        
      </Flex> */}
        {/* <Text textStyle="title4" px={6}>
          Stability Pool{" "}
          <Tooltip>
            You will accrue YETI for staking YUSD in the Stability Pool. In case
            of liquidations, a portion of YUSD from the Stability Pool will be
            used to offset debt for a portion of the collateral as reward. When
            liquidations occur, your YUSD amount staked will decrease slightly
            but you will then have claimable collateral which you can take as
            reward. The claimable collateral is typically ~10% greater in value
            than your decrease in YUSD in the Stability Pool.
          </Tooltip>
        </Text> */}
        <Box
          display="flex"
          flexDir={["column", "column", "row"]}
          gap="16px"
          justifyContent="center"
          mt="48px"
        >
          <PoolCard disconnected={disconnected} />
          {/* <Text textStyle="title4" mt={3} px={6}>
          Curve LP Farm
        </Text> */}

          <BoostFarmCard disconnected={disconnected} />
          <PLPPool />
          <TJFarmPool />
        </Box>
        {/* <Text textStyle="title4" px={6} mt="64px">
          Old Curve LP Farm{" "}
          <Tooltip>
            Emissions on this farm are off as of May 9th. Unstake your LP and
            transfer to the new farm above, which includes boosted yields based
            on veYETI.
          </Tooltip>
        </Text> */}
        <Box display="flex" mt="64px">
          <FarmCard disconnected={disconnected} />
        </Box>
      </Box>
    </>
  );
};

export default Pool;
