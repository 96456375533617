import { Box, Spinner } from "@chakra-ui/react";
import React from "react";

export type LoaderProps = Record<string, never>;

const Loader: React.FC<LoaderProps> = () => (
  // <img
  //   src="https://miro.medium.com/max/1200/1*4Ji922-P8JrVGrzEiRHTZg.gif"
  //   alt="Yeti Finance"
  //   style={{ width: "12rem", borderRadius: "50%" }}
  // />
  <Box p="32px">
    <Spinner color="text-primary" />
  </Box>
);

export default Loader;
