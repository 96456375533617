const Card = {
  baseStyle: {
    bg: "brand.800",
  },
  sizes: {
    xl: {
      borderRadius: "lgCard",
      py: 5,
      px: 8,
    },
  },
};

export default Card;
