const Divider = {
  baseStyle: {
    opacity: 1,
    borderColor: "brand.600",
    borderWidth: 0,
    h: 0.5,
    bg: "brand.600",
    borderRadius: "infinity",
  },
};

export default Divider;
