import React, { ReactNode } from "react";

// import { Table, Thead, Tbody, Tr, Th, TableProps, Box } from "@chakra-ui/react";

import { Table, Tbody, TableProps } from "@chakra-ui/react";

// import Tooltip from "../Tooltip";

export type TokenTableProps = {
  // headers: ReactNode[];
  // tooltips?: string[];
  children: ReactNode;
  width?: number;
  borrow?: boolean;
  // hideTopBorder?: string;
} & TableProps;

const TokenTable: React.FC<TokenTableProps> = ({
  // headers,
  children,
  // tooltips,
  borrow = false,
}) => {
  return (
    <Table border="none">
      {/* <Thead display="flex" w="full">
        <Tr display="flex" w="full">
          {headers.map((header, index) => (
            <Th
              key={index}
              pt="16px"
              pb="16px"
              borderColor="border"
              color="text-secondary"
              fontSize={11}
              textTransform="capitalize"
              fontWeight="medium"
              display="flex"
              w="full"
            >
              <Box display="flex" alignItems="center">
                {header}
                {tooltips && tooltips[index] && (
                  <Tooltip>{tooltips[index]}</Tooltip>
                )}
              </Box>
            </Th>
          ))}
        </Tr>
      </Thead> */}
      <Tbody textStyle="text_md" color="text-primary">
        {children}
      </Tbody>
    </Table>
  );
};

export default TokenTable;
