import { createIcon } from "@chakra-ui/react";

const calculator = createIcon({
  displayName: "calculator",
  viewBox: "0 0 12 15",
  path: (
    <g>
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="1"
        y="1"
        width="10.4"
        height="13"
        rx="2"
        stroke="#9B9EAC"
      />
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="3.6001"
        y="3.59998"
        width="5.2"
        height="1.95"
        rx="0.5"
        stroke="#9B9EAC"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.25 11.9C4.52614 11.9 4.75 11.6761 4.75 11.4C4.75 11.1238 4.52614 10.9 4.25 10.9V11.9ZM3.6 10.9C3.32386 10.9 3.1 11.1238 3.1 11.4C3.1 11.6761 3.32386 11.9 3.6 11.9V10.9ZM4.25 10.9H3.6V11.9H4.25V10.9Z"
        fill="#9B9EAC"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.25 9.29999C4.52614 9.29999 4.75 9.07613 4.75 8.79999C4.75 8.52385 4.52614 8.29999 4.25 8.29999V9.29999ZM3.6 8.29999C3.32386 8.29999 3.1 8.52385 3.1 8.79999C3.1 9.07613 3.32386 9.29999 3.6 9.29999V8.29999ZM4.25 8.29999H3.6V9.29999H4.25V8.29999Z"
        fill="#9B9EAC"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8.1499 10.9C7.87376 10.9 7.6499 11.1238 7.6499 11.4C7.6499 11.6761 7.87376 11.9 8.1499 11.9V10.9ZM8.7999 11.9C9.07604 11.9 9.2999 11.6761 9.2999 11.4C9.2999 11.1238 9.07604 10.9 8.7999 10.9V11.9ZM8.1499 11.9H8.7999V10.9H8.1499V11.9Z"
        fill="#9B9EAC"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M8.1499 8.29999C7.87376 8.29999 7.6499 8.52385 7.6499 8.79999C7.6499 9.07613 7.87376 9.29999 8.1499 9.29999L8.1499 8.29999ZM8.7999 9.29999C9.07604 9.29999 9.2999 9.07613 9.2999 8.79999C9.2999 8.52385 9.07604 8.29999 8.7999 8.29999L8.7999 9.29999ZM8.1499 9.29999L8.7999 9.29999L8.7999 8.29999L8.1499 8.29999L8.1499 9.29999Z"
        fill="#9B9EAC"
      />
    </g>
  ),
});

export default calculator;
