import React from "react";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { LiquityStoreState } from "@yeti/lib-base";
import tokenData from "../../../../TokenData";
import { Icon, TokenTable } from "../../../../Components";
import CurrencyConverter from "../../../../Components/CurrencyConverter";
import getTokenName from ".././utils/getTokenName";
import { getNum } from "../../../../Utils/number";
import { Flex, Center, Box, Text, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export type PortfolioModalProps = {
  isOpen: boolean;
  onClose: () => void;
  data: any;
};

const selector = ({ prices, total, safetyRatios }: LiquityStoreState) => ({
  prices,
  total,
  safetyRatios,
});

const getFormattedDate = (date: Date) => {
  // console.log('date', date.toLocaleString() )
  const options: any = {
    day: "numeric",
    month: "long",
    hour: "numeric",
    minute: "numeric",
  };
  const string = date.toLocaleString("en-US", options).split(" ");
  return (
    string[0].slice(0, 3) + " " + string[1] + ", " + string[3] + " " + string[4]
  );
};

const TroveSummaryModal: React.FC<PortfolioModalProps> = ({
  isOpen,
  onClose,
  data,
}) => {
  // console.log('summary modal history data', data)
  // const summaryData =
  //     {
  //       "operation": "adjustTrove",
  //       "blockNum": "14800706",
  //       "collsIn": [
  //         "0x72298e47f648ca0215f2e17d5647048b2d42520c",
  //         "0xcc3ee7ccb14aea851850f46cbbe4d82f5d74c20f"
  //       ],
  //       "amountsIn": [
  //         500000000000000000,
  //         49999999996627379438
  //       ],
  //       "tokens": [
  //         "0x72298e47f648ca0215f2e17d5647048b2d42520c",
  //         "0xcc3ee7ccb14aea851850f46cbbe4d82f5d74c20f"
  //       ],
  //       "amounts": [
  //         15705269840987655557,
  //         1053636163089280431124
  //       ],
  //       "debt": 7099996846927131974487,
  //       "YUSDchange": 27020000000000000000,
  //       "isDebtIncrease": true,
  //       "timestamp": 1652764225,
  //       "currentICR": 1476463009993319806
  //     }
  const summaryData = data;

  // console.log("summaryData", summaryData);

  // console.log('summary', summaryData)

  const allTokens: string[] = Object.assign(
    [],
    summaryData["collsIn"].concat(summaryData["collsOut"])
  );

  for (const i in summaryData["tokens"]) {
    if (!allTokens.includes(summaryData["tokens"][i])) {
      allTokens.push(summaryData["tokens"][i]);
    }
  }

  const getTransactionType = (
    operation: string,
    collsIn: string[],
    collsOut: string[],
    debt: number
  ) => {
    switch (operation) {
      case "liquidateInNormalMode":
        return "Liquidated";
      case "adjustTrove":
        if (
          summaryData["collsIn"].length == 0 &&
          summaryData["collsOut"].length == 0
        ) {
          return "Change Debt";
        }
        return "Adjust Portfolio";
      case "redeemCollateral":
        if (debt == 0) {
          return "Closed by Redemption";
        }
        return "Partially Redeemed";
      case "openTrove":
        return "Open Trove";
      case "closeTrove":
        return "Close Trove";
      default:
        return "Undefined";
    }
  };

  const getTransaction = (token: string) => {
    // console.log('all', allTokens)
    if (summaryData["collsIn"].includes(token)) {
      if (summaryData["collsIn"].indexOf(token) == 0) {
        return <Center>Deposit</Center>;
      }
      return <Center> | </Center>;
    } else if (summaryData["collsOut"].includes(token)) {
      if (summaryData["collsOut"].indexOf(token) == 0) {
        return <Center>Withdraw</Center>;
      }
      return <Center> | </Center>;
    } else {
      if (
        allTokens[
          summaryData["collsIn"].length + summaryData["collsOut"].length
        ] == token
      ) {
        return <Center>No Change</Center>;
      }
      return (
        <Box display="flex" justifyContent="center" w="90px">
          {" "}
          |{" "}
        </Box>
      );
    }
  };

  const getAmountChange = (token: string) => {
    // console.log("amountChange", summaryData);
    const tokenInfo = tokenData.find(
      (t) => t["address"].toLowerCase() == token.toLowerCase()
    );
    const tokenName = tokenInfo!["token"];
    if (summaryData["collsIn"].includes(token)) {
      const index = summaryData["collsIn"].indexOf(token);
      const realAmount = summaryData["realAmountsIn"][index];
      const realValue = summaryData["valuesIn"][index];
      if (realValue != 0) {
        return (
          <Box>
            <Text
              textStyle="subtitle3"
              color="green.400"
              textAlign={["right", "left"]}
            >
              {" "}
              +{getNum(Number(realAmount), 3)}
              {
                <CurrencyConverter
                  token={tokenName}
                  value={realValue}
                  currency={"USD"}
                  price={1}
                />
              }
            </Text>
          </Box>
        );
      }
    } else if (summaryData["collsOut"].includes(token)) {
      const index = summaryData["collsOut"].indexOf(token);
      const realAmount = summaryData["realAmountsOut"][index];
      const realValue = summaryData["valuesOut"][index];
      // console.log("realValue", realValue);
      if (realValue != 0) {
        return (
          <Box>
            <Text
              textStyle="subtitle3"
              color="red.400"
              textAlign={["right", "left"]}
            >
              {" "}
              -{getNum(Number(realAmount), 3)}
              {
                <CurrencyConverter
                  token={tokenName}
                  value={realValue}
                  currency={"USD"}
                  price={1}
                />
              }
            </Text>
          </Box>
        );
      }
    }
    return (
      <Box>
        <Text
          textStyle="subtitle3"
          color="text-primary"
          textAlign={["right", "left"]}
        >
          {" "}
          --
        </Text>
      </Box>
    );
  };

  const isAToken = (tokenName: string) => {
    return ["aUSDC", "aWAVAX", "aWETH", "aUSDT", "aDAI"].includes(tokenName);
  };

  const getFinalAmount = (token: string) => {
    const tokenInfo = tokenData.find(
      (t) => t["address"].toLowerCase() == token.toLowerCase()
    );
    const tokenName = tokenInfo!["token"];
    const underlyingDecimals = tokenInfo!["underlyingDecimals"];
    let finalValue = 0;
    let realAmount = 0;
    if (summaryData["tokens"].includes(token)) {
      const index = summaryData["tokens"].indexOf(token);
      finalValue = summaryData["values"][index];
      realAmount = summaryData["realAmounts"][index];
    }
    return (
      <Box display="flex" flexDir="column" alignItems="flex-end">
        <Text
          textStyle="subtitle3"
          color="text-primary"
          textAlign={["right", "left"]}
        ></Text>
        {getNum(Number(realAmount), 3) + " "}
        <Text color="text-secondary">
          {
            <CurrencyConverter
              token={tokenName}
              value={finalValue}
              currency={"USD"}
              price={1}
            />
          }
        </Text>
      </Box>
    );
  };

  const getDebtChange = (change: number) => {
    if (change == 0) {
      return (
        <Box>
          <Text
            textStyle="number_base"
            color="text-primary"
            textAlign={["right", "left"]}
          >
            --
          </Text>
        </Box>
      );
    } else {
      return (
        <Box>
          <Text
            textStyle="number_base"
            color="text-primary"
            textAlign={["right", "left"]}
          >
            {getNum(Number(change), 3)}
          </Text>
        </Box>
      );
    }
  };

  return summaryData == {} ? (
    <Text color="text-primary"> Loading </Text>
  ) : (
    <>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent maxW="1280px">
          <ModalBody layerStyle="card">
            <Box
              display="flex"
              flexDir="column"
              pb="16px"
              textStyle="text_sm"
              color="text-secondary"
              pt="16px"
              justifyContent="space-between"
            >
              <Box display="flex" color="text-primary">
                <Text textStyle="display_xs">
                  {" "}
                  {getTransactionType(
                    summaryData["operation"],
                    summaryData["collsIn"],
                    summaryData["collsOut"],
                    Number(summaryData["debt"])
                  )}{" "}
                </Text>
                <Link
                  textStyle="display_xs"
                  ml="8px"
                  color="text-secondary"
                  _focus={{ outline: "none", boxShadow: "none" }}
                  textDecorationLine="none"
                  href={"https://snowtrace.io/tx/" + summaryData["transaction"]}
                  isExternal
                >
                  <ExternalLinkIcon mb={1}> </ExternalLinkIcon>
                </Link>
              </Box>
              <Box display="flex" pt="24px" justifyContent="space-between">
                <Box display="flex">
                  <Box display="flex">
                    <Text>Event</Text>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box w="120px" display="flex" justifyContent="flex-end">
                    <Text>Asset Changes</Text>
                  </Box>
                  <Box w="200px" display="flex" justifyContent="flex-end">
                    <Text>Deposit Change</Text>
                  </Box>
                  <Box w="160px" display="flex" justifyContent="flex-end">
                    <Text>Final Collateral Balance</Text>
                  </Box>
                  <Box w="180px" display="flex" justifyContent="flex-end">
                    <Text>Final Debt</Text>
                  </Box>
                  <Box w="130px" display="flex" justifyContent="flex-end">
                    <Text>ICR</Text>
                  </Box>
                  <Box w="180px" display="flex" justifyContent="flex-end">
                    <Text>Date</Text>
                  </Box>
                  <Box w="40px" />
                </Box>
              </Box>
              {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
            </Box>
            <TokenTable
            // headers={[
            //   "Transaction Type",
            //   "Asset changes",
            //   "Collateral change",
            //   "Final collateral value ($)",
            //   "Final debt",
            //   "ICR",
            //   "Date",
            // ]}
            // width={7}
            // display={["none"]}
            >
              {/* <Tr onClick={() => trclick(history)} _hover={{bg: "#272C39"}}> */}
              <Box display="flex" flexDir="column">
                <Box
                  display="flex"
                  h="80px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <Box display="flex" alignItems="center">
                      {summaryData["collsIn"].map((token: string) => {
                        return (
                          <Icon
                            key={token}
                            iconName={getTokenName(token)}
                            h="24px"
                            w="24px"
                          />
                        );
                      })}
                      {summaryData["collsOut"].map((token: string) => {
                        return (
                          <Icon
                            key={token}
                            iconName={getTokenName(token)}
                            h={5}
                            w={5}
                            mr={3}
                          />
                        );
                      })}
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      color="text-primary"
                    >
                      {summaryData["totalValueChange"] == 0 ? (
                        <Flex>-- </Flex>
                      ) : (
                        <Flex>
                          {summaryData["totalValueChange"] > 0 ? (
                            <Text
                              textStyle="subtitle3"
                              color="green.400"
                              textAlign={["right", "left"]}
                            >
                              {" "}
                              +$
                              {getNum(
                                Number(summaryData["totalValueChange"]),
                                2
                              )}
                            </Text>
                          ) : (
                            <Text
                              textStyle="subtitle3"
                              color="red.400"
                              textAlign={["right", "left"]}
                            >
                              {" "}
                              -$
                              {getNum(
                                -1 * Number(summaryData["totalValueChange"]),
                                2
                              )}
                            </Text>
                          )}
                        </Flex>
                      )}
                    </Box>

                    <Box
                      w="160px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      color="text-primary"
                    >
                      <Text color="text-primary">
                        $
                        {Number(summaryData["totalValue"])
                          .toFixed(2)
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </Text>
                    </Box>
                    <Box
                      w="190px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {Number(summaryData["debt"] * 10 ** -18)
                        .toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      YUSD
                    </Box>
                    <Box
                      w="140px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {getNum(summaryData["currentICR"] * 10 ** -16, 2) + "%"}
                    </Box>
                    <Box
                      w="210px"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {getFormattedDate(
                        new Date(summaryData["timestamp"] * 1000)
                      )}
                    </Box>
                  </Box>
                </Box>

                {allTokens.map((token: string) => (
                  <Box
                    key={token}
                    display="flex"
                    h="80px"
                    borderTop="1px"
                    borderColor="border"
                    alignItems="center"
                    justifyContent="space-between"
                    // bg="red.100"
                  >
                    <Box display="flex" w="100px" alignItems="center">
                      <Box display="flex">{getTransaction(token)}</Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        w="100px"
                      >
                        {
                          <Flex>
                            <Icon
                              iconName={getTokenName(token)}
                              h={5}
                              w={5}
                              mr={3}
                            />
                            <Text>{getTokenName(token)}</Text>
                          </Flex>
                        }
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        w="200px"
                      >
                        {getAmountChange(token)}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        w="160px"
                      >
                        {getFinalAmount(token)}
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        w="180px"
                      >
                        --
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        w="140px"
                      >
                        --
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        w="220px"
                      >
                        --
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex">
                    <Box display="flex" h="80px" alignItems="center">
                      {summaryData["YUSDchange"] > 0 ? (
                        <>
                          {summaryData["isDebtIncrease"] ||
                          summaryData["operation"] == "openTrove" ? (
                            <Center>Borrow</Center>
                          ) : (
                            <Center>Repay</Center>
                          )}
                        </>
                      ) : (
                        <>
                          <Center>No Change</Center>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box display="flex">
                      {
                        <Flex>
                          <Icon iconName={"YUSD"} h={5} w={5} mr={3} />
                          <Text>YUSD</Text>
                        </Flex>
                      }
                    </Box>
                    <Box display="flex" justifyContent="flex-end" w="200px">
                      {getDebtChange(
                        Number(summaryData["YUSDchange"]) / 10 ** 18
                      )}
                    </Box>
                    <Box display="flex" justifyContent="flex-end" w="160px">
                      --
                    </Box>
                    <Box display="flex" justifyContent="flex-end" w="190px">
                      <Text
                        textStyle="number_base"
                        color="text-primary"
                        textAlign={["right", "left"]}
                      >
                        {getNum(summaryData["debt"] / 10 ** 18, 3) + " "}
                      </Text>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" w="130px">
                      --
                    </Box>
                    <Box display="flex" justifyContent="flex-end" w="220px">
                      --
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Td py={3} borderColor="#313545" fontSize={14}>
                            {summaryData['operation']}
                        </Td>
                        
                        <VStack
                            divider={<StackDivider borderColor='gray.200' />}
                            spacing={2}
                            align='stretch'
                            >
                            {summaryData['collsIn'].map((token: string) => {
                                const tokenName = tokenData.find(t=> (t['address'].toLowerCase() == token.toLowerCase()))!['token']
                                return(
                                        <Box height={10}>
                                            <Flex>
                                            <Icon iconName={tokenName} h={5} w={5} mr={3} />
                                        <Text textStyle="subtitle3" fontWeight="normal" maxWidth={500}>{tokenName }</Text>
                                            </Flex>
                                        
                                        </Box>
                                    
                                )
                            })}
                            {summaryData['collsOut'].map((token: string) => {
                                const tokenName = tokenData.find(t=> (t['address'].toLowerCase() == token.toLowerCase()))!['token']
                                return(                     
                                        <Box width={50} height={10}>
                                            <Flex>
                                            <Icon iconName={tokenName} h={5} w={5} mr={3} />
                                        <Text textStyle="subtitle3" fontWeight="normal" maxWidth={500}>{tokenName }</Text>
                                            </Flex>
                                        
                                        </Box>   
                                )
                            })}
                        </VStack>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                            {summaryData['amountsIn'].map((amount: number) => {
                                const tokenAddress = summaryData['collsIn'][summaryData['amountsIn'].indexOf(amount)]
                                const tokenInfo = tokenData.find(t=> (t['address'].toLowerCase() == tokenAddress.toLowerCase()))
                                const tokenName = tokenInfo!['token']
                                const priceMap = vaultData['price']
                                const price = priceMap[tokenName.replace('-', '').replace(' ', '')]
                                const underlyingDecimals = tokenInfo!['underlyingDecimals'];
                                let realAmount = Number(amount) / 10 ** underlyingDecimals
                                let displayAmount = realAmount
                                return(
                                    <Box height={10}>
                                    <Text textStyle="subtitle3" color="green.400" textAlign={["right", "left"]}> +
                                        {(displayAmount !== 0 && displayAmount < 0.001 ? "< 0.001" : getNum(displayAmount, 3)) + " "}
                                        {<CurrencyConverter token={tokenName} value={realAmount} currency={"USD"} price={price}/>}
                                    </Text>
                                    </Box>
                                )
                            })}
                            {summaryData['amountsOut'].map((amount: number) => {
                                const tokenAddress = summaryData['collsOut'][summaryData['amountsOut'].indexOf(amount)]
                                const tokenInfo = tokenData.find(t=> (t['address'].toLowerCase() == tokenAddress.toLowerCase()))
                                const tokenName = tokenInfo!['token']
                                const priceMap = vaultData['price']
                                const price = priceMap[tokenName.replace('-', '').replace(' ', '')]
                                const underlyingDecimals = tokenInfo!['underlyingDecimals'];
                                let realAmount = Number(amount) / 10 ** 18
                                let displayAmount = realAmount
                                if (tokenInfo!['isVault']) { 
                                    displayAmount = Number(amount) / 10 ** underlyingDecimals * vaultData['underlyingPerReceipt'][tokenName.replace('-', '').replace(' ', '')] 
                                }
                                return(
                                    <Box height={10}>
                                    <Text textStyle="subtitle3" color="red.400" textAlign={["right", "left"]}> -
                                        {(displayAmount !== 0 && displayAmount < 0.001 ? "< 0.001" : getNum(displayAmount, 3)) + " "}
                                        {<CurrencyConverter token={tokenName} value={realAmount} currency={"USD"} price={price}/>}
                                    </Text>
                                    </Box>
                                )
                            })}
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{summaryData['totalValueChange']} </Text>
                        </Td>   
                        <Td py={3} borderColor="#313545" fontSize={14}>
                            {summaryData['isDebtIncrease']
                            ? <Text textStyle="subtitle3" color="green.400" textAlign={["right", "left"]}> +{getNum(Number(summaryData['YUSDchange']) / 10 ** 18, 2)} YUSD</Text>
                            : <Text textStyle="subtitle3" color="red.400" textAlign={["right", "left"]}> -{getNum(Number(summaryData['YUSDchange']) / 10 ** 18, 2)} YUSD</Text>}
                        </Td>
                        
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(Number(summaryData['debt']) * (10 ** -18), 2) + ' YUSD'} </Text>
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(totalValue * (10 ** -18), 2) + ' USD'} </Text>
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text textStyle="subtitle3" fontWeight="normal">{getNum(summaryData['currentICR'] * (10 ** -16), 2) + '%'} </Text>
                        </Td>
                        <Td py={3} borderColor="#313545" fontSize={14}>
                        <Text>{new Date(summaryData['timestamp'] * 1000).toLocaleString()}</Text>
                        </Td> */}
            </TokenTable>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TroveSummaryModal;
