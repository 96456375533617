import { createIcon } from "@chakra-ui/react";

const InvertedYeti = createIcon({
  displayName: "InvertedYeti",
  viewBox: "0 30 600 600",
  path: (
    <g>
      <circle
        cx="332"
        cy="350.42"
        r="271.8"
        fill="url(#b)"
        stroke="url(#c)"
        strokeMiterlimit="10"
        strokeWidth="21"
      />
      <path
        d="M206.3 251.22c-.7-2 22.6-14.9 51.6-23.1 28-7.9 75.6-15.5 140.2 2.1-28.8-1.5-57.7 1.8-85.5 9.7-5.1-.3-12.2-.7-20.8-.6-50.3.3-84.7 14.2-85.5 11.9Z"
        fill="url(#d)"
      />
      <path
        d="M334.7 242.62c9.4-2.3 18.9-4.2 28.5-5.5 18.2-2.5 36.5-3.1 54.8-1.8 8.7 1.8 17 5.5 24.2 10.7 22.4 16.5 25.8 42.9 26.7 50 3.3 25.5-6.8 45.1-10.8 52.2.6-6.3.5-12.6-.2-18.8-.7-6.3-4.4-39.1-32.2-60.7-10.5-8-22.8-13.4-35.8-15.8-18.4-3.4-36.8-6.8-55.2-10.3Z"
        fill="url(#e)"
      />
      <path
        d="M431.5 385.13c9.3 4.6 36.6 19.8 49 51 3 7.6 9.5 24.7 4.5 45.2-1.7 7-5.1 19.7-17.2 29.6-7.5 6.2-16.6 10-26.3 10.9-7.2.6-15.7-.5-39.2-13-14.4-7.6-28.2-16.2-41.4-25.7 8.3 2.4 16.8 3.6 25.4 3.6 9.2 0 20.1 0 31.3-5.9 1.6-.9 18.4-10.1 24-28.6 3-9.8.5-21.6-4.5-44.8-2.1-9.3-4.1-16.9-5.6-22.3Z"
        fill="url(#f)"
      />
      <path
        d="M351.3 486.72c9.1 7.2 18.6 13.8 28.6 19.7 12.1 7 24.9 12.9 38.1 17.7-7 .4-16.8.7-28.5.6-21.4-.2-58.6-.6-100.1-12.2-34.8-9.8-67.6-25.6-96.8-46.8 25.7 13.3 53.6 21.7 82.4 24.9 25.4 2.9 51.2 1.6 76.3-3.9Z"
        fill="url(#g)"
      />
      <path
        d="M331.9 153.82c1.6 4.6 4.1 10.9 5.4 17.7.9 4.5 5.2 21.4 6.4 25.7 2.4 7.5 3.5 10.2 4.7 15.3 1.5.3 3 .5 4.5.6 10.4.9 20.6 2.8 30.6 5.7-4.9-3.3-9.4-7.2-13.4-11.5-5.9-6.1-5-4.7-9.9-10.7-5.8-7-11.1-14.4-15.8-22.1-4.5-7-7.6-12-12.5-20.7Z"
        fill="url(#h)"
      />
      <path
        d="M328.9 159.92c-8.4 10.7-17.3 20.9-26.8 30.6-8.5 8.7-17.4 17-26.8 24.8 24.3-10.8 43.5-30.7 53.6-55.4Z"
        fill="url(#i)"
      />
      <ellipse
        cx="228.5"
        cy="334.22"
        rx="5.3"
        ry="8.8"
        fill="#006eb8"
        stroke="#006eb8"
        strokeMiterlimit="10"
      />
      <ellipse
        cx="339.8"
        cy="334.22"
        rx="5.3"
        ry="8.8"
        fill="#006eb8"
        stroke="#006eb8"
        strokeMiterlimit="10"
      />
      <path
        d="M193.3 258.22c-5.2 7.9-9.2 16.5-11.9 25.5-4.1 13.6-4.8 28.1-2 42 .3 6.3.2 12.6-.4 18.9-1.5 16.4-5.7 32.5-12.5 47.5-3.4 7.9-4.9 16.4-4.4 25 1.5 20.8 15.5 35.1 22 41-2-4.1-4.1-8.3-6.1-12.5-4-6.6-6.7-13.9-7.7-21.6-1.2-10.6.7-21.2 5.5-30.7 7.2-14.3 11.6-29.8 12.9-45.7.8-10.6.1-21.3-2.1-31.8-1-5.5-1.6-11.2-1.6-16.8-.1-14 2.8-27.9 8.3-40.8Z"
        fill="url(#j)"
      />
      <path
        d="M429.6 282.72c5.7 7.5 10.6 15.5 14.8 23.9 0 0 8.3 18.9 8.1 32.2 0 1.4.7 11 .9 17.4.3 7.7.3 8.3.5 10.5.3 2.2.7 4.4 1.3 6.5 1.5 5.7 3.8 11.1 6.9 16.1 4.7 8.2 7.1 14.7 14.2 20.7-4.1-3.5-5-3.9-10.7-8.3-3.1-2.8-8-6.4-19.6-16.4-7.1-6.2-10.5-9.7-13.1-14.8-3.1-6.3-4.6-13.3-4.2-20.3-.1-3.2.3-6.4 1-9.5 1.4-5.7 3.4-7.8 4.6-13.8.5-2.6.8-5.2.8-7.8.5-13.3-4.7-33.4-5.5-36.4Z"
        fill="url(#k)"
      />
      <defs>
        <linearGradient
          id="b"
          x1="-573.11"
          y1="710.98"
          x2="-572.11"
          y2="711.98"
          gradientTransform="rotate(32.51 3732.04649268 308.58787997)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".03" stopColor="#bfeeff" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="1131.94"
          y1="2719.67"
          x2="1675.63"
          y2="2567.14"
          gradientTransform="rotate(48.18 3431.9963877 298.22955906)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#91d5fd" />
          <stop offset=".13" stopColor="#8ad0fa" />
          <stop offset=".32" stopColor="#78c1f0" />
          <stop offset=".53" stopColor="#59aae1" />
          <stop offset=".77" stopColor="#2e8acb" />
          <stop offset="1" stopColor="#0067b4" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="206.28"
          y1="235.49"
          x2="398.1"
          y2="235.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#91d5fd" />
          <stop offset=".13" stopColor="#8ad0fa" />
          <stop offset=".32" stopColor="#78c1f0" />
          <stop offset=".53" stopColor="#59aae1" />
          <stop offset=".77" stopColor="#2e8acb" />
          <stop offset="1" stopColor="#0067b4" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="334.7"
          y1="291.44"
          x2="469.55"
          y2="291.44"
          xlinkHref="#d"
        />
        <linearGradient
          id="f"
          x1="360.9"
          y1="453.54"
          x2="486.83"
          y2="453.54"
          xlinkHref="#d"
        />
        <linearGradient
          id="g"
          x1="192.6"
          y1="495.23"
          x2="418"
          y2="495.23"
          xlinkHref="#d"
        />
        <linearGradient
          id="h"
          x1="331.9"
          y1="186.32"
          x2="383.5"
          y2="186.32"
          xlinkHref="#d"
        />
        <linearGradient
          id="i"
          x1="275.3"
          y1="187.62"
          x2="328.9"
          y2="187.62"
          xlinkHref="#d"
        />
        <linearGradient
          id="j"
          x1="162.01"
          y1="358.17"
          x2="193.3"
          y2="358.17"
          xlinkHref="#d"
        />
        <linearGradient
          id="k"
          x1="428.64"
          y1="346.37"
          x2="476.3"
          y2="346.37"
          xlinkHref="#d"
        />
      </defs>
    </g>
  ),
});

export default InvertedYeti;
