const Modal = {
  baseStyle: {
    dialog: {
      bg: "#242938",
      color: "white",
    },
  },
};

export default Modal;
