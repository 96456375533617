import { Box } from "@chakra-ui/react";
import React from "react";
import AdjustLiquidation from "../../PageComponents/LiquidationCalculator";

const LiquidationCalculator: React.FC = () => {
  return (
    <Box>
      <AdjustLiquidation />
    </Box>
  );
};

export default LiquidationCalculator;
