import React from "react";

import { Alert, CloseButton, Text, useDisclosure } from "@chakra-ui/react";

const Announcement: React.FC = () => {
  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: true });
  return isVisible ? (
    <Alert bg="#141724" status="error" borderBottom="1px" borderColor="#33374D">
      <>
        <Text color="gray.200" fontSize="13.5px" letterSpacing="tight">
          A new fee model will be going in effect once the audit is complete:
          est. October 24th. These changes include a reduction of one-time
          borrowing fees and and introduction of a 0.5% starting interest rate
          for new and existing borrowers. Existing borrowers will receive a
          rebate for future interest paid.{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={
              "https://medium.com/@yetifinance/the-path-to-becoming-the-best-defi-borrowing-protocol-790459ddf93d"
            }
            style={{
              outline: "none",
              textDecoration: "underline",
              color: "#8ABEFB",
            }}
          >
            More
          </a>
        </Text>
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      </>
    </Alert>
  ) : (
    <></>
  );
};

export default Announcement;
