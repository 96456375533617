import {
  Box,
  chakra,
  Stack,
  Text,
  VisuallyHidden,
  Icon,
  Switch,
  useColorMode,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { SiDiscord, SiMedium, SiTelegram, SiTwitter } from "react-icons/si";
// import Link from "../Link";

const SocialButton = ({
  children,
  label,
  href,
  target,
}: {
  children: ReactNode;
  label: string;
  href: string;
  target: string;
}) => {
  return (
    <chakra.button
      bg="surface"
      rounded="full"
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target={target}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "surface-hover",
        color: "text-primary",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Box
      width="full"
      py="16px"
      borderTop="1px"
      borderColor="border"
      color="text-secondary"
      mt="64px"
    >
      <Box
        display="flex"
        gap="24px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gap="24px">
          <a
            href="https://techdocs.yeti.finance/about-yeti-finance/audits-and-risks"
            target="_blank"
            rel="noreferrer"
          >
            <Text>Audits</Text>
          </a>
          <a href="https://docs.yeti.finance/" target="_blank" rel="noreferrer">
            <Text>Docs</Text>
          </a>

          {/* <Link to={"/terms"} label={"Terms"}>
            Terms
          </Link> */}

          <Switch
            onChange={toggleColorMode}
            id="email-alerts"
            colorScheme="gray"
          />
        </Box>
        <Stack direction={"row"} spacing="16px">
          <SocialButton
            label={"Twitter"}
            href={"https://twitter.com/YetiFinance"}
            target="_blank"
          >
            <Icon as={SiTwitter} />
          </SocialButton>
          <SocialButton
            label={"Medium"}
            href={"https://blog.yetifinance.co/"}
            target="_blank"
          >
            <Icon as={SiMedium} />
          </SocialButton>
          <SocialButton
            label={"Discord"}
            href={"https://discord.com/invite/SgZURK9ped"}
            target="_blank"
          >
            <Icon as={SiDiscord} />
          </SocialButton>
          <SocialButton
            label={"Telegram"}
            href={"https://t.me/yetifinance"}
            target="_blank"
          >
            <Icon as={SiTelegram} />
          </SocialButton>
        </Stack>
      </Box>
    </Box>
  );
}

{
  /* <Link href="https://yetifinance.medium.com/" isExternal h={10} w={10}>
<Icon
  as={SiMedium}
  color="#949494"
  h={10}
  w={10}
  _hover={{ color: "white" }}
/>
</Link>
<Link href="https://twitter.com/YetiFinance" isExternal h={10} w={10}>
<Icon
  as={SiTwitter}
  color="#949494"
  h={10}
  w={10}
  _hover={{ color: "white" }}
/>
</Link>
<Link href="http://discord.gg/SgZURK9ped" isExternal h={10} w={10}>
<Icon
  as={SiDiscord}
  color="#949494"
  h={10}
  w={10}
  _hover={{ color: "white" }}
/>
</Link>
<Link href="https://t.me/yetifinance" isExternal h={10} w={10}>
<Icon
  as={SiTelegram}
  color="#949494"
  h={10}
  w={10}
  _hover={{ color: "white" }}
/>
</Link> */
}
