const radii = {
  input: "0.25rem",
  button: "0.375rem",
  microbutton: "0.125rem",
  card: "0.5rem",
  lgCard: "1.5rem",
  infinity: "9999px",
};

export default radii;
