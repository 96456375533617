import React from "react";

import { Tooltip as ChakraTooltip, TooltipProps } from "@chakra-ui/react";
import Icon from "../Icon";

const Tooltip: React.FC<TooltipProps> = ({ children, ...props }) => {
  return (
    <ChakraTooltip
      label={children}
      placement="top"
      {...props}
      bg="surface"
      color="text-primary"
      py="12px"
      px="24px"
      borderWidth="1px"
      borderColor="border"
      rounded="8px"
      shadow="rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;"
    >
      <Icon iconName="Tooltip" ml="2px" h={3} w={3} />
    </ChakraTooltip>
  );
};

export default Tooltip;
