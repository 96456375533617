const shadows = {
  shadow_sm: "rgba(0, 0, 0, 0.04) 0px 3px 5px;",
  shadow_md: "rgba(0, 0, 0, 0.02) 0px 4px 12px;",
  shadow_lg: "rgba(50, 50, 93, 0.03) -4px 9px 16px -6px;",
  button:
    "rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px .5px 1px 0px;",
  button_hover:
    "rgba(50, 50, 93, 0.05) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 0px -1px;",
};

export default shadows;
