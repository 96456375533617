import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Progress,
  // Divider,
  useDisclosure,
} from "@chakra-ui/react";
// import { ConnectButton } from "../../../Components/WalletConnector";
// import ConfirmCloseTroveModal from "../ConfirmCloseTroveModal";
import {
  calculateHealth,
  calculateHealthColor,
  calculateHealthStableTrove,
} from "./Trove.utils";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { TroveMappings, CoinMode, AdjustedTokenData } from "../../../Types";
import { getNum, format } from "../../../Utils/number";
import { useEffect } from "react";
import { useState } from "react";
import Tooltip from "../../../Components/Tooltip";
import { VC_explanation } from "../../../Utils/constants";

export type TroveProps = {
  disconnected?: boolean;
  borrowMode: "normal" | "lever" | "unlever";
  setBorrowMode: any;
  values: { [key: string]: any };
  currVcValue: number;
  currUSDValue: number;
  stableVC: number;
  mode: CoinMode;
  depositFees: TroveMappings;
  adjustedCollateral: AdjustedTokenData[];
  validateFunc: any;
  troveBalance: number;
};

const selector = ({
  trove,
  underlyingPrices,
  tokenBalances,
  icr,
  decimals,
  safetyRatios,
  prices,
  receiptPerUnderlyingRatios,
  borrowingRate,
  vcValue,
}: LiquityStoreState) => ({
  trove,
  underlyingPrices,
  tokenBalances,
  icr,
  decimals,
  safetyRatios,
  prices,
  receiptPerUnderlyingRatios,
  borrowingRate,
  vcValue,
});

const dataSelector = useLiquitySelector;

const Trove: React.FC<TroveProps> = ({
  disconnected = false,
  borrowMode,
  setBorrowMode,
  values,
  currVcValue,
  currUSDValue,
  stableVC,
  mode,
  depositFees,
  adjustedCollateral,
  validateFunc,
  troveBalance,
}) => {
  const { trove, icr, vcValue } = dataSelector(selector);

  const healthRatio = format(icr) * 100;

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isFixed, setIsFixed] = useState<"absolute" | "fixed">("absolute");
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  if (scrollPosition > 200 && isFixed == "absolute") {
    setIsFixed("fixed");
  } else if (scrollPosition <= 200 && isFixed == "fixed") {
    setIsFixed("absolute");
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {
    isOpen: isCloseTroveOpen,
    onOpen: onCloseTroveOpen,
    onClose: onCloseTroveClose,
  } = useDisclosure();

  const troveHealth =
    stableVC * 1.1 > troveBalance && stableVC / format(vcValue) > 0.99
      ? calculateHealthStableTrove(healthRatio)
      : calculateHealth(healthRatio);
  if (trove && trove.status !== "open" && Object.keys(values).length < 2) {
    return (
      <Box
        layerStyle="card"
        w="380px"
        // borderTopLeftRadius="0px"
        // borderTopRightRadius="0px"
        position="sticky"
        top="366px"
        paddingTop="0px"
        mt="16px"
      >
        <Text textStyle="card_header">Borrow Summary</Text>
        <Text textStyle="card_text" fontWeight="normal" mt="32px">
          Nothing deposited yet.
        </Text>
        <Text textStyle="card_text" fontWeight="normal" mt="16px">
          Start by adding collateral.
        </Text>
      </Box>
    );
  }

  return (
    <Box
      layerStyle="card"
      w="380px"
      // borderTopLeftRadius="0px"
      // borderTopRightRadius="0px"
      position="sticky"
      top="366px"
      paddingTop="0px"
      // mt="16px"
      textStyle="number_base"
      color="text-primary"
    >
      {/* {/* <Text textStyle="title3">Your Portfolio</Text>
      {format(vcValue) != currVcValue && (
        <Text textStyle="subtitle2" fontWeight="normal" color="#6079FF">
          (with changes)
        </Text>
      )} */}

      {/* <Box display="flex" alignItems="center" pb="16px">
        <Box display="flex" w="full">
          <Text textStyle="card_header">Borrow Summary</Text>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            fontSize="14px"
            rounded="14px"
            color="red.400"
            fontWeight="normal"
            disabled={trove.status !== "open" && true}
            variant="text"
            onClick={onCloseTroveOpen}
          >
            Close Trove
          </Button>
        </Box>
      </Box> */}

      <Box display="flex" flexDir="column" pt="16px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text
            textStyle="subheading"
            color="text-secondary"
            fontWeight="normal"
          >
            Collateral Ratio{" "}
            <Tooltip>Ratio of Risk Adjusted Value to YUSD Debt</Tooltip>
          </Text>
          <Text textStyle="card_text">
            {isNaN((currVcValue * 100) / troveBalance)
              ? "0%"
              : `${((currVcValue * 100) / troveBalance).toFixed(3)}%`}
          </Text>
        </Box>
        <Flex direction={["column", "row"]} alignItems="center" pt="12px">
          <Progress
            value={troveHealth}
            size="sm"
            w="100%"
            colorScheme={calculateHealthColor(troveHealth)}
            bg="surface-highlight"
          />
        </Flex>
        <Text
          textStyle="text_xs"
          color="text-secondary"
          fontWeight="normal"
          pt="4px"
        >
          110% MIN
        </Text>
      </Box>

      <Flex direction="row" justifyContent="space-between" pt="24px">
        <Text>
          Safety Rating{" "}
          <Tooltip>
            Score from 0 to 100 that helps trove owners to understand how safe
            from liquidation their trove is. Learn more from docs.
          </Tooltip>
        </Text>
        <Text color="text-primary" fontWeight="700">
          {troveHealth.toFixed(3)}
        </Text>
      </Flex>

      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pt="24px"
      >
        <Text>
          Risk Adjusted Value <Tooltip>{VC_explanation}</Tooltip>
        </Text>
        <Text fontWeight="700">{`$${getNum(currVcValue, 2)}`}</Text>
      </Flex>

      {/* <Box>
      <Divider borderColor="border" pt="24px" />
      </Box> */}

      {/* <Divider borderColor="border" pt="24px" /> */}

      {/* Borrow Balance */}
      <Box
        display="flex"
        pt="24px"
        w="full"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>Borrow Balance</Text>
        <Text fontWeight="700">{`$${getNum(troveBalance, 2)}`}</Text>
      </Box>

      {/* Collateral Balance */}
      <Box
        pt="24px"
        display="flex"
        w="full"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text>Collateral Balance</Text>
        <Text fontWeight="700">
          {`$${getNum(currUSDValue * +String(1), 2)}`}
        </Text>
      </Box>

      <Flex direction="column" flex={1} mt={8}>
        {disconnected ? (
          // <ConnectButton w="100%" />
          <Box>temporary disconnected</Box>
        ) : (
          <>
            {format(vcValue) === currVcValue ? (
              <Button w="100%" variant="newPrimary" mb={4} disabled={true}>
                Review Order
              </Button>
            ) : (
              <Button
                w="100%"
                variant="newPrimary"
                mb={4}
                disabled={format(vcValue) === currVcValue && true}
                onClick={() =>
                  validateFunc(
                    values,
                    ((currVcValue * 100) / troveBalance).toFixed(3),
                    troveBalance
                  )
                }
              >
                Review Order
              </Button>
            )}

            {/* <ConfirmCloseTroveModal
              isOpen={isCloseTroveOpen}
              onClose={onCloseTroveClose}
            /> */}
            {/* {borrowMode === "close" ? (
                <Button w="100%" variant="secondary" onClick={() => setBorrowMode("normal")} mt={4}>
                  Adjust Trove
                </Button>
              ) : (
                <Button w="100%" variant="secondary" onClick={() => setBorrowMode("close")} mt={4}>
                  Close Trove Auto-Sell
                </Button>
              )} */}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Trove;
