import React from "react";
import { paths } from "./Navbar.constants";
import {
  Menu,
  Box,
  useDisclosure,
  IconButton,
  Text,
  Hide,
} from "@chakra-ui/react";
import Link from "./Link";
import UserDetails from "./UserDetails";
import { ConnectButton } from "../ConnectButton";
import { Link as Link2 } from "react-router-dom";
import {
  useMediaQuery,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { getNum } from "../../Utils/number";
import { useLiquitySelector } from "@yeti/lib-react";
import { LiquityStoreState } from "@yeti/lib-base";
import Icon from "../Icon";

export type NavbarProps = {
  walletConnected: boolean;
  dashboardVisible: boolean;
  snow?: 0 | 1;
  setSnow?: any;
};

const selector = ({ yusdBalance }: LiquityStoreState) => ({
  yusdBalance,
});

const Navbar: React.FC<NavbarProps> = ({
  dashboardVisible,
  walletConnected,
  snow,
  setSnow,
}) => {
  const { yusdBalance } = useLiquitySelector(selector);
  const [isMobile] = useMediaQuery("(max-width: 764px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const walletBalance = +yusdBalance;
  return (
    <Box mb="48px" py="24px">
      {!isMobile ? (
        <>
          <Box
            // bg="#141724"
            display="flex"
            w="full"
            // mb="48px"
            zIndex="20"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom="1px"
            // borderColor="#33374D"
            // position="fixed"
            // bg="blackAlpha.200"
            // backdropFilter="auto"
            // backdropBrightness="1"
            // backdropBlur="8px"
          >
            <Box zIndex="50">
              <Link2 to="/dashboard">
                <Box display="flex" alignItems="center" left="0px">
                  <img
                    src="/img/3d-yeti.png"
                    alt="Yeti Finance"
                    style={{ height: "40px" }}
                  />
                  <Text
                    fontSize="24px"
                    ml="8px"
                    fontWeight="700"
                    color="white"
                    letterSpacing="tight"
                  >
                    Yeti
                  </Text>
                </Box>
              </Link2>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              // bg="#272937"
              // py="6px"
              // px="4px"
              // rounded="full"
              // shadow="shadow_sm"
              // border="1px"
              borderColor="#33374D"
              position="absolute"
              left="0px"
              right="0px"
              ml="auto"
              mr="auto"
              justifyContent="center"
              zIndex="1"
            >
              {dashboardVisible && <Link to="/" label="Borrow" />}

              {paths.slice(1).map(({ path, label }) => (
                <Box key={`${path} ${label}`}>
                  {path !== "/more" ? (
                    <Link key={path} to={path} label={label} />
                  ) : (
                    <></>
                    // <Menu>
                    //   <MenuButton
                    //     key={"more"}
                    //     as={Button}
                    //     rounded={"full"}
                    //     variant={"link"}
                    //     cursor={"pointer"}
                    //     bg="red.100"
                    //     w="50px"
                    //   >
                    //     <Link key={path} to={path} label={label} />
                    //   </MenuButton>
                    //   <MenuList key={"list"}>
                    //   <MenuItem key={"stats"}>
                    //       <Link
                    //         key={"/stats"}
                    //         to={"/stats"}
                    //         label={"Stats"}
                    //         hasRadius={false}
                    //       />
                    //     </MenuItem>
                    //     <MenuItem key={"calculator"}>
                    //       <Link
                    //         key={"/calculator"}
                    //         to={"/calculator"}
                    //         label={"Calculator"}
                    //         hasRadius={false}
                    //       />
                    //     </MenuItem>
                    //     <MenuItem key={"strategies"}>
                    //       <Link
                    //         key={"/strategies"}
                    //         to={"/strategies"}
                    //         label={"Strategies"}
                    //         hasRadius={false}
                    //       />
                    //     </MenuItem>
                    //     <MenuItem key={"trovehistory"}>
                    //       <Link
                    //         key={"/trovehistory"}
                    //         to={"/trovehistory"}
                    //         label={"Trove History"}
                    //         hasRadius={false}
                    //       />
                    //     </MenuItem>
                    //     <MenuItem key={"redemption"}>
                    //       <Link
                    //         key={"/redemption"}
                    //         to={"/redemption"}
                    //         label={"Redeem"}
                    //         hasRadius={false}
                    //       />
                    //     </MenuItem>
                    //   </MenuList>
                    // </Menu>
                  )}
                </Box>
              ))}
              <Menu>
                <MenuButton
                  ml="8px"
                  color="#EAEDF0"
                  fontSize="15px"
                  fontWeight="700"
                  as={Button}
                  variant={"link"}
                  rightIcon={<ChevronDownIcon />}
                >
                  More
                </MenuButton>
                <MenuList
                  bg="#272937"
                  border="1px"
                  borderColor="#33374D"
                  rounded="14px"
                >
                  <MenuItem
                    bg="#272937"
                    _hover={{ bg: "#343647" }}
                    _focus={{ bg: "#343647", color: "white" }}
                  >
                    <Link
                      to={"/calculator"}
                      label={"Calculator"}
                      hasRadius={false}
                    >
                      Calculator
                    </Link>
                  </MenuItem>
                  <MenuItem _hover={{ bg: "#343647" }}>
                    <Link
                      to={"/strategies"}
                      label={"Strategies"}
                      hasRadius={false}
                    >
                      Strategies
                    </Link>
                  </MenuItem>
                  <MenuItem _hover={{ bg: "#343647" }}>
                    <Link to={"/redemption"} label={"Redeem"} hasRadius={false}>
                      Redeem
                    </Link>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Box display="flex" gap="4px" zIndex="50">
              {walletConnected ? (
                <Box display="flex" alignItems="center">
                  <Hide below="1000px">
                    <Box display="flex" alignItems="center">
                      <Icon mr="4px" iconName={"YUSD"} h="20px" w="20px" />
                      <Text
                        mr="16px"
                        textStyle="number_base"
                        color="text-secondary"
                      >
                        {getNum(walletBalance)}
                      </Text>
                    </Box>
                  </Hide>

                  <UserDetails onChange={setSnow} />
                </Box>
              ) : (
                <ConnectButton />
              )}
            </Box>
          </Box>
        </>
      ) : (
        <>
          {/* MOBILE - UNOPENED */}
          <Box
            h="80px"
            display="flex"
            px="16px"
            flexDir="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link2 to="/dashboard">
              <Box zIndex="10">
                <img
                  // src="/img/3d-yeti.png"
                  src="/img/3d-yeti.png"
                  alt="Yeti Finance"
                  style={{ height: "40px" }}
                />
              </Box>
            </Link2>
            <Box display="flex" gap="8px">
              {walletConnected ? (
                <UserDetails onChange={setSnow} />
              ) : (
                <ConnectButton />
              )}
              <IconButton
                size={"md"}
                zIndex="10"
                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                color="white"
                aria-label={"Open Menu"}
                onClick={isOpen ? onClose : onOpen}
                bg="#272937"
                _hover={{ bg: "#343647 " }}
              />
            </Box>
          </Box>

          {/* MOBILE - OPENED */}
          {isOpen ? (
            <Box
              position="fixed"
              bg="#272937"
              zIndex="9"
              top="0"
              right="0"
              left="0"
              bottom="0"
              display={{ md: "none" }}
            >
              <Box
                display="flex"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                h="100vh"
              >
                <Box
                  gap="16px"
                  display="flex"
                  flexDir="column"
                  alignItems="center"
                >
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link to={"/borrow"} label={"Borrow"} isMobile={false} />
                  </Box>
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link
                      to={"/farm"}
                      label={"Farm"}
                      hasRadius={false}
                      isMobile={false}
                    />
                  </Box>
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link
                      to={"/veYeti"}
                      label={"Stake"}
                      hasRadius={false}
                      isMobile={false}
                    />
                  </Box>
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link
                      to={"/stats"}
                      label={"Stats"}
                      hasRadius={false}
                      isMobile={false}
                    />
                  </Box>
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link
                      to={"/calculator"}
                      label={"Calculator"}
                      hasRadius={false}
                      isMobile={false}
                    />
                  </Box>
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link
                      to={"/strategies"}
                      label={"Strategies"}
                      hasRadius={false}
                      isMobile={false}
                    />
                  </Box>
                  <Box onClick={isOpen ? onClose : onOpen}>
                    <Link
                      to={"/redemption"}
                      label={"Redeem"}
                      hasRadius={false}
                      isMobile={false}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default Navbar;
