import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { getNum } from "../../../Utils/number";
import Icon from "../../../Components/Icon";
import { useState } from "react";
import { useEffect } from "react";

const TJFarmPool: React.FC = () => {
  const [JOEAPR, setJOEAPR] = useState(0);
  const [YETIAPR, setYETIAPR] = useState(0);
  const [poolAPR, setPoolAPR] = useState(0);
  useEffect(() => {
    const joeURL = "https://api.yeti.finance/v1/FarmPool/JOE";
    const yetiURL = "https://api.yeti.finance/v1/FarmPool/YETI";
    const poolURL = "https://api.yeti.finance/v1/FarmPool/pool";
    const fetchData = async () => {
      try {
        const joeResponse = await fetch(joeURL, {
          method: "GET",
          mode: "cors",
        });
        const yetiResponse = await fetch(yetiURL, {
          method: "GET",
          mode: "cors",
        });
        const poolResponse = await fetch(poolURL, {
          method: "GET",
          mode: "cors",
        });
        setJOEAPR(await joeResponse.json());
        setYETIAPR(await yetiResponse.json());
        setPoolAPR(await poolResponse.json());
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Box
        layerStyle="card"
        w={{ sm: "full", md: "340px" }}
        textStyle="number_base"
        p="24px"
      >
        <Box display="flex" flexDir="column" justifyContent="space-between">
          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px"
            borderColor="border"
            pb="24px"
          >
            <Box>
              <Text textStyle="card_header">
                <Icon iconName={"YETI"} h="24px" w="24px" />{" "}
                <Icon iconName={"AVAX"} h="24px" w="24px" />
                YETI / AVAX LP
              </Text>
              <Text textStyle="asset" color="green.300" pt="8px">
                Earn LP fees
              </Text>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            pt="24px"
            justifyContent="space-between"
          >
            <Text>Pool Rewards</Text>
            <Text textStyle="subtitle3" fontWeight="normal" ml={1}>
              {getNum(poolAPR * 100, 2)}%
              <Icon iconName={"YETI"} h={6} w={6} mb={1} />
            </Text>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            pt="24px"
            justifyContent="space-between"
          >
            <Text>YETI Rewards:</Text>
            <Text textStyle="subtitle3" fontWeight="normal" ml={1}>
              {getNum(YETIAPR * 100, 2)}%
            </Text>
          </Box>
          {/* <Flex alignItems="center" ml={5} px={2} mt={4}>
              <Icon iconName={"sJOE"} h={6} w={6} /> 
            </Flex> */}
          {/* <Flex alignItems="center" pr={2} mt={4}>
              <Text textStyle="subtitle3" color="#9B9EAC" fontWeight="normal">
                JOE APR: 
              </Text>
              <Text textStyle="subtitle3" color="white" fontWeight="normal" ml={1}>
                {getNum(JOEAPR * 100, 2)}%
              </Text>
            </Flex> */}
          <Box
            display="flex"
            alignItems="center"
            pt="24px"
            justifyContent="space-between"
          >
            <Text>Total Rewards</Text>
            <Text color="green.300" fontWeight="bold">
              {getNum(((poolAPR ? poolAPR : 0) + YETIAPR + JOEAPR) * 100, 2)}%
            </Text>
          </Box>
          <Box display="flex" w="full" alignItems="center">
            <Button variant="surface" w="full" mt="32px">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://traderjoexyz.com/farm/0xbdc7EF37283BC67D50886c4afb64877E3e83f869-0x188bED1968b795d5c9022F6a0bb5931Ac4c18F00?fm=fm/"
                }
                style={{
                  outline: "none",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                Stake on Trader Joe
              </a>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TJFarmPool;
