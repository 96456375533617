import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
// import { ConnectCard } from "../../Components";
// import { AdjustTrove } from "../../PageComponents/Vaults";
// import { LiquityStoreState } from "@yeti/lib-base";
// import { useLiquitySelector } from "@yeti/lib-react";
// import tokenData from "../../TokenData";
// import { formatWithDecimals } from "../../Utils/number";
// import { CoinShow } from "../../Types";

export type BorrowProps = {
  disconnected?: boolean;
};

// const selector = ({ tokenBalances, vaultDepositInfo }: LiquityStoreState) => ({
//   tokenBalances,
//   vaultDepositInfo,
// });

const Vaults: React.FC<BorrowProps> = ({ disconnected = false }) => {
  // const { tokenBalances, vaultDepositInfo } = useLiquitySelector(selector);
  // console.log("vaultDeposits", vaultDepositInfo);
  // // Coin Display Config State
  // const coinShow: CoinShow = {};
  // tokenData.forEach((coin) => {
  //   if (tokenBalances[coin.isVault ? coin.underlying : coin.address].isZero) {
  //     coinShow[coin.token] = false;
  //   } else {
  //     coinShow[coin.token] = true;
  //   }
  // });

  // const [show, setShow] = useState<CoinShow>(coinShow);
  // const availableCollateral = tokenData.filter((coin) => !show[coin.token]);
  // const currentCollateral = tokenData.filter((coin) => show[coin.token]);

  // console.log(show);

  return (
    <>
      <Box
        w="full"
        h="530px"
        bg="#181A21"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Flex
        direction="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          color="white"
          textStyle="display_md"
          borderBottom="1px"
          borderColor="#31354F"
          pb="8px"
        >
          Hello
        </Text>
        {/* <>
          <AdjustTrove
            disconnected={disconnected}
            borrowMode={"normal"}
            setBorrowMode={setBorrowMode}
          />
        </> */}
      </Flex>
    </>
  );
};

export default Vaults;
