import {
  Box,
  useDisclosure,
  Flex,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  NumberInput,
  NumberInputField,
  SliderMark,
} from "@chakra-ui/react";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import React, { useEffect, useState } from "react";
import { TokenTable, Icon } from "../../../Components";
import { CoinShow, TokenData } from "../../../Types";
import { format, getNum } from "../../../Utils/number";
import AddCollateralTypeModal from "../../Borrow/AddCollateralTypeModal";
import OverallStats from "../OverallStats";
import { useLiquity } from "../../../Hooks/LiquityContext";

type CollateralCalculatorProps = {
  collateral: TokenData[];
};

type CollateralStats = {
  adjustedPrice: number;
  adjustedPriceString: string;
  weightedCollateralValue: number;
  maxAdjustedPrice: number;
  minAdjustedPrice: number;
  adjustmentStep: number;
};

export type AdjustedCollateral = TokenData &
  CollateralStats & {
    troveBalanceString: string;
    underlyingPrices: number;
  };

export type OverallCollateralStats = CollateralStats & {
  weightedStablecoinCollateralValue: number;
};

type CalculatorState = {
  adjustedCollaterals: AdjustedCollateral[];
  overallStats: CollateralStats;
};

// this can be made more generic to handle stable coins and moved to a global util file
export const isStableCoin = (coin: TokenData) => coin.isStable === true;
export const getOverallWeightedValue = (collaterals: AdjustedCollateral[]) => {
  const value = collaterals.reduce(
    (acc, item) => acc + item.weightedCollateralValue,
    0
  );

  return value;
};

const selector = ({
  trove,
  underlyingPrices,
  safetyRatios,
}: LiquityStoreState) => ({
  trove,
  underlyingPrices,
  safetyRatios,
});

const CollateralCalculator: React.FC<CollateralCalculatorProps> = ({
  collateral,
}) => {
  const {
    isOpen: isAddCollateralTypeOpen,
    onOpen: onAddCollateralTypeOpen,
    onClose: onAddCollateralTypeClose,
  } = useDisclosure();
  const { liquity } = useLiquity();
  const { trove, underlyingPrices, safetyRatios } =
    useLiquitySelector(selector);

  const coinShow: CoinShow = {};
  collateral.forEach((coin) => {
    if (coin.troveBalance === 0) {
      coinShow[coin.token] = false;
    } else {
      coinShow[coin.token] = true;
    }
  });

  const [show, setShow] = useState<CoinShow>(coinShow);

  const initialAdjustedCollateral: AdjustedCollateral[] = collateral
    .filter((coin) => show[coin.token])
    .map((coin) => {
      const coinPrice = format(underlyingPrices[coin.address]);
      const priceAdjustmentSpread = isStableCoin(coin)
        ? coinPrice * 1.25 - coinPrice * 0.75
        : coinPrice * 5 - coinPrice;

      return {
        ...coin,
        underlyingPrices: coinPrice,
        troveBalanceString: (
          coin.troveBalance * format(safetyRatios[coin.address])
        ).toFixed(3),
        adjustedPrice: coinPrice,
        adjustedPriceString: coinPrice.toFixed(3),
        weightedCollateralValue:
          coin.troveBalance * coinPrice * format(safetyRatios[coin.address]),
        maxAdjustedPrice: isStableCoin(coin) ? coinPrice * 1.25 : coinPrice * 6,
        minAdjustedPrice: isStableCoin(coin) ? coinPrice * 0.75 : 0,
        adjustmentStep: priceAdjustmentSpread / 20,
      };
    });

  const initialCalculatorState: CalculatorState = {
    adjustedCollaterals: initialAdjustedCollateral,
    overallStats: {
      adjustedPrice: 0,
      adjustedPriceString: "0",
      weightedCollateralValue: getOverallWeightedValue(
        initialAdjustedCollateral
      ),
      maxAdjustedPrice: 500,
      minAdjustedPrice: -100,
      adjustmentStep: 5,
    },
  };

  const [calculatorState, setCalculatorState] = useState<CalculatorState>(
    initialCalculatorState
  );

  let availableCollateral: TokenData[] = collateral.filter(
    (coin) => !show[coin.token]
  );

  const handleCollateralChange = (
    collateral: AdjustedCollateral,
    index: number
  ) => {
    const originalWeightedCollateralValue =
      calculatorState.adjustedCollaterals.reduce(
        (total, collateralItem) =>
          total +
          collateralItem.troveBalance *
            format(collateralItem.underlyingPrices) *
            format(safetyRatios[collateralItem.address]),
        0
      );

    const newCollaterals = [...calculatorState.adjustedCollaterals];
    collateral.weightedCollateralValue =
      collateral.troveBalance *
      format(collateral.adjustedPrice) *
      format(safetyRatios[collateral.address]);
    newCollaterals[index] = collateral;

    const newWeightedCollateralValue = getOverallWeightedValue(newCollaterals);

    setCalculatorState({
      adjustedCollaterals: newCollaterals,
      overallStats: {
        ...calculatorState.overallStats,
        weightedCollateralValue: newWeightedCollateralValue,
        adjustedPrice:
          ((newWeightedCollateralValue - originalWeightedCollateralValue) /
            originalWeightedCollateralValue) *
          100,
      },
    });
  };

  useEffect(() => {
    const oldTokens = new Set(
      calculatorState.adjustedCollaterals.map((collateral) => collateral.token)
    );
    const addedTokens = new Set(
      Object.entries(show)
        .filter(([token, isShown]) => !!isShown && !oldTokens.has(token))
        .map(([token]) => token)
    );

    availableCollateral = collateral.filter((coin) => !show[coin.token]);

    const newCollaterals: AdjustedCollateral[] = collateral
      .filter((coin) => addedTokens.has(coin.token))
      .map((coin) => {
        const coinPrice = format(underlyingPrices[coin.address]);
        const priceAdjustmentSpread = isStableCoin(coin)
          ? coinPrice * 1.25 - coinPrice * 0.75
          : coinPrice * 5 - coinPrice;

        return {
          ...coin,
          underlyingPrices: coinPrice,
          adjustedPrice: coinPrice,
          troveBalanceString: coin.troveBalance.toFixed(3),
          adjustedPriceString: coinPrice.toString(),
          weightedCollateralValue:
            coin.troveBalance * coinPrice * format(safetyRatios[coin.address]),
          maxAdjustedPrice: isStableCoin(coin)
            ? coinPrice * 1.25
            : coinPrice * 6,
          minAdjustedPrice: isStableCoin(coin) ? coinPrice * 0.75 : 0,
          adjustmentStep: priceAdjustmentSpread / 20,
        };
      });

    if (!newCollaterals.length) {
      return;
    }

    const newAdjustedCollaterals = [
      ...calculatorState.adjustedCollaterals,
      ...newCollaterals,
    ];

    const originalTotalPrice = newAdjustedCollaterals.reduce(
      (underlyingPrices, collateralItem) =>
        underlyingPrices + collateralItem.underlyingPrices,
      0
    );

    const adjustedTotalPrice = newAdjustedCollaterals.reduce(
      (underlyingPrices, collateralItem) =>
        underlyingPrices + collateralItem.adjustedPrice,
      0
    );

    setCalculatorState({
      adjustedCollaterals: newAdjustedCollaterals,
      overallStats: {
        ...calculatorState.overallStats,
        weightedCollateralValue: getOverallWeightedValue(
          newAdjustedCollaterals
        ),
        adjustedPrice:
          ((adjustedTotalPrice - originalTotalPrice) / originalTotalPrice) *
          100,
      },
    });
  }, [show]);

  const overallStats = {
    ...calculatorState.overallStats,
    weightedStablecoinCollateralValue: getOverallWeightedValue(
      calculatorState.adjustedCollaterals.filter((collateral) =>
        isStableCoin(collateral)
      )
    ),
  };

  return (
    <>
      <Box
        w="full"
        h="280px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text
        color="white"
        textStyle="display_md"
        borderBottom="1px"
        borderColor="#31354F"
        pb="8px"
      >
        Calculator
      </Text>
      <Box layerStyle="card" p="24px" mt="48px">
        <OverallStats
          collaterals={calculatorState.adjustedCollaterals}
          overallTroveStats={overallStats}
          setCalculatorState={setCalculatorState}
          debt={format(trove.debt["debt"])}
          safetyRatios={safetyRatios}
        />
        <Box>
          <Text textStyle="title4" mb={4} mt={12}>
            Collateral
          </Text>
          <Box
            display="flex"
            pb="16px"
            textStyle="text_sm"
            color="text-secondary"
            pt="16px"
            w="full"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex">
              <Box display="flex">
                <Text>Asset</Text>
              </Box>
            </Box>
            <Box display="flex">
              <Box w="150px" display="flex" justifyContent="flex-end">
                <Text>Deposit Balance</Text>
              </Box>
              <Box w="210px" display="flex" justifyContent="flex-end">
                <Text>Current Price</Text>
              </Box>
              <Box w="132px" display="flex" justifyContent="flex-end">
                <Text>Simulated Price</Text>
              </Box>
              <Box w="264px" display="flex" justifyContent="flex-end">
                <Text>Simulated Price</Text>
              </Box>
              <Box w="200px" display="flex" justifyContent="flex-end">
                <Text>RAV</Text>
              </Box>
              {/* <Box display="flex" w="60px">
              <Text>Action</Text>
            </Box> */}
            </Box>
            {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
          </Box>
          <Box display="flex" flexDir="column">
            <TokenTable
            // headers={[
            //   "Collateral",
            //   "Balance",
            //   "Current Price",
            //   "Price Slider",
            //   "Price",
            //   "",
            //   "Safety Ratio",
            //   "",
            //   "Risk Adjusted Value (RAV)",
            // ]}
            // tooltips={[
            //   "A collateral in your trove",
            //   "The number of tokens in your trove",
            //   "The current market underlyingPrices of the collateral asset",
            //   "Simulate underlyingPrices changes of your current trove's collaterals",
            //   "Simulated underlyingPrices of the collateral token",
            //   "",
            //   "The safety ratio of the collateral token",
            //   "",
            //   "Balance x Safety Ratio x Price",
            // ]}
            // width={9}
            >
              {calculatorState.adjustedCollaterals.map((item, index) => (
                <Box
                  alignItems="center"
                  display="flex"
                  key={index}
                  w="full"
                  h="80px"
                  justifyContent="space-between"
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Icon iconName={item.token} h={5} w={5} />
                    <Text ml={3} whiteSpace="pre-wrap">
                      {item.token}
                    </Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    w="full"
                    justifyContent="flex-end"
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <NumberInput
                        w="170px"
                        precision={3}
                        value={item.troveBalanceString}
                        defaultValue={0}
                        onChange={(val) => {
                          handleCollateralChange(
                            {
                              ...item,
                              troveBalance: parseFloat(val),
                              troveBalanceString: val,
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      w="100px"
                      justifyContent="flex-end"
                    >
                      <Text textStyle="number_base">
                        ${getNum(item.underlyingPrices, 2)}
                      </Text>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      w="260px"
                      justifyContent="flex-end"
                    >
                      <Slider
                        w="180px"
                        focusThumbOnChange={false}
                        aria-label="slider-ex-6"
                        value={item.adjustedPrice}
                        min={item.minAdjustedPrice}
                        max={item.maxAdjustedPrice}
                        step={item.adjustmentStep}
                        onChange={(val) => {
                          handleCollateralChange(
                            {
                              ...item,
                              adjustedPrice: val,
                              adjustedPriceString: val.toString(),
                            },
                            index
                          );
                        }}
                      >
                        <SliderMark
                          value={item.minAdjustedPrice}
                          mt="1"
                          ml="-2.5"
                          fontSize="x-small"
                        >
                          {isStableCoin(item) ? "0.75X" : "0X"}
                        </SliderMark>
                        <SliderMark
                          value={item.underlyingPrices}
                          mt="1"
                          ml="-2.5"
                          fontSize="x-small"
                        >
                          1X
                        </SliderMark>
                        <SliderMark
                          value={item.maxAdjustedPrice}
                          mt="1"
                          ml="-2.5"
                          fontSize="x-small"
                        >
                          {isStableCoin(item) ? "1.25X" : "6X"}
                        </SliderMark>
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                    </Box>
                    <Box
                      alignItems="center"
                      display="flex"
                      justifyContent="flex-end"
                      w="260px"
                    >
                      <NumberInput
                        w="180px"
                        color="text-primary"
                        precision={3}
                        value={item.adjustedPriceString}
                        onChange={(val) => {
                          handleCollateralChange(
                            {
                              ...item,
                              adjustedPrice: parseFloat(val),
                              adjustedPriceString: val,
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Box>
                    {/* <Box alignItems="center" display="flex">
                    <Text ml={3} whiteSpace="nowrap" color="gray.500">
                      x
                    </Text>
                  </Box> */}
                    {/* <Box display="flex" alignItems="center">
                    <Text ml={3} whiteSpace="nowrap">
                      {getNum(format(safetyRatios[item.address]))}
                    </Text>
                  </Box>
                  <Box alignItems="center" display="flex">
                    <Text ml={3} whiteSpace="nowrap" color="gray.500">
                      =
                    </Text>
                  </Box> */}
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-end"
                      w="120px"
                    >
                      <Text textStyle="number_base" whiteSpace="nowrap">
                        ${getNum(item.weightedCollateralValue)}
                      </Text>
                    </Box>
                  </Box>
                </Box>
              ))}
            </TokenTable>
          </Box>
          <Flex>
            <Button
              colorScheme="brand"
              variant="newPrimary"
              _active={{ bg: "transparent" }}
              mt={10}
              onClick={() => {
                setCalculatorState(initialCalculatorState);
              }}
            >
              Set to Your Trove Balances
            </Button>
          </Flex>
        </Box>
      </Box>
      <Box mt="24px">
        <AddCollateralTypeModal
          isOpen={isAddCollateralTypeOpen}
          onClose={onAddCollateralTypeClose}
          show={show}
          setShow={setShow}
          availableCollateral={availableCollateral}
          borrowMode="normal"
        />
      </Box>
    </>
  );
};

export default CollateralCalculator;
