import { createIcon } from "@chakra-ui/react";

const qiETH = createIcon({
  displayName: "WETH",
  viewBox: "150 100 1800 1800",
  path: (
    <>
      <g>
        <image
          xmlns="http://www.w3.org/2000/svg"
          width="3563"
          height="3564"
          transform="translate(144.76 144.72) scale(0.48)"
          href="https://i.ibb.co/8YrtJFc/benqi-circle.png"
        />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="993.59"
          cy="999.85"
          r="642.06"
          fill="#fff"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M999.89,621.18V901.25L1236.6,1007Z"
          fill="#00b2ec"
          fillOpacity="0.6"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M999.89,621.18,763.14,1007,999.89,901.25Z"
          fill="#00b2ec"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M999.89,1188.52v190.3l236.87-327.72Z"
          fill="#00b2ec"
          fillOpacity="0.6"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M999.89,1378.82V1188.48L763.14,1051.1Z"
          fill="#00b2ec"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M999.89,1144.47,1236.6,1007,999.89,901.31Z"
          fill="#00b2ec"
          fillOpacity="0.2"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M763.14,1007l236.75,137.44V901.31Z"
          fill="#00b2ec"
          fillOpacity="0.6"
        />
      </g>
    </>
  ),
});

export default qiETH;
