import React, { useState } from "react";
import { Flex, Text, useTheme, Progress } from "@chakra-ui/react";
// import StatColumn from "../StatColumn";
import { Decimal, LiquityStoreState, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import tokenData, { tokenDataMappingA } from "../../../TokenData";
import { format, formatWithDecimals } from "../../../Utils/number";
import Tooltip from "../../../Components/Tooltip";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { calculateHealthColor } from "../../Borrow/Trove/Trove.utils";

const selector = ({
  trove,
  prices,
  tokenBalances,
  total,
  safetyRatios,
  recoveryRatios,
  YUSDPrice,
  icr,
  decimals,
  farm,
  yusdInStabilityPool,
  underlyingPerReceiptRatios,
}: LiquityStoreState) => ({
  trove,
  prices,
  tokenBalances,
  total,
  safetyRatios,
  recoveryRatios,
  YUSDPrice,
  icr,
  decimals,
  farm,
  yusdInStabilityPool,
  underlyingPerReceiptRatios,
});

const BorrowSummary: React.FC = () => {
  const {
    trove,
    prices,
    tokenBalances,
    total,
    safetyRatios,
    recoveryRatios,
    YUSDPrice,
    icr,
    decimals,
    farm,
    yusdInStabilityPool,
    underlyingPerReceiptRatios,
  } = useLiquitySelector(selector);
  const { liquity } = useLiquity();
  const { yeti } = useTheme();

  // console.log('YUSDPrice', +String(YUSDPrice))
  tokenData.map(
    (token) =>
      (token["troveBalance"] = formatWithDecimals(
        trove.collaterals[token.address],
        decimals[token.address].toNumber()
      ))
  );
  tokenData.map(
    (token) =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[
          token.underlying == "" ? token.address : token.underlying
        ],
        token.underlyingDecimals
      ))
  );

  const depositedCollateral = tokenData.filter(
    (token) => token.walletBalance !== 0 || token.troveBalance !== 0
  );

  const heldCollateral = tokenData.filter((token) => token.troveBalance !== 0);

  const collateralizationRatio = format(icr) * 100;

  const totalBorrowed = format(trove.debt["debt"]);

  const ratioMapping: TroveMappings = {};
  const [ratios, setRatios] = useState<TroveMappings>(ratioMapping);

  let vcValue = 0;
  let usdValue = 0;
  let stableVC = 0;
  let stableUSD = 0;
  tokenData.map((token) => {
    const curBal: Decimal = trove.collaterals[token.address];
    let vc: number;
    let usd: number;
    const safetyRatio = format(safetyRatios[token.address]);
    const dec = decimals[token.address].toNumber();
    if (curBal != undefined) {
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(curBal, dec);
      // console.log(token.token +'ddd', vc)
      vcValue += vc;
      usd = format(prices[token.address]) * formatWithDecimals(curBal, dec);
      usdValue += usd;
    } else {
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(trove.collaterals[token.address], dec);
      vcValue += vc;

      usd =
        format(prices[token.address]) *
        formatWithDecimals(trove.collaterals[token.address], dec);
      usdValue += usd;
    }
    if (token.isStable) {
      stableVC += vc;
      stableUSD += usd;
    }
  });
  // console.log('vcValue summary', vcValue)

  const troveHealth =
    stableVC * 1.1 > totalBorrowed && stableVC / vcValue > 0.99
      ? 200 - (200 - 110) * Math.exp((-1 / 9) * (collateralizationRatio - 110))
      : collateralizationRatio;

  const getCollateralRatioDisplay = (collateralRatio: number) => {
    if (collateralRatio < 125) {
      return ["RedThermometer", "red.500"];
    } else if (collateralRatio < 165) {
      return ["YellowThermometer", "yellow.500"];
    } else {
      return ["GreenThermometer", "green.500"];
    }
  };

  const getTroveRiskynessMsg = () => {
    const riskeyness =
      ((vcValue - stableVC) / (totalBorrowed - stableVC)) * 100;
    const precentStable = (stableVC / vcValue) * 100;
    let safteyLable: string;
    let amountRoom: string;
    if (collateralizationRatio === 0) {
      return "";
    }
    if (stableUSD > totalBorrowed) {
      if ((collateralizationRatio * precentStable) / 100 < 112) {
        safteyLable = "risky";
        amountRoom = "not much";
      } else if ((collateralizationRatio * precentStable) / 100 < 114) {
        safteyLable = "medium risk";
        amountRoom = "some";
      } else {
        safteyLable = "safe";
        amountRoom = "a lot of";
      }
    } else if (riskeyness < 130) {
      safteyLable = "risky";
      amountRoom = "not much";
    } else if (riskeyness < 170) {
      safteyLable = "low-medium risk";
      amountRoom = "some";
    } else {
      safteyLable = "safe";
      amountRoom = "a lot of";
    }
    return `Your trove is comprised of ${precentStable.toFixed(3)}% stables${
      riskeyness > 0
        ? `, with an ICR without stable coins of ${riskeyness.toFixed(3)}%.`
        : "."
    } We deem this as ${safteyLable} since there is ${amountRoom} room for your collateral prices to fall before reaching the liquidation threshold of 110%.`;
  };

  let totalSystemVC = 0;
  let totalSystemUSD = 0;
  let totalStableUSD = 0;

  Object.keys(total.collaterals).map((address) => {
    const amountUSD = format(
      total.collaterals[address]
        .mul(10 ** (18 - format(decimals[address])))
        .mul(prices[address])
        .div(1e18)
    );
    totalSystemVC += amountUSD * format(recoveryRatios[address]);
    totalSystemUSD += amountUSD;
    if (
      tokenDataMappingA[address] !== undefined &&
      tokenDataMappingA[address].isStable
    ) {
      totalStableUSD += amountUSD;
    }
  });
  // console.log("totalSystemVC", totalSystemVC);
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  return (
    // <Box layerStyle="base" flex={1} px={0}>
    <Flex direction={["column", "column"]}>
      {/* <Flex
          direction={["column", "row"]}
          pt={3}
          pb={5}
          mb={5}
          px={6}
          border="1px"
          borderColor="#313545"
        >
          <StatColumn
            iconName="Bank"
            amount={`$${getNum(totalBorrowed * +String(YUSDPrice), 2)}`}
            description="Borrowed YUSD (in USD)"
          />
          <StatColumn
            iconName="MoneyStack"
            amount={`$${getNum(usdValue, 2)}`}
            description="Total Collateral"
          />
        </Flex> */}
      <Flex direction={["column", "column"]} pt={2} pb={2} mb="24px">
        <Text textStyle="subheading" color="text-secondary">
          Collateral Ratio{" "}
          <Tooltip>Ratio of Risk Adjusted Value to YUSD Debt</Tooltip>
        </Text>
        <Flex direction={["column", "row"]} alignItems="center">
          <Progress
            h="4px"
            value={troveHealth}
            rounded="full"
            w="100%"
            colorScheme={calculateHealthColor(troveHealth)}
            mt={2.5}
            mb={2}
          />
          <Text
            fontSize="14px"
            fontWeight="800px"
            color={getCollateralRatioDisplay(troveHealth)[1]}
            ml={2}
          >{`${collateralizationRatio.toFixed(3)}%`}</Text>
        </Flex>

        {/* <Text textStyle="text-sm" color="text-secondary" fontWeight="normal">
          110% MIN
        </Text> */}
      </Flex>
    </Flex>
    // </Box>
  );
};

export default BorrowSummary;
