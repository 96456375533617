import React, { useReducer } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { tokenDataMappingT } from "../../TokenData";
import { useLiquitySelector } from "@yeti/lib-react";
import { LiquityStoreState } from "@yeti/lib-base";
import { format, getNum } from "../../Utils/number";
import { connectionReducer } from "../WalletConnector";

type CurrencyConverterProps = {
  token: string;
  value: number;
  currency: string;
  price?: number;
} & TextProps;

const selector = ({
  underlyingPrices,
  YETIPrice,
  YUSDPrice,
  safetyRatios,
}: LiquityStoreState) => ({
  underlyingPrices,
  YETIPrice,
  YUSDPrice,
  safetyRatios,
});

const dataSelector = useLiquitySelector;

const CurrencyConverterLabel: React.FC<CurrencyConverterProps> = ({
  token,
  value,
  currency,
  price = 0,
  ...props
}) => {
  const [connectionState, dispatch] = useReducer(connectionReducer, {
    type: "inactive",
  });
  let converter = 1;
  const { safetyRatios } = dataSelector(selector);
  const collateral = tokenDataMappingT[token];
  const { underlyingPrices, YETIPrice, YUSDPrice } = dataSelector(selector);
  if (token !== "veYETI") {
    if (token === "YUSD") {
      converter = format(YUSDPrice);
    } else if (token === "YETI") {
      converter = format(YETIPrice);
    } else {
      converter = format(underlyingPrices[collateral.address]);
    }
  }

  if (currency === "USD") {
    return (
      <Text
        as="span"
        fontSize="12px"
        color="text-secondary"
        whiteSpace="nowrap"
        {...props}
      >
        {price != 0 &&
          ` ${value !== 0 && value * (price as number) < 0.001 ? "<" : ""} $${
            value !== 0 && value * (price as number) < 0.001
              ? "0.001"
              : isNaN(value)
              ? "0.00"
              : getNum(value * (price as number), 2)
          }`}
        {price == 0 &&
          ` ${value !== 0 && value * (price as number) < 0.001 ? "" : ""} $${
            value !== 0 && value * converter < 0.001
              ? "0.001"
              : isNaN(value)
              ? "0.00"
              : getNum(value * converter, 2)
          }`}
      </Text>
    );
  } else if (currency === "VC") {
    return (
      <Text
        as="span"
        fontSize="12px"
        fontWeight="500"
        color="text-secondary"
        whiteSpace="nowrap"
        {...props}
      >
        {` ${getNum(value * converter, 2)}`} RAV
      </Text>
    );
  } else if (currency === "RAV") {
    return (
      <Text
        as="span"
        fontSize="12px"
        fontWeight="500"
        color="text-secondary"
        whiteSpace="nowrap"
        {...props}
      >
        {` ${getNum(
          value * converter * format(safetyRatios[collateral.address]),
          2
        )}`}{" "}
        RAV
      </Text>
    );
  } else if (currency === "YUSDEarned") {
    return (
      <Text
        as="span"
        fontSize="12px"
        fontWeight="500"
        color="text-secondary"
        whiteSpace="nowrap"
        {...props}
      >
        {` ${getNum((value * converter) / format(YUSDPrice), 2)}`} YUSD
      </Text>
    );
  } else {
    return (
      <Text
        as="span"
        fontSize="12px"
        fontWeight="500"
        color="text-secondary"
        whiteSpace="nowrap"
        {...props}
      >
        {` ${getNum(value * converter, 2)}`} YUSD
      </Text>
    );
  }
};

export default CurrencyConverterLabel;
