import tokenData from "../../../../TokenData";

const getTokenName = (tokenAddress: string) => {
  const tokenInfo = tokenData.find(
    (t) => t["address"].toLowerCase() == tokenAddress.toLowerCase()
  );
  return tokenInfo!["token"];
};

export default getTokenName;
