import { createIcon } from "@chakra-ui/react";

const AddIcon = createIcon({
  displayName: "AddIcon",
  viewBox: "0 0 10 10",
  path: (
    <g>
      <rect
        x="0.5"
        y="0.5"
        width="9"
        height="9"
        rx="4.5"
        fill="transparent"
        stroke="#63DB63"
      />
      <path d="M5 7.5V2.5M7.5 4.93056H2.5" stroke="#63DB63" />
    </g>
  ),
});

export default AddIcon;
