import React from "react";
import { Box, Text, TextProps as ChakraTextProps } from "@chakra-ui/react";
import Tooltip from "../../../Components/Tooltip";

export interface StatColumnProps extends Omit<ChakraTextProps, "css"> {
  iconName: string;
  amount: any;
  units?: string;
  description: string;
  link?: string;
  tooltip?: string;
  msg?: string;
  headerSize?: string;
  textSize?: string;
}

const StatColumn: React.FC<StatColumnProps> = ({
  iconName,
  amount,
  units,
  description,
  link,
  tooltip,
  msg,
  headerSize,
  textSize,
  ...textProps
}) => (
  <Box display="flex" flexDir="column">
    {/* <Center h={20} w={20}>
      {msg
          ? <ChakraTooltip label={msg} placement="top">
          
        <Icon iconName={iconName} h={20} w={20} />
      </ChakraTooltip>
      : <Icon iconName={iconName} h={20} w={20} />
      }
    </Center> */}
    <Text textStyle="text_md">
      {amount} {units}
    </Text>
    <Box>
      {!link ? (
        <Text textStyle="subheading" color="text-secondary">
          {description} {tooltip && <Tooltip>{tooltip}</Tooltip>}
        </Text>
      ) : (
        <Text textStyle="subheading" color="text-secondary">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ outline: "none", textDecoration: "none" }}
          >
            {description}
          </a>
        </Text>
      )}
    </Box>
  </Box>
);

export default StatColumn;
