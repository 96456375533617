import { ApolloClient, InMemoryCache, gql } from "@apollo/client/core";

export const getTroveHistory = async (userID: string) => {
  const historyQuery = `
      query {
        updatedTroves(first: 1000,  where: {borrower: "${userID}"}, orderBy: timestamp, orderDirection: desc) {
          timestamp
          operation
          currentICR
          tokens
          amounts
          realAmounts
          values
          debt
          collsIn
          amountsIn
          realAmountsIn
          valuesIn
          collsOut
          amountsOut
          realAmountsOut
          valuesOut
          YUSDchange
          totalValue
          valueChange
          isDebtIncrease
          blockNum
          transaction
        }
      }
      `;

  const client = new ApolloClient({
    uri: "https://api.thegraph.com/subgraphs/name/0xcano/yeti",
    cache: new InMemoryCache(),
  });

  const result = await client
    .query({
      query: gql(historyQuery),
    })
    .then((data: any) => data)
    .catch((err: any) => {
      console.log("Error fetching history data: ", err);
      return [];
    });

  // console.log(result);

  const troveDayMap = new Map<string, any>();

  if (result.length == 0) {
    console.log("no result");
    return [];
  }

  result.data.updatedTroves.map((trove: any) => {
    const date = new Date(trove!["timestamp"] * 1000).toDateString();
    if (!troveDayMap.has(date)) {
      troveDayMap.set(date, [trove]);
    } else {
      troveDayMap.get(date).push(trove);
    }
  });

  const array: any[] = [];

  troveDayMap.forEach((value: any, key: string) => {
    array.push([key, value]);
  });

  return array;
};

export default getTroveHistory;
