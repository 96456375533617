import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { BatchedWebSocketAugmentedWeb3Provider } from "@yeti/providers";
import { Center, Text, Button } from "@chakra-ui/react";
import Snowfall from "react-snowfall";
import { RootWrapper } from "./Components";
import { Loading } from "./Screens";
import { LiquityProvider } from "./Hooks/LiquityContext";
import { TransactionProvider } from "./Components/Transaction";
import YetiFrontend from "./YetiFrontend";
import { ProtocolDataProvider } from "./Hooks/useProtocolDataContext";
import { Web3ContextProvider } from "./Hooks/web3-data-provider/Web3Provider";

declare let window: any;

const switchNetwork = () => {
  if (!window.ethereum) return;
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0xA86A" }], // mainnet
    // params: [{ chainId: `0xA869` }]  // testnet
  });
};

const EthersWeb3ReactProvider: React.FC = ({ children }) => (
  <Web3ReactProvider
    getLibrary={(provider) =>
      new BatchedWebSocketAugmentedWeb3Provider(provider)
    }
  >
    {children}
  </Web3ReactProvider>
);

const UnsupportedMainnetFallback: React.FC = () => (
  <Center w="100vw" h="100vh" flexDirection="column" p={10}>
    <Snowfall snowflakeCount={100} style={{ zIndex: 1 }} />
    {/* <img src="/img/3d.png" alt="Yeti Finance" style={{ width: "6rem" }} /> */}
    <Text textStyle="title4" ml={3} my={5} textAlign="center">
      Please switch your chain to the Avalanche C-Chain.
    </Text>
    <Button variant="newPrimary" mb={10} onClick={switchNetwork}>
      Switch Network
    </Button>
  </Center>
);

function App() {
  const unsupportedNetworkFallback = (chainId: number) => (
    <Center w="100vw" h="100vh" flexDirection="column" p={10}>
      <Snowfall snowflakeCount={100} style={{ zIndex: 1 }} />
      {/* <img src="/img/yeti2d.png" alt="Yeti Finance" style={{ width: "6rem" }} /> */}
      <Text textStyle="title4" ml={3} my={5} textAlign="center">
        Please switch your chain to the Avalanche C-Chain.
      </Text>
      <Button variant="newPrimary" mb={10} onClick={switchNetwork}>
        Switch Network
      </Button>
    </Center>
  );

  return (
    <EthersWeb3ReactProvider>
      <RootWrapper>
        <Web3ContextProvider>
          <LiquityProvider
            loader={<Loading />}
            unsupportedNetworkFallback={unsupportedNetworkFallback}
            unsupportedMainnetFallback={<UnsupportedMainnetFallback />}
          >
            <TransactionProvider>
              <ProtocolDataProvider>
                <YetiFrontend />
              </ProtocolDataProvider>
            </TransactionProvider>
          </LiquityProvider>
        </Web3ContextProvider>
      </RootWrapper>
    </EthersWeb3ReactProvider>
  );
}

export default App;
