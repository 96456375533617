import React, { useState, useEffect } from "react";

import {
  Flex,
  Text,
  Box,
  Checkbox,
  Hide,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import Icon from "../../../Components/Icon";
import { Collateral, CoinShow } from "../../../Types";
import { getNum, format } from "../../../Utils/number";
import { Form } from "react-final-form";
import TokenTable from "../../../Components/TokenTable";
import { tokenDataMappingT } from "../../../TokenData";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { useWeb3Context } from "../../../Hooks/useWeb3Context";
import { WalletSelector } from "../../../Components/WalletConnector";

export type AddCollateralTypeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  availableCollateral: Collateral[];
  show?: any;
  setShow?: any;
  borrowMode: string;
};
export interface CollateralAPYs {
  [key: string]: any;
}

const selector = ({ prices, total, safetyRatios }: LiquityStoreState) => ({
  prices,
  total,
  safetyRatios,
});

const AddCollateralTypeModal: React.FC<AddCollateralTypeModalProps> = ({
  isOpen,
  onClose,
  availableCollateral,
  show,
  setShow,
  borrowMode,
}) => {
  const { prices, total, safetyRatios } = useLiquitySelector(selector);
  const { connected } = useWeb3Context();
  const [toggle, setToggle] = useState(false);

  const {
    isOpen: isSelectorOpen,
    onOpen: onSelectorOpen,
    onClose: onSelectorClose,
  } = useDisclosure();

  const [showZeroBalance, setShowZeroBalance] = useState(true);
  console.log(showZeroBalance);

  let filteredCollater: Collateral[] = availableCollateral.filter((coin) =>
    borrowMode != "unlever" ? true : coin.troveBalance != 0
  );

  filteredCollater = filteredCollater.filter((coin) =>
    showZeroBalance ? true : coin.walletBalance !== 0
  );
  const onSubmit = (values: CoinShow) => {
    setShow({ ...show, ...values });
  };

  const [APYs, setAPYs] = useState<CollateralAPYs>({} as CollateralAPYs);
  useEffect(() => {
    const fetchData = async () => {
      const tempAPYs: CollateralAPYs = {};
      for (let i = 0; i < Object.keys(filteredCollater).length; i++) {
        const token = filteredCollater[i].token;
        let url = `https://api.yeti.finance/v1/Collaterals/${token}/APY`;
        if (token === "WETH-WAVAX JLP") {
          url = "https://api.yeti.finance/v1/Collaterals/WETHWAVAXJLP/APY";
        } else if (token === "AVAX-USDC JLP") {
          url = "https://api.yeti.finance/v1/Collaterals/AVAXUSDCJLP/APY";
        }
        try {
          fetch(url, { method: "GET", mode: "cors" })
            .then(function (response) {
              if (response.ok) {
                return response.json();
              }
              const err = new Error("No live API for " + token);
              throw err;
            })
            .then(function (result) {
              if (result !== undefined) {
                tempAPYs[token] = result;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } catch (error) {
          console.log("error", error);
        }
      }
      setAPYs(tempAPYs);
    };
    fetchData();
  }, [show]);

  return (
    <>
      {isSelectorOpen && (
        <WalletSelector
          isSelectorOpen={isSelectorOpen}
          onSelectClose={onSelectorClose}
        />
      )}
      <Box
        // w="full"
        // rounded="8px"
        // // borderWidth="1px"
        // borderColor="border"
        // display="flex"
        // flexDir="column"
        // p="24px"
        // bg="surface"
        // shadow="shadow_sm"
        layerStyle="card"
        p="24px"
      >
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Box>
            <Text textStyle="card_header">Assets to add</Text>
            <Text textStyle="card_text" color="text-secondary" mb={4}>
              Select assets to add as collateral
            </Text>
          </Box>
          <Flex mb={4} mt={3}>
            <Checkbox
              onChange={(e) => {
                setShowZeroBalance(e.target.checked);
              }}
              size="md"
              style={{ outline: "none" }}
              defaultChecked={true}
            />
            <Text textStyle="text_sm" color="text-secondary" ml={3}>
              Show assets with 0 balance
            </Text>
          </Flex>
        </Box>

        <Box
          display="flex"
          pb="16px"
          textStyle="text_sm"
          color="text-secondary"
          pt="16px"
          w="full"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box display="flex">
              <Text>Asset</Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box w="150px" display="flex" justifyContent="flex-end">
              <Text>Safety Ratio</Text>
            </Box>
            <Box w="180px" display="flex" justifyContent="flex-end">
              <Text>Total Deposited</Text>
            </Box>
            <Box w="140px" display="flex" justifyContent="flex-end">
              <Text>Wallet</Text>
            </Box>
            <Box w="130px" display="flex" justifyContent="flex-end">
              <Text>Deposit APY</Text>
            </Box>
            {/* <Box display="flex" w="60px">
              <Text>Action</Text>
            </Box> */}
          </Box>
          {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
        </Box>

        <Form
          onSubmit={onSubmit}
          initialValues={[]}
          render={({ values }) => (
            // <Box
            //   overflowY={filteredCollater.length > 5 ? "scroll" : undefined}
            //   maxHeight={550}
            //   sx={yeti.scrollbarDashboard}
            // >
            <TokenTable
              // // headers={[
              // //   "Asset",
              // //   "Safety Ratio",
              // //   "System Deposits",
              // //   "Wallet Balance",
              // //   "APY",
              // //   "Action",
              // // ]}
              // // tooltips={[
              // //   "Name",
              // //   "Weighting for risk adjusted value",
              // //   "Total sum of deposits in the system.",
              // //   "Amount of collateral in your wallet.",
              // //   "Estimated Annual Percentage Yield, including auto-compounding fees. Currently updated daily for AAVE tokens. (live update coming in the next 2 days)",
              // //   "Add a collateral to your trove.",
              // // ]}
              // // width={6}
              borrow
            >
              <>
                {filteredCollater.map(
                  ({ token, walletBalance, address, apr, name }) => (
                    <>
                      <Tooltip
                        bg="contrast"
                        p="8px"
                        color="background"
                        label="Add to supply"
                        openDelay={1000}
                        placement="top"
                        rounded="8px"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          h="80px"
                          key={token}
                          borderTop="1px"
                          // rounded="8px"
                          borderColor="border"
                          color="text-primary"
                          textStyle="number_base"
                          _hover={{ bg: "surface-hover" }}
                          transition="background 0.1s ease-out"
                          onClick={() => {
                            if (!connected) {
                              onSelectorOpen();
                            } else {
                              values[token] = true;
                              onSubmit(values);
                            }
                          }}
                          cursor="pointer"
                        >
                          {/* Asset */}
                          <Box alignItems="center" display="flex">
                            <Icon iconName={token} h="36px" w="36px" />
                            <Box
                              display="flex"
                              flexDir="column"
                              alignItems="flex-start"
                              ml="8px"
                            >
                              <Text textStyle="assetName">{name}</Text>
                              <Text textStyle="asset">{token}</Text>
                            </Box>
                          </Box>

                          <Box display="flex" alignItems="center">
                            {/* Safety Ratio */}
                            <Hide below="lg">
                              <Box w="150px">
                                <Text whiteSpace="nowrap">
                                  {getNum(
                                    format(
                                      safetyRatios[
                                        tokenDataMappingT[token].address
                                      ]
                                    )
                                  )}
                                </Text>
                              </Box>
                            </Hide>

                            {/* Total Supplied */}
                            <Hide below="md">
                              <Box w="150px">
                                <Text whiteSpace="nowrap">
                                  $
                                  {getNum(
                                    format(
                                      total.collaterals[address].mul(
                                        prices[address]
                                      )
                                    ),
                                    2
                                  )}
                                </Text>
                              </Box>
                            </Hide>

                            {/* Wallet Balance */}
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              w="80px"
                              mr="32px"
                            >
                              <Text whiteSpace="nowrap">
                                {getNum(walletBalance)}
                              </Text>
                            </Box>

                            {/* Supply APY */}
                            <Hide below="md">
                              <Box
                                w="100px"
                                display="flex"
                                justifyContent="flex-end"
                              >
                                <Text whiteSpace="nowrap">
                                  {(APYs[token] === undefined ||
                                    APYs[token] === null) &&
                                  apr !== undefined
                                    ? apr.toFixed(2) + "%"
                                    : APYs[token] !== 0 && !isNaN(APYs[token])
                                    ? (APYs[token] * 100).toFixed(2) + "%"
                                    : "--"}
                                </Text>
                              </Box>
                            </Hide>

                            {/* Add Button */}
                            {/* <Box>
                            <Button
                              fontSize="15px"
                              fontWeight="medium"
                              variant="contrast"
                              size="md"
                              onClick={() => {
                                values[token] = true;
                                onSubmit(values);
                              }}
                            >
                              Add
                            </Button>
                          </Box> */}
                          </Box>
                        </Box>
                      </Tooltip>
                    </>
                  )
                )}
              </>
            </TokenTable>
            // </Box>
          )}
        />
      </Box>
      {/* <Modal isOpen={isOpen} onClose={onClose} size="3xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <Form
          onSubmit={onSubmit}
          initialValues={[]}
          render={({ values }) => (
            <>
              <ModalHeader fontSize="2xl" pb={2}>
                Add Collateral Type
              </ModalHeader>

              <ModalBody>
                <Flex
                  textStyle="body2"
                  color="brand.600"
                  fontWeight="bold"
                  textTransform="uppercase"
                  px={4}
                  mb={3}
                >
                  <Text flex={3}>Token</Text>
                  <Text flex={2}>System Deposits</Text>
                  <Text flex={2}>Safety Ratio</Text>
                  <Text flex={2}>Wallet Balance</Text>
                </Flex>
                {filteredCollater.map(({ token, walletBalance, address }) => (
                  <Button
                    variant="tokenSelect"
                    bg={values[token] === true ? "brand.1000" : "brand.900"}
                    borderColor={values[token] === true ? "brand.700" : "transparent"}
                    my={2}
                    onClick={() => {
                      values[token] = values[token] === true ? false : true;
                      // console.log(values[token] === true);
                      setToggle(!toggle);
                    }}
                    key={token + format(safetyRatios[tokenDataMappingT[token].address])}
                  >
                    <Flex align="center" flex={3}>
                      <Icon iconName={token} h={6} w={6} />
                      <Text ml={3}>{token}</Text>
                    </Flex>
                    <Text flex={2} textAlign="left">
                      ${getNum(format(total.collaterals[address].mul(prices[address])), 2)}
                    </Text>
                    <Text flex={2} textAlign="left">
                      {getNum(format(safetyRatios[tokenDataMappingT[token].address]))} <Tooltip>{"Effective Minimum Collateral Ratio: " + ((1.1 / format(safetyRatios[tokenDataMappingT[token].address])) * 100).toFixed(2) + "%"}</Tooltip>

                    </Text>
                    <Text flex={2} textAlign="left">
                      {getNum(walletBalance)}
                    </Text>
                  </Button>
                ))}
              </ModalBody>

              <ModalFooter justifyContent={"flex-start"}>
                <Button variant="newPrimary" mr={6} onClick={() => onSubmit(values)}>
                  Add
                </Button>

                <Button variant="secondary" onClick={onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </>
          )}
        />
      </ModalContent>
    </Modal> */}
    </>
  );
};

export default AddCollateralTypeModal;
