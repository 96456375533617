import React, { useState, useEffect } from "react";

import ProgressBar from "../../../Components/ProgressBar";
import CoinAmount from "../../../Components/CoinAmount";
import {
  useTransactionFunction,
  useMyTransactionState,
} from "../../../Components/Transaction";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { Decimal } from "@yeti/lib-base";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format } from "../../../Utils/number";

import { useLiquity } from "../../../Hooks/LiquityContext";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  Flex,
  Text,
  Button,
  Spacer,
  HStack,
  useDisclosure,
} from "@chakra-ui/react";

const BOOSTED_FARM_ADDRESS = "0xD8A4AA01D54C8Fdd104EAC28B9C975f0663E75D8";
const OLD_FARM_ADDRESS = "0xfffFffFFfFe8aA117FE603a37188E666aF110F39";

export type ConfirmStakeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: "deposit" | "withdraw";
  amount: string;
  total: number;
  values: Record<string, any>;
  name: string;
  isOldFarm?: boolean;
};
const selector = ({
  yetiBalance,
  lpTokenBalance,
  boostedFarm,
  farm,
}: LiquityStoreState) => ({
  yetiBalance,
  lpTokenBalance,
  boostedFarm,
  farm,
});

const ConfirmStakeModal: React.FC<ConfirmStakeModalProps> = ({
  isOpen,
  onClose,
  mode,
  amount,
  total,
  values,
  name,
  isOldFarm,
}) => {
  const { yetiBalance, lpTokenBalance, boostedFarm, farm } =
    useLiquitySelector(selector);
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const action = capitalizeFirstLetter(mode) == "Deposit" ? "Stake" : "Unstake";

  const { liquity, account } = useLiquity();
  let { userAddress } = liquity.connection;
  // console.log("111", userAddress);
  if (userAddress === undefined) {
    userAddress = "";
  }
  const {
    isOpen: isTxModalOpen,
    onOpen: onTxModalOpen,
    onClose: onTxModalClosed,
  } = useDisclosure();

  const getFormattedValue = (value: string): number => {
    try {
      Decimal.from(value);
      return +value;
    } catch (err) {
      return 0;
    }
  };
  useEffect(() => {
    // let tot:Decimal = Decimal.ZERO
    // if (!(getFormattedValue(amount) == 0)) {
    //   tot = Decimal.from(amount)
    // }
    const open = isOpen;
    let interval: any = undefined;
    if (open) {
      interval = setInterval(async () => {
        const allowance = await checkAllowance(
          "0x1da20ac34187b2d9c74f729b85acb225d3341b25",
          Decimal.from(getFormattedValue(amount))
        );
        if (allowance) {
          setStep(2);
        } else {
          setStep(1);
        }
      }, 1500);
    }

    return () => clearInterval(interval);
  }, [amount, isOpen]);

  let newAmount: Decimal;

  const formatedAmount = getFormattedValue(amount);

  if (mode === "deposit" && formatedAmount === format(yetiBalance)) {
    newAmount = yetiBalance;
  } else {
    if (
      mode === "deposit" &&
      Decimal.from(formatedAmount)
        .add(Decimal.from(".00009"))
        .gte(lpTokenBalance)
    ) {
      newAmount = lpTokenBalance;
    } else if (
      mode === "withdraw" &&
      Decimal.from(formatedAmount)
        .add(Decimal.from(".00009"))
        .gte(isOldFarm ? farm.lpTokenBalance : boostedFarm.lpTokenBalance)
    ) {
      newAmount = isOldFarm ? farm.lpTokenBalance : boostedFarm.lpTokenBalance;
    } else if (!isNaN(formatedAmount)) {
      newAmount = Decimal.from(formatedAmount);
    } else {
      newAmount = Decimal.from(0);
    }
  }

  // console.log("newAmount", newAmount.toString())
  const [sendTransaction] = useTransactionFunction(
    "stakeLPTokens",
    mode === "deposit"
      ? liquity.send.stakeLPTokens.bind(liquity.send, newAmount)
      : liquity.send.withdrawLPTokens.bind(liquity.send, newAmount)
  );

  const [sentTransactionOldFarm] = useTransactionFunction(
    "stakeLPTokensOldFarm",
    mode === "deposit"
      ? liquity.send.stakeLPTokensOldFarm.bind(liquity.send, newAmount)
      : liquity.send.withdrawLPTokensOldFarm.bind(liquity.send, newAmount)
  );

  const transactionId = "stakeLPTokens";
  const myTransactionState = useMyTransactionState(transactionId);

  const checkAllowance = async (
    token: string,
    amount: Decimal
  ): Promise<boolean> => {
    const result = await liquity.getAllowanceOf(
      account,
      token,
      isOldFarm ? OLD_FARM_ADDRESS : BOOSTED_FARM_ADDRESS,
      amount
    );

    return result;
  };

  /*
  Fixme:
  amount should be a number like depositYUSDInStabilityPool
  **/
  const [approveTransaction] = useTransactionFunction(
    "approve",
    liquity.send.approveToken.bind(
      liquity.send,
      "0x1da20ac34187b2d9c74f729b85acb225d3341b25",
      isOldFarm ? OLD_FARM_ADDRESS : BOOSTED_FARM_ADDRESS,
      Decimal.from("10000000000000000000")
    )
  );

  const onApprove = () => {
    approveTransaction();
  };

  const onDeposit = () => {
    if (step === 2 || mode == "withdraw") {
      // console.log(mode);
      if (isOldFarm) {
        console.log(isOldFarm);
        sentTransactionOldFarm();
      } else {
        sendTransaction();
      }
      onClose();
      delete values[name];
    }
  };

  let confirmStakeButtons = (
    <ModalFooter flexDirection="column">
      <HStack spacing={6}>
        <Button
          disabled={step === 2 && true}
          variant={step !== 1 ? "quaternary" : "newPrimary"}
          onClick={onApprove}
        >
          Approve
        </Button>

        <Button
          variant={step !== 2 ? "quaternary" : "newPrimary"}
          onClick={onDeposit}
        >
          {action}
        </Button>
      </HStack>
      <ProgressBar step={step === 2 ? 1 : 0} w="30%" mt={2} />
    </ModalFooter>
  );

  if (action == "Unstake") {
    confirmStakeButtons = (
      <ModalFooter flexDirection="column">
        <HStack spacing={6}>
          <Button variant={"newPrimary"} onClick={onDeposit}>
            {action}
          </Button>
        </HStack>
      </ModalFooter>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent bg="surface" color="text-primary">
          <ModalHeader color="text-primary" textAlign="center" pb={1}>
            Confirm {action}
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody color="text-primary">
            <Flex>
              <Text fontSize="lg">{action} Amount:</Text>
              <Spacer />
              <CoinAmount
                amount={formatedAmount}
                token="Curve LP Tokens"
                fontWeight="bold"
                color="text-primary"
                fontSize="md"
                noCurrencyConvert={true}
              />
            </Flex>
            <Flex mt={3}>
              <Text color="text-primary">New Total Staked Curve LP Tokens</Text>
              <Spacer />
              <CoinAmount
                amount={total}
                token="Curve LP Tokens"
                fontWeight="bold"
                noCurrencyConvert={true}
              />
            </Flex>
          </ModalBody>
          {confirmStakeButtons}
        </ModalContent>
      </Modal>
      {/* <TransactionModal
        status={myTransactionState.type}
        isOpen={isTxModalOpen}
        onClose={onTxModalClosed}
      /> */}
    </>
  );
};

export default ConfirmStakeModal;
