import { createIcon } from "@chakra-ui/react";

const qiDAI = createIcon({
  displayName: "qiDAI",
  viewBox: "150 100 1800 1800",
  path: (
    <>
      <g>
        <image
          xmlns="http://www.w3.org/2000/svg"
          width="3563"
          height="3564"
          transform="translate(144.76 144.72) scale(0.48)"
          href="https://i.ibb.co/8YrtJFc/benqi-circle.png"
        />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="1000"
          cy="1000"
          r="642.06"
          fill="#fff"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M1008.75,1039.65h212.88c4.54,0,6.68,0,7-6a406.84,406.84,0,0,0,0-65.12c0-4.21-2.09-6-6.66-6H798.31c-5.25,0-6.66,1.74-6.66,6.65v62.33c0,8,0,8,8.4,8Zm196.12-149.86a6.87,6.87,0,0,0,0-4.89,139.26,139.26,0,0,0-12.66-22.06,178.32,178.32,0,0,0-25.92-32.57,85.57,85.57,0,0,0-16.11-15.76,251.72,251.72,0,0,0-105-53.22A260.81,260.81,0,0,0,985.6,755h-188c-5.25,0-6,2.09-6,6.65v124.3c0,5.24,0,6.65,6.65,6.65h404.06s3.51-.7,4.21-2.8h-1.68Zm0,222.69a82.4,82.4,0,0,0-17.9,0H798.66c-5.24,0-7,0-7,7V1241c0,5.6,0,7,7,7H977.94a89.41,89.41,0,0,0,25.54-1.74,266.72,266.72,0,0,0,76-16.81,139.82,139.82,0,0,0,25.54-11.9h2.44a227.51,227.51,0,0,0,97.7-98.35s2.44-5.3-.3-6.69ZM721.29,1311V1117.4c0-4.56,0-5.24-5.6-5.24h-76c-4.21,0-6,0-6-5.6V1040H715c4.54,0,6.31,0,6.31-6V968.25c0-4.21,0-5.24-5.6-5.24h-76c-4.21,0-6,0-6-5.6V895.79c0-3.85,0-4.89,5.6-4.89h75.28c5.25,0,6.66,0,6.66-6.65V695.51c0-5.59,0-7,7-7H990.9a398.19,398.19,0,0,1,56.72,6.3,342.17,342.17,0,0,1,109.6,40.61,309,309,0,0,1,61.61,47.63,337.25,337.25,0,0,1,37.47,46.56,281.18,281.18,0,0,1,27.35,53.58,9.12,9.12,0,0,0,10.46,7.36h62.68c8,0,8,0,8.39,7.71V955.7c0,5.59-2.09,7-7.71,7h-48.33c-4.89,0-6.3,0-6,6.31a356.94,356.94,0,0,1,0,64.06c0,6,0,6.65,6.68,6.65h55.29c2.44,3.16,0,6.31,0,9.48a71.36,71.36,0,0,1,0,12.23v42.38c0,6-1.74,7.72-7,7.72H1292a8.75,8.75,0,0,0-10.16,6.65,280.08,280.08,0,0,1-73.54,107.15,421.61,421.61,0,0,1-37.46,30.1c-14,8.07-27.66,16.47-42,23.12a379.13,379.13,0,0,1-82.64,26.25,430.83,430.83,0,0,1-81.94,6.65h-243v-.35Z"
          fill="#00b2ec"
        />
      </g>
    </>
  ),
});

export default qiDAI;
