import { extendTheme } from "@chakra-ui/react";

import textStyles from "./textStyles";
import layerStyles from "./layerStyles";
// import colors from "./colors";
import components from "./Components";
import radii from "./radii";
import yeti from "./yeti";
import sizes from "./sizes";
import shadows from "./shadows";

const width = window.innerWidth;

const height = window.innerHeight;

const tokens = {
  colors: {
    light: {
      "primary-default": "#5F81F9",
      "primary-hover": "#718FFA",
      "primary-focused": "#6F74FA",
      "primary-subdued": "#B1B4F9",
      "primary-disabled": "#E8E8E8",
      background: "#F4F7FB",
      surface: "#FFFFFF",
      "surface-hover": "#F7F8FB",
      "surface-highlight": "#EBEEF1",
      "sh-hover": "#E1E7ED",
      border: "#F0F0F0",
      "text-primary": "#111111",
      "text-secondary": "#757C88",
      "text-tertiary": "#A6ADB8",
      contrast: "#0D0E0F",
      "contrast-hover": "#16191C",
      "contrast-border": "#33394D",
      "contrast-text": "#FBFCFC",
    },
    dark: {
      "primary-default": "#5F81F9",
      "primary-hover": "#718FFA",
      "primary-focused": "#6F74FA",
      "primary-subdued": "#B1B4F9",
      "primary-disabled": "#E8E8E8",
      background: "#141724",
      surface: "#272937",
      "surface-hover": "#343647",
      "surface-highlight": "#434658",
      "sh-hover": "#4F5166",
      border: "#33374D",
      "text-primary": "#DFE3E9",
      "text-secondary": "#8A93A6",
      "text-tertiary": "#5C5D69",
      contrast: "#FFFFFF",
      "contrast-hover": "#FAFAFF",
      "contrast-border": "#F0F0F0",
      "contrast-text": "#33394D",
    },
  },
};

const semanticTokens = {
  colors: {
    "primary-default": {
      default: tokens.colors.light["primary-default"],
      _dark: tokens.colors.dark["primary-default"],
    },
    "primary-hover": {
      default: tokens.colors.light["primary-hover"],
      _dark: tokens.colors.dark["primary-hover"],
    },
    "primary-focused": {
      default: tokens.colors.light["primary-focused"],
      _dark: tokens.colors.dark["primary-focused"],
    },
    background: {
      default: tokens.colors.light["background"],
      _dark: tokens.colors.dark["background"],
    },
    surface: {
      default: tokens.colors.light["surface"],
      _dark: tokens.colors.dark["surface"],
    },
    "surface-hover": {
      default: tokens.colors.light["surface-hover"],
      _dark: tokens.colors.dark["surface-hover"],
    },
    "surface-highlight": {
      default: tokens.colors.light["surface-highlight"],
      _dark: tokens.colors.dark["surface-highlight"],
    },
    "sh-hover": {
      default: tokens.colors.light["sh-hover"],
      _dark: tokens.colors.dark["sh-hover"],
    },
    border: {
      default: tokens.colors.light["border"],
      _dark: tokens.colors.dark["border"],
    },
    "text-primary": {
      default: tokens.colors.light["text-primary"],
      _dark: tokens.colors.dark["text-primary"],
    },
    "text-secondary": {
      default: tokens.colors.light["text-secondary"],
      _dark: tokens.colors.dark["text-secondary"],
    },
    "text-tertiary": {
      default: tokens.colors.light["text-tertiary"],
      _dark: tokens.colors.dark["text-tertiary"],
    },
    contrast: {
      default: tokens.colors.light["contrast"],
      _dark: tokens.colors.dark["contrast"],
    },
    "contrast-hover": {
      default: tokens.colors.light["contrast-hover"],
      _dark: tokens.colors.dark["contrast-hover"],
    },
    "contrast-border": {
      default: tokens.colors.light["contrast-border"],
      _dark: tokens.colors.dark["contrast-border"],
    },
    "contrast-text": {
      default: tokens.colors.light["contrast-text"],
      _dark: tokens.colors.dark["contrast-text"],
    },
  },
};

const styles = {
  global: {
    body: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
      background: "background",
      // fontFamily: "Inter; sans-serif",
      // overflow: "auto",
      // overflow: "clip",
      // height: "100%",
      // height: height,
      // width: width,
      // overflow: "hidden",
      // fontFamily: "Nunito",
      // fontFamily: "Roboto",
      // fontFamily: "Urbanist",
      fontFamily: "Inter",
      // fontFamily: "Mulish",
    },
  },
};

const theme = extendTheme({
  shadows,
  styles,
  tokens,
  semanticTokens,
  // colors,
  textStyles,
  layerStyles,
  components,
  radii,
  sizes,
  yeti,
});

export default theme;
