import { createIcon } from "@chakra-ui/react";

const qiUSDTn = createIcon({
  displayName: "qiUSDTn",
  viewBox: "150 100 1800 1800",
  path: (
    <>
      <g>
        <image
          xmlns="http://www.w3.org/2000/svg"
          width="3563"
          height="3564"
          transform="translate(144.76 144.72) scale(0.48)"
          href="https://i.ibb.co/8YrtJFc/benqi-circle.png"
        />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="1029.44"
          cy="977.99"
          r="642.06"
          fill="#fff"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M793.8,681l-135,283.66a5.5,5.5,0,0,0,1.17,6.42l364.73,349.49a5.55,5.55,0,0,0,7.7,0l364.72-349.47a5.49,5.49,0,0,0,1.18-6.41l-135-283.66a5.45,5.45,0,0,0-5-3.19H798.82a5.45,5.45,0,0,0-5,3.16Z"
          fill="#00b2ec"
          fillRule="evenodd"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M1079.12,993.32h0c-2.83.21-17.44,1.08-50,1.08-25.93,0-44.33-.77-50.79-1.08h0c-100.18-4.41-174.95-21.85-174.95-42.73s74.77-38.29,174.95-42.77V976c6.55.47,25.31,1.58,51.23,1.58,31.11,0,46.69-1.3,49.49-1.55V907.87c100,4.45,174.58,21.89,174.58,42.72S1179,988.87,1079,993.3h0Zm0-92.5v-61h139.51v-93H838.79v93H978.28V900.8C864.9,906,779.64,928.47,779.64,955.38s85.26,49.35,198.64,54.58v195.36h100.81V1009.89c113.12-5.21,198.24-27.65,198.24-54.54s-85-49.32-198.24-54.55h0Zm0,0"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </>
  ),
});

export default qiUSDTn;
