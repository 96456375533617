import React, { useState, useEffect } from "react";

import ProgressBar from "../../../Components/ProgressBar";
import CoinAmount from "../../../Components/CoinAmount";
import {
  useTransactionFunction,
  useMyTransactionState,
} from "../../../Components/Transaction";
import { Decimal, TroveMappings } from "@yeti/lib-base";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format } from "../../../Utils/number";

import { useLiquity } from "../../../Hooks/LiquityContext";

import { useProtocolDataContext } from "../../../Hooks/useProtocolDataContext";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
} from "@chakra-ui/modal";
import {
  Flex,
  Text,
  Button,
  HStack,
  useDisclosure,
  CircularProgress,
  Tr,
  Td,
  Box,
} from "@chakra-ui/react";
import TransactionModal from "../../../Components/TransactionModal";
import { TroveData } from "../../../Types";
import { Icon, TokenTable } from "../../../Components";
import { tokenDataMappingA } from "../../../TokenData";

export type ConfirmRedeemModalProps = {
  isOpen: boolean;
  onClose: () => void;
  amount: string;
  values: Record<string, any>;
  redeemRate: Decimal;
  updated: boolean;
  setUpdated: React.Dispatch<React.SetStateAction<boolean>>;
};
const selector = ({ yusdBalance, underlyingPrices }: LiquityStoreState) => ({
  yusdBalance,
  underlyingPrices,
});

const ConfirmRedeemModal: React.FC<ConfirmRedeemModalProps> = ({
  isOpen,
  onClose,
  amount,
  values,
  redeemRate,
  updated,
  setUpdated,
}) => {
  const { sortedTrovesData } = useProtocolDataContext();

  const firstTenTroves = sortedTrovesData.slice(0, 11);

  const { liquity, account } = useLiquity();

  const {
    isOpen: isTxModalOpen,
    onOpen: onTxModalOpen,
    onClose: onTxModalClosed,
  } = useDisclosure();

  const { yusdBalance, underlyingPrices } = useLiquitySelector(selector);

  // const [ collateralsToReceive, setCollateralsToReceive ] = useState<TroveMappings>({} as TroveMappings)

  const getFormattedValue = (value: string): number => {
    try {
      Decimal.from(value);
      return +value;
    } catch (err) {
      return 0;
    }
  };

  let newAmount;

  const formatedAmount = getFormattedValue(amount);
  if (
    formatedAmount === format(yusdBalance) ||
    Decimal.from(formatedAmount).add(Decimal.from(".000009")).gte(yusdBalance)
  ) {
    newAmount = yusdBalance;
  } else {
    newAmount = Decimal.from(formatedAmount);
  }

  const transactionId = "redeem";
  const myTransactionState = useMyTransactionState(transactionId);

  const checkAllowance = async (
    token: string,
    amount: Decimal
  ): Promise<boolean> => {
    const result = await liquity.getAllowanceOf(
      account,
      token,
      "0x00000000000d9c2f60d8e82F2d1C2bed5008DD7d",
      amount
    );

    return result;
  };

  const [hasAllowance, setHasAllowance] = useState<boolean>(false);

  useEffect(() => {
    // let tot:Decimal = Decimal.ZERO
    // if (!(getFormattedValue(amount) == 0)) {
    //   tot = Decimal.from(amount)
    // }
    const open = isOpen;
    let interval: any = undefined;
    if (open) {
      interval = setInterval(async () => {
        const allowance = await checkAllowance(
          "0x111111111111ed1D73f860F57b2798b683f2d325",
          Decimal.from(getFormattedValue(amount))
        );
        if (allowance) {
          setHasAllowance(true);
        } else {
          setHasAllowance(false);
        }
      }, 1500);
    }

    return () => clearInterval(interval);
  }, [amount, isOpen]);

  const [redeem] = useTransactionFunction(
    transactionId,
    liquity.send.redeemYUSD.bind(
      liquity.send,
      newAmount,
      redeemRate.add(Decimal.from(0.0005))
    )
  );

  const [approveTransaction] = useTransactionFunction(
    "approve",
    liquity.send.approveToken.bind(
      liquity.send,
      "0x111111111111ed1D73f860F57b2798b683f2d325",
      "0x00000000000d9c2f60d8e82F2d1C2bed5008DD7d",
      Decimal.from("10000000000000000000")
    )
  );

  const onApprove = () => {
    approveTransaction();
  };

  const [collateralsToReceive, setCollateralsToReceive] =
    useState<TroveMappings>({} as TroveMappings);

  useEffect(() => {
    const getSetEstimation = async () => {
      // this amount decreases as when the redeem transaction closes a trove
      let amountToRedeem: number = +amount;

      // troves that is sorted by AICR
      const trovesToRedeem: TroveData[] = [...firstTenTroves];

      const tempMapping: TroveMappings = {};

      while (amountToRedeem > 0 && trovesToRedeem.length > 0) {
        console.log(firstTenTroves);
        const troveToRedeem = trovesToRedeem.shift();

        const trove = await liquity.getTrove(troveToRedeem?.owner);

        const troveColls = trove.collaterals;

        let troveCollsUSDValue = 0;

        // check if this trove will be fully redeemed
        Object.keys(troveColls).forEach((address) => {
          troveCollsUSDValue += format(
            troveColls[address].mul(underlyingPrices[address])
          );
        });

        if (troveCollsUSDValue < amountToRedeem) {
          Object.keys(troveColls).forEach((address) => {
            if (troveColls[address].gt(Decimal.from(0))) {
              tempMapping[address] =
                tempMapping[address] === undefined
                  ? troveColls[address]
                  : tempMapping[address].add(troveColls[address]);
            }
          });
        } else {
          const portionOfTrove = amountToRedeem / troveCollsUSDValue;
          Object.keys(troveColls).forEach((address) => {
            if (troveColls[address].gt(Decimal.from(0))) {
              const toAdd = troveColls[address].mul(
                Decimal.from(portionOfTrove)
              );
              tempMapping[address] =
                tempMapping[address] === undefined
                  ? toAdd
                  : tempMapping[address].add(toAdd);
            }
          });
          // console.log(collateralsToReceive)
        }
        amountToRedeem -= troveCollsUSDValue;
      }
      setUpdated(true);
      setCollateralsToReceive(tempMapping);
    };

    getSetEstimation();
  }, []);

  const onDeposit = () => {
    // console.log(mode);
    redeem();
    onClose();
    delete values["yusdRedeemInput"];
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent bg="surface" color="text-primary">
          <ModalHeader textAlign="center" pb={1}>
            Confirm Redeem
            <Box onClick={onClose}>
              <ModalCloseButton />
            </Box>
          </ModalHeader>

          <ModalBody>
            <Flex justifyContent="space-between" mt={5}>
              <Text fontSize="lg">Redeem Amount:</Text>
              <CoinAmount
                amount={formatedAmount}
                token="YUSD"
                fontWeight="bold"
                color="text-primary"
                fontSize="md"
              />
            </Flex>

            <Flex justifyContent="space-between" mt={5}>
              <Text fontSize="lg">Max Redemption Fee:</Text>
              <CoinAmount
                amount={format(
                  redeemRate.add(Decimal.from(0.0005)).mul(newAmount)
                )}
                token="YUSD"
                fontWeight="bold"
                color="text-primary"
                fontSize="md"
              />
            </Flex>

            <Text textStyle="body1" fontSize="lg" pt={5}>
              Estimated Collaterals to Receive:
            </Text>
            {updated ? (
              <TokenTable
              // headers={["token", "amount"]}
              // width={5}
              // hideTopBorder="hidden"
              >
                <>
                  {Object.keys(collateralsToReceive).map((address) => {
                    return (
                      <Tr key={address}>
                        <Td py={3} borderColor="border" fontSize={14}>
                          <Flex align="center">
                            <Icon
                              iconName={tokenDataMappingA[address].token}
                              h={6}
                              w={6}
                            />
                            <Text ml={3}>
                              {tokenDataMappingA[address].token}
                            </Text>
                          </Flex>
                        </Td>
                        {[...new Array(3)].map((_) => (
                          <Td
                            key={_}
                            py={3}
                            borderColor="border"
                            fontSize={14}
                          />
                        ))}
                        <Td py={3} borderColor="border" fontSize={14}>
                          <CoinAmount
                            token={tokenDataMappingA[address].token}
                            amount={format(collateralsToReceive[address])}
                            color="text-primary"
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </>
              </TokenTable>
            ) : (
              <Flex flexDirection="column" alignItems="center" gap={4}>
                <CircularProgress isIndeterminate />
                <Text textStyle="title4">Calculating</Text>
              </Flex>
            )}
          </ModalBody>
          <ModalFooter flexDirection="column">
            <HStack spacing={6}>
              <Button
                variant={hasAllowance ? "surface" : "newPrimary"}
                onClick={onApprove}
              >
                Approve
              </Button>

              <Button
                variant={!hasAllowance ? "surface" : "newPrimary"}
                disabled={!hasAllowance}
                onClick={onDeposit}
              >
                Redeem
              </Button>
            </HStack>
            <ProgressBar step={hasAllowance ? 1 : 0} w="30%" mt={2} />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <TransactionModal
        status={myTransactionState.type}
        isOpen={isTxModalOpen}
        onClose={onTxModalClosed}
      />
    </>
  );
};

export default ConfirmRedeemModal;
