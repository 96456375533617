import { createIcon } from "@chakra-ui/react";

const VTX = createIcon({
  displayName: "VTCIcon",
  viewBox: "0 0 600 600",
  path: (
    <g>
      <circle cx="250" cy="250" r="250" fill="#6869E7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100 145.4 250 404l150-258.6H100Zm256.2 0L250 329.346l-85.2-146.918h83.1l-20.4 34.938 22.2 37.625L313.3 145.4h42.9Z"
        fill="#fff"
      />
    </g>
  ),
});

export default VTX;
