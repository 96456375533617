const Input = {
  baseStyle: ({ theme }: any) => ({
    border: theme.yeti.borders.input,
    borderRadius: "input",
    color: "#949494",
    bg: "transparent",
  }),
  sizes: {
    xs: {
      height: "inputlike.xs",
    },
    sm: {
      height: "inputlike.sm",
    },
    md: {
      height: "inputlike.md",
    },
    lg: {
      height: "inputlike.lg",
    },
  },
};

export default Input;
