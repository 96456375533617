import React, { useReducer } from "react";
import { AbstractConnector } from "@web3-react/abstract-connector";

import {
  injectedConnector,
  walletConnectConnector,
  coinbaseConnector,
} from "../connectors/injectedConnector";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import Icon from "./Icon";
import { useWeb3Context } from "../Hooks/useWeb3Context";
import { ArrowForwardIcon } from "@chakra-ui/icons";

interface MaybeHasMetaMask {
  ethereum?: {
    isMetaMask?: boolean;
  };
}

type ConnectionState =
  | { type: "inactive" }
  | {
      type:
        | "activating"
        | "active"
        | "rejectedByUser"
        | "alreadyPending"
        | "failed";
      connector: AbstractConnector;
    };

type ConnectionAction =
  | { type: "startActivating"; connector: AbstractConnector }
  | { type: "fail"; error: Error }
  | { type: "finishActivating" | "retry" | "cancel" | "deactivate" };

export const connectionReducer: React.Reducer<
  ConnectionState,
  ConnectionAction
> = (state, action) => {
  switch (action.type) {
    case "startActivating":
      return {
        type: "activating",
        connector: action.connector,
      };
    case "finishActivating":
      return {
        type: "active",
        connector:
          state.type === "inactive" ? injectedConnector : state.connector,
      };
    case "fail":
      if (state.type !== "inactive") {
        return {
          type: action.error.message.match(/user rejected/i)
            ? "rejectedByUser"
            : action.error.message.match(/already pending/i)
            ? "alreadyPending"
            : "failed",
          connector: state.connector,
        };
      }
      break;
    case "retry":
      if (state.type !== "inactive") {
        return {
          type: "activating",
          connector: state.connector,
        };
      }
      break;
    case "cancel":
      return {
        type: "inactive",
      };
    case "deactivate":
      return {
        type: "inactive",
      };
  }

  console.warn("Ignoring connectionReducer action:");

  return state;
};

const detectMetaMask = () =>
  (window as MaybeHasMetaMask).ethereum?.isMetaMask ?? false;

type WalletConnectorProps = {
  loader?: React.ReactNode;
};

// const WalletConnector: React.FC<WalletConnectorProps> = ({
//   children,
//   loader,
// }) => {
//   const { activate, deactivate, active, error, connector } =
//     useWeb3React<unknown>();
//   const triedAuthorizedConnection = useAuthorizedConnection();
//   const [connectionState, dispatch] = useReducer(connectionReducer, {
//     type: "inactive",
//   });
//   const isMetaMask = detectMetaMask();

//   useEffect(() => {
//     if (error) {
//       dispatch({ type: "fail", error });
//       deactivate();
//     }
//   }, [error, deactivate]);

//   useEffect(() => {
//     if (active) {
//       dispatch({ type: "finishActivating" });
//     } else {
//       dispatch({ type: "deactivate" });
//     }
//   }, [active]);

//   if (!triedAuthorizedConnection) {
//     return <>{loader}</>;
//   }

//   if (connectionState.type === "active") {
//     return <>{children}</>;
//   }

//   return (
//     <></>
//   );
// };

export const WalletSelector: React.FC<{
  isSelectorOpen: boolean;
  onSelectClose: () => void;
}> = ({ isSelectorOpen, onSelectClose, ...props }) => {
  const { connectWallet } = useWeb3Context();
  const [connectionState, dispatch] = useReducer(connectionReducer, {
    type: "inactive",
  });

  const isMetaMask = detectMetaMask();

  return (
    <Modal isOpen={isSelectorOpen} onClose={onSelectClose} size="sm" isCentered>
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalContent bg="surface" shadow="shadow_lg">
        <ModalHeader textAlign="center" color="text-primary">
          <Box display="flex" alignItems="center" flexDir="column" mt="32px">
            <img
              src="/img/3d-yeti.png"
              alt="Yeti Finance"
              style={{ width: "48px" }}
            />
            <Text
              fontSize="24px"
              ml="8px"
              fontWeight="600"
              color="text-primary"
              letterSpacing="tight"
              mt="16px"
            >
              Connect a wallet
            </Text>
          </Box>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Box
            bg="surface"
            display="flex"
            flexDir="column"
            p="24px"
            rounded="8px"
            shadow="shadow_sm"
            color="text-primary"
            alignItems="center"
          >
            <Button
              justifyContent="space-between"
              px="24px"
              rightIcon={<ArrowForwardIcon h="24px" w="24px" />}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: injectedConnector,
                });
                connectWallet(injectedConnector);
              }}
              variant="surface"
              width="full"
              height="60px"
              rounded="14px"
              {...props}
            >
              {isMetaMask ? (
                <>
                  <Box display="flex" alignItems="center">
                    <Icon iconName="MetaMaskIcon" h={5} w={5} />
                    <Text ml="12px">MetaMask</Text>
                  </Box>
                </>
              ) : (
                <>
                  {/* <Icon name="plug" size="lg" /> */}
                  <Text ml="12px">Connect wallet</Text>
                </>
              )}
            </Button>
            <Button
              justifyContent="space-between"
              px="24px"
              rightIcon={<ArrowForwardIcon h="24px" w="24px" />}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: walletConnectConnector,
                });
                connectWallet(walletConnectConnector);
              }}
              variant="surface"
              height="60px"
              rounded="14px"
              {...props}
              width="full"
              mt="16px"
            >
              <>
                <Box display="flex" alignItems="center">
                  <Icon iconName="WalletConnect" h={5} w={5} />
                  <Text ml="16px">WalletConnect</Text>
                </Box>
              </>
            </Button>

            <Button
              justifyContent="space-between"
              px="24px"
              rightIcon={<ArrowForwardIcon h="24px" w="24px" />}
              onClick={() => {
                dispatch({
                  type: "startActivating",
                  connector: coinbaseConnector,
                });
                connectWallet(coinbaseConnector);
              }}
              variant="surface"
              {...props}
              width="full"
              height="60px"
              rounded="14px"
              mt="16px"
            >
              <>
                <Box display="flex" alignItems="center">
                  <Icon iconName="Coinbase" h={5} w={5} rounded="full" />
                  <Text ml={2}>Coinbase Wallet</Text>
                </Box>
              </>
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
