const layerStyles = {
  base: {
    bg: "#191D2A",
    borderRadius: "2xl",
    borderColor: "gray.500",
    py: 6,
    px: 8,
    pt: 5,
  },
  // card: {
  //   bg: "brand.800",
  //   borderRadius: "2xl",
  //   borderColor: "gray.500",
  //   px: [4, 6, 8],
  //   pt: [3, 4, 5],
  //   pb: [4, 5, 6],
  // },
  newAdjustCard: {
    bg: "brand.800",
    borderRadius: "2xl",
    borderColor: "gray.500",
    pt: [3, 4, 5],
    pb: [4, 5, 6],
  },
  newCard: {
    bg: "#242938",
    px: [4, 6, 8],
    pt: [3, 4, 5],
    pb: [4, 5, 6],
  },
  card: {
    bg: "surface",
    // p: ["16px", "24px", "24px", "32px"],
    borderRadius: "12px",
    borderColor: "border",
    // borderWidth: "1px",
    shadow: "shadow_lg",
    overflow: "hidden",
  },
  tableHead: {
    p: ["16px", "24px", "24px", "32px"],
    display: "flex",
    textStyle: "card_header",
    // borderBottom: "1px",
    borderColor: "border",
    color: "text-primary",
    w: "full",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableSub: {
    px: ["16px", "24px", "24px", "32px"],
    display: "flex",
    py: "16px",
    // px: "16px",
    // bg: "surface",
    textStyle: "subheading",
    color: "text-secondary",
    w: "full",
    // borderTop: "1px",
    // borderBottom: "1px",
    borderTopColor: "border",
    borderColor: "border",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tableRow: {
    px: ["16px", "24px", "24px", "32px"],
    borderTop: "1px",
    // px: "16px",
    // _hover: {
    //   borderLeftWidth: "1px",
    //   borderLeftColor: "text-primary",
    // },
    display: "flex",
    h: "90px",
    alignItems: "center",
    flexDir: ["column", "row"],
    justifyContent: "space-between",
    borderTopColor: "border",
    color: "text-primary",
    textStyle: "number_base",
  },
  pill_dark: {
    bg: "surface",
    border: "1px",
    borderColor: "border",
    py: "6px",
    px: "12px",
    rounded: "full",
    fontSize: "14px",
    color: "text-primary",
  },
};

export default layerStyles;
