import React, { createContext, useContext, useMemo } from "react";
import { Provider } from "@ethersproject/abstract-provider";
// import { Web3Provider } from "@ethersproject/providers";
// import { useWeb3React } from "@web3-react/core";

import { useWeb3Context } from "./useWeb3Context";

import {
  BlockPolledLiquityStore,
  EthersLiquity,
  EthersLiquityWithStore,
  _connectByChainId,
} from "@yeti/lib-ethers";

type LiquityContextValue = {
  account: string;
  provider: Provider;
  liquity: EthersLiquityWithStore<BlockPolledLiquityStore>;
};

const LiquityContext = createContext<LiquityContextValue | undefined>(
  undefined
);

type LiquityProviderProps = {
  loader?: React.ReactNode;
  unsupportedNetworkFallback?: (chainId: number) => React.ReactNode;
  unsupportedMainnetFallback?: React.ReactNode;
};

const supportedNetworks = ["fuji", "avalanche"];

export const LiquityProvider: React.FC<LiquityProviderProps> = ({
  children,
  loader,
  unsupportedNetworkFallback,
  unsupportedMainnetFallback,
}) => {
  const { provider, connected, account, chainId, signer } = useWeb3Context();

  console.log(provider);

  const connection = useMemo(() => {
    if (provider && account && chainId) {
      try {
        return _connectByChainId(provider, signer, chainId, {
          userAddress: account,
          useStore: "blockPolled",
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [provider, account, chainId]);

  // if (config.testnetOnly && chainId === 1) {
  //   return <>{unsupportedMainnetFallback}</>;
  // }

  if (!connection) {
    return unsupportedNetworkFallback ? (
      <>{unsupportedNetworkFallback(chainId)}</>
    ) : null;
  }

  const liquity = EthersLiquity._from(connection);
  liquity.store.logging = true;
  console.log("connection", connection);
  return (
    <LiquityContext.Provider value={{ account, provider, liquity }}>
      {children}
    </LiquityContext.Provider>
  );
};

export const useLiquity = () => {
  const liquityContext = useContext(LiquityContext);

  if (!liquityContext) {
    throw new Error("You must provide a LiquityContext via LiquityProvider");
  }

  return liquityContext;
};
