import {
  Button,
  Text,
  ButtonProps as ChakraButtonProps,
  useDisclosure,
} from "@chakra-ui/react";
// import { useAuthorizedConnection } from "../Hooks/useAuthorizedConnection";

import { WalletSelector } from "./WalletConnector";

export const ConnectButton: React.FC<ChakraButtonProps> = (props) => {
  // const triedAuthorizedConnection = useAuthorizedConnection();

  const {
    isOpen: isSelectorOpen,
    onOpen: onSelectorOpen,
    onClose: onSelectorClose,
  } = useDisclosure();
  return (
    <>
      {isSelectorOpen && (
        <WalletSelector
          isSelectorOpen={isSelectorOpen}
          onSelectClose={onSelectorClose}
        />
      )}
      <Button
        onClick={() => {
          onSelectorOpen();
        }}
        variant="newPrimary"
        width="full"
        {...props}
      >
        <Text>Connect Wallet</Text>
      </Button>
    </>
  );
};
