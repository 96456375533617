import React from "react";
import {
  Box,
  Flex,
  Text,
  TextProps as ChakraTextProps,
} from "@chakra-ui/react";
import Icon from "../Icon";
import CurrencyConverter from "../CurrencyConverter";
import { getNum } from "../../Utils/number";

export interface CoinAmountProps extends Omit<ChakraTextProps, "css"> {
  icon?: boolean;
  token: string;
  currency?: string;
  safetyRatio?: number;
  amount: number;
  usd?: number;
  textStyle?: string;
  noGetNum?: boolean;
  noCurrencyConvert?: boolean;
  price?: number;
}

const CoinAmount: React.FC<CoinAmountProps> = ({
  icon = false,
  token,
  currency,
  safetyRatio,
  amount,
  usd,
  textStyle = "body2",
  noGetNum = false,
  noCurrencyConvert = false,
  price,
  ...textProps
}) => {
  if (!safetyRatio) {
    safetyRatio = 1;
  }
  if (noGetNum) {
    return (
      <Flex>
        <Text
          textStyle={textStyle}
          color="text-primary"
          textAlign={["right", "left"]}
          {...textProps}
        >
          {!noCurrencyConvert && (
            <CurrencyConverter
              token={token}
              value={amount}
              currency={"USD"}
              price={price}
            />
          )}
        </Text>
      </Flex>
    );
  }
  if (currency) {
    return (
      <Box display="flex" flexDir="column" alignItems="flex-end">
        {icon && <Icon iconName={token} h={5} w={5} mr={3} />}
        <Text
          textStyle={textStyle}
          color="text-primary"
          textAlign={["right", "left"]}
          {...textProps}
        >
          {/* {getNum(Number(amount), 3) + " " + token} */}
          {getNum(Number(amount), 3)}
        </Text>
        {/* <CurrencyConverter
            token={token}
            value={amount * safetyRatio}
            currency={currency}
          /> */}
      </Box>
    );
  } else {
    return (
      <Flex>
        {icon && <Icon iconName={token} h={5} w={5} mr={3} />}

        <Text
          textStyle={textStyle}
          color="text-primary"
          textAlign={["right", "left"]}
          {...textProps}
        >
          {/* {(amount !== 0 && amount < 0.001 ? "< 0.001" : getNum(amount, 3)) +
            " " +
            token} */}
          {amount !== 0 && amount < 0.001 ? "< 0.001" : getNum(amount, 3)}
          {!noCurrencyConvert && (
            <CurrencyConverter
              token={token}
              value={amount}
              currency={"USD"}
              price={price}
            />
          )}
        </Text>
      </Flex>
    );
  }
};
export default CoinAmount;
