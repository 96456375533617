import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

export type LinkProps = {
  label: string;
  to: string;
  hasRadius?: boolean;
  isMobile?: boolean;
};

const CustomLink: React.FC<LinkProps> = ({
  label,
  to,
  hasRadius = true,
  isMobile = true,
}) => {
  const location = useLocation();

  let current = false;
  if (location.pathname === to) {
    current = true;
  }

  return (
    <>
      {isMobile ? (
        <Link to={to}>
          <Box
            mx="2px"
            px="10px"
            py="4px"
            rounded="full"
            // bg={current ? "primary-default" : "#272937"}
            // _hover={{ bg: "#343647" }}
            transitionDuration="200ms"
          >
            <Text
              fontSize="15px"
              fontWeight="700"
              // bg={current ? "primary-default" : "surface"}
              color={current ? "primary-default" : "#EAEDF0"}
              _hover={{ color: "primary-default" }}
              transitionDuration="200ms"
              // borderBottomWidth={current ? "1px" : "0px"}
            >
              <Text>{label}</Text>
            </Text>
          </Box>
        </Link>
      ) : (
        <Box px={[3, null, 4]}>
          <Link to={to}>
            <Text
              fontSize="24px"
              color={current ? "#EAEDF0" : "#8A93A6"}
              _hover={{ color: "#EAEDF0" }}
              transitionDuration="200ms"
              fontWeight="600"
            >
              {label}
            </Text>
          </Link>
        </Box>
      )}
    </>
  );
};

export default CustomLink;
