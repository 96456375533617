import { createIcon } from "@chakra-ui/react";

const Bank = createIcon({
  displayName: "BankIcon",
  viewBox: "0 0 70 74",
  path: (
    <>
      <path
        d="M21.3043 57.5217V62.3152H6.08694V57.5217C6.08694 57.0979 6.24727 56.6915 6.53265 56.3919C6.81803 56.0922 7.20509 55.9239 7.60868 55.9239H19.7826C20.1862 55.9239 20.5732 56.0922 20.8586 56.3919C21.144 56.6915 21.3043 57.0979 21.3043 57.5217Z"
        fill="#768BA0"
      />
      <path
        d="M63.9131 62.3152H48.6957V57.5217C48.6957 57.0979 48.856 56.6915 49.1414 56.3919C49.4268 56.0922 49.8138 55.9239 50.2174 55.9239H62.3913C62.7949 55.9239 63.182 56.0922 63.4674 56.3919C63.7527 56.6915 63.9131 57.0979 63.9131 57.5217V62.3152Z"
        fill="#768BA0"
      />
      <path
        d="M63.9131 30.3587V33.5543C63.9131 33.9781 63.7527 34.3845 63.4674 34.6842C63.182 34.9838 62.7949 35.1522 62.3913 35.1522H50.2174C49.8138 35.1522 49.4268 34.9838 49.1414 34.6842C48.856 34.3845 48.6957 33.9781 48.6957 33.5543V30.3587C48.6957 29.9349 48.856 29.5285 49.1414 29.2289C49.4268 28.9292 49.8138 28.7609 50.2174 28.7609H62.3913C62.7949 28.7609 63.182 28.9292 63.4674 29.2289C63.7527 29.5285 63.9131 29.9349 63.9131 30.3587Z"
        fill="#768BA0"
      />
      <path
        d="M21.3043 30.3587V33.5543C21.3043 33.9781 21.144 34.3845 20.8586 34.6842C20.5732 34.9838 20.1862 35.1522 19.7826 35.1522H7.60868C7.20509 35.1522 6.81803 34.9838 6.53265 34.6842C6.24727 34.3845 6.08694 33.9781 6.08694 33.5543V30.3587C6.08694 29.9349 6.24727 29.5285 6.53265 29.2289C6.81803 28.9292 7.20509 28.7609 7.60868 28.7609H19.7826C20.1862 28.7609 20.5732 28.9292 20.8586 29.2289C21.144 29.5285 21.3043 29.9349 21.3043 30.3587Z"
        fill="#768BA0"
      />
      <path
        d="M35 46.337H42.6087V62.3152H48.6957H63.9131V67.1087H3.04352V62.3152H6.087H21.3044H27.3913V46.337H35Z"
        fill="#D0D7DF"
      />
      <path
        d="M19.7826 28.7609H50.2174C49.8138 28.7609 49.4268 28.9292 49.1414 29.2289C48.856 29.5285 48.6957 29.9349 48.6957 30.3587V33.5543C48.6957 33.9781 48.856 34.3845 49.1414 34.6842C49.4268 34.9838 49.8138 35.1522 50.2174 35.1522H51.7392V55.9239H50.2174C49.8138 55.9239 49.4268 56.0922 49.1414 56.3919C48.856 56.6915 48.6957 57.098 48.6957 57.5217V62.3152H42.6087V46.337H27.3913V62.3152H21.3044V57.5217C21.3044 57.098 21.144 56.6915 20.8587 56.3919C20.5733 56.0922 20.1862 55.9239 19.7826 55.9239H18.2609V35.1522H19.7826C20.1862 35.1522 20.5733 34.9838 20.8587 34.6842C21.144 34.3845 21.3044 33.9781 21.3044 33.5543V30.3587C21.3044 29.9349 21.144 29.5285 20.8587 29.2289C20.5733 28.9292 20.1862 28.7609 19.7826 28.7609Z"
        fill="#D0D7DF"
      />
      <path
        d="M60.8696 35.1522H51.7392V55.9239H60.8696V35.1522Z"
        fill="#A4B0C0"
      />
      <path
        d="M18.2609 35.1522H9.13046V55.9239H18.2609V35.1522Z"
        fill="#A4B0C0"
      />
      <path
        d="M65.4348 15.9783V22.3696H57.8261H4.56519V15.9783H7.60866V12.7826L35 0L62.3913 12.7826V15.9783H65.4348Z"
        fill="#A4B0C0"
      />
      <path d="M70 67.1087V73.5H0V67.1087H3.04348H63.913H70Z" fill="#A4B0C0" />
      <path
        d="M70 22.3696V28.7609H62.3913H50.2174H19.7826H7.6087H0V22.3696H4.56522H65.4348H70Z"
        fill="#D0D7DF"
      />
      <path
        d="M35 14.3804C36.6809 14.3804 38.0435 12.9497 38.0435 11.1848C38.0435 9.41988 36.6809 7.98914 35 7.98914C33.3191 7.98914 31.9565 9.41988 31.9565 11.1848C31.9565 12.9497 33.3191 14.3804 35 14.3804Z"
        fill="#F1F2F2"
      />
      <path
        d="M35.0001 46.337H27.3914V62.3152H35.0001V46.337Z"
        fill="#F1F2F2"
      />
      <path d="M42.6087 46.337H35V62.3152H42.6087V46.337Z" fill="#F1F2F2" />
      <path
        d="M48.6957 30.3587C48.6957 29.9349 48.856 29.5285 49.1414 29.2289C49.4268 28.9292 49.8138 28.7609 50.2174 28.7609H19.7827C20.1862 28.7609 20.5733 28.9292 20.8587 29.2289C21.1441 29.5285 21.3044 29.9349 21.3044 30.3587V31.9565H48.6957V30.3587Z"
        fill="#BBC1C9"
      />
      <path
        d="M27.3913 59.1196H21.3043V62.3152H27.3913V59.1196Z"
        fill="#BBC1C9"
      />
      <path
        d="M48.6957 59.1196H42.6087V62.3152H48.6957V59.1196Z"
        fill="#BBC1C9"
      />
      <path
        d="M42.6087 39.9457H27.3913C26.9877 39.9457 26.6007 39.7773 26.3153 39.4777C26.0299 39.178 25.8696 38.7716 25.8696 38.3478C25.8696 37.9241 26.0299 37.5176 26.3153 37.218C26.6007 36.9183 26.9877 36.75 27.3913 36.75H42.6087C43.0123 36.75 43.3994 36.9183 43.6848 37.218C43.9701 37.5176 44.1305 37.9241 44.1305 38.3478C44.1305 38.7716 43.9701 39.178 43.6848 39.4777C43.3994 39.7773 43.0123 39.9457 42.6087 39.9457Z"
        fill="#A4B0C0"
      />
      <path
        d="M65.4348 15.9783H4.56519V22.3696H65.4348V15.9783Z"
        fill="#919CAB"
      />
    </>
  ),
});

export default Bank;
