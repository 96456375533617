import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Td,
  Text,
  useDisclosure,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { AdjustInput, TokenTable, Icon } from "../../../Components";
import { Decimal, LiquityStoreState, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { adjustValue, getNum, format } from "../../../Utils/number";
import { validateDeposit } from "../../../Utils/validation";
import { Form } from "react-final-form";
import { useLiquity } from "../../../Hooks/LiquityContext";
import PoolRewardsModal from "../../Pool/PoolRewardsModal";

export type FarmCardProps = {
  disconnected?: boolean;
};

const selector = ({ farm, lpTokenBalance, YETIPrice }: LiquityStoreState) => ({
  farm,
  lpTokenBalance,
  YETIPrice,
});

const dataSelector = useLiquitySelector;

const FarmCard: React.FC<FarmCardProps> = ({ disconnected = false }) => {
  let lpStaked: number,
    yetiAPR: number,
    yetiPrice: number,
    totalLPStaked: number,
    rewardRate: number;
  const { farm } = dataSelector(selector);
  const { lpTokenBalance, YETIPrice } = dataSelector(selector);

  const { liquity } = useLiquity();
  if (!disconnected) {
    yetiPrice = format(YETIPrice);
    lpStaked = format(farm.lpTokenBalance);
    totalLPStaked = format(farm.totalLPStaked);
    rewardRate = 0;
  } else {
    totalLPStaked = 0;
    lpStaked = 0;
  }
  const earned: TroveMappings = {
    "0x77777777777d4554c39223C354A05825b2E8Faa3": farm.earnedYETI,
  };

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");
  const [showInput, setShowInput] = useState<boolean>(false);

  const validate = (valueChange: number) => {
    validateDeposit(
      toast,
      mode,
      format(lpTokenBalance),
      lpStaked,
      valueChange,
      onConfirmOpen
    );
  };

  const [value, setValue] = useState<Record<string, any>>({});
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (!isConfirmOpen) {
      setShowError(false);
    } else if (mode === "deposit") {
      setShowError(true);
    }
  }, [isConfirmOpen]);

  const toastProps: UseToastOptions = {
    status: "error",
    duration: 4000,
    isClosable: true,
    position: "top-right",
  };
  const toast = useToast();
  const smallPercent = "< 0.01";

  const onSubmit = (values: any): void => {
    if (mode !== "deposit") {
      validate(values.stakeInput);
    } else {
      toast({
        title: "Error",
        description:
          "Emissions on this LP Farm have been turned off. Please stake in new Curve LP Farm for YETI reward emissions.",
        ...toastProps,
      });
    }
  };
  const {
    isOpen: isPoolRewardsOpen,
    onOpen: onPoolRewardsOpen,
    onClose: onPoolRewardsClose,
  } = useDisclosure();
  const poolShare: number = +String(
    Decimal.from(100).mul(farm.lpTokenBalance.div(farm.totalLPStaked))
  );

  return (
    <>
      <Form
        onSubmit={() => undefined}
        // format={getFormattedValue}
        render={({ values, form }) => (
          <>
            {!disconnected && (
              <PoolRewardsModal
                isOpen={isPoolRewardsOpen}
                onClose={onPoolRewardsClose}
                rewards={earned}
                notStability={true}
                mode="LP"
                key="prm"
                isOldFarm={true}
              />
            )}
            {setValue(values)}
            <Box
              layerStyle="card"
              w="full"
              // shadow="shadow_sm"
            >
              <Text layerStyle="tableHead">Old Curve Farm</Text>
              <TokenTable
                // headers={[
                //   "Asset",
                //   "Total Staked",
                //   "Rewards APR",
                //   "Your Total Stake",
                //   "Pool Share",
                //   "Est. Weekly YETI Rewards",
                //   "YETI Earned",
                //   "Actions",
                // ]}
                // width={8}
                display={["none", "block"]}
              >
                <>
                  <Box key="oldCurve" display="flex" alignItems="center">
                    <Td py={2} fontSize={14}>
                      <Flex align="center">
                        <Icon iconName={"CLP"} h={6} w={6} />
                        <Text ml={2}>CRV</Text>
                      </Flex>
                    </Td>
                    <Td py={2} fontSize={14}>
                      ${getNum(format(farm.totalLPStaked), 2)}
                    </Td>
                    <Td py={2}>
                      <Text color="#34CE85" textStyle="subtitle1" fontSize={14}>
                        0.00%
                      </Text>
                    </Td>
                    <Td py={2} fontSize={14}>
                      {getNum(
                        adjustValue(mode, lpStaked, values.stakeInput),
                        3
                      )}
                    </Td>
                    <Td py={2} fontSize={14}>
                      {poolShare < 0.01 && poolShare != 0
                        ? smallPercent
                        : poolShare.toFixed(3)}
                      %
                    </Td>
                    <Td py={2} fontSize={14}>
                      0.000
                    </Td>
                    <Td py={2} fontSize={14}>
                      {!isNaN(+Object.values(earned)[0])
                        ? (+Object.values(earned)[0]).toFixed(3)
                        : 0.0}
                    </Td>
                    <Td py={2}>
                      <Flex direction="row" alignItems="center">
                        {showInput && (
                          <>
                            <Text
                              fontSize={14}
                              color={
                                mode === "withdraw" ? "#DC444D" : "#34CE85"
                              }
                              mr={2}
                            >
                              {mode === "withdraw" ? "Unstake" : "Stake"}
                            </Text>
                            <AdjustInput
                              max={
                                mode === "deposit"
                                  ? format(lpTokenBalance)
                                  : format(lpStaked)
                              }
                              name="stakeInput"
                              token="CLP"
                              form={form}
                              walletBalance={
                                mode === "deposit"
                                  ? format(lpTokenBalance)
                                  : format(lpStaked)
                              }
                              noCurrencyConvert={true}
                              setFarm={setShowInput}
                            />
                            <Button fontSize={14} variant="newPrimary" ml={4}>
                              Confirm
                            </Button>
                          </>
                        )}
                        {!showInput && (
                          <>
                            <Button
                              fontSize={14}
                              variant="text"
                              disabled={true}
                              ml={4}
                              _hover={{ bg: "#313646", textColor: "gray" }}
                            >
                              Stake
                            </Button>
                            <Button
                              fontSize={14}
                              variant="text"
                              ml={4}
                              onClick={() => {
                                setMode("withdraw");
                                setShowInput(true);
                              }}
                            >
                              Unstake
                            </Button>
                            <Button
                              fontSize={14}
                              variant="text"
                              ml={4}
                              onClick={onPoolRewardsOpen}
                            >
                              View Rewards
                            </Button>
                          </>
                        )}
                      </Flex>
                    </Td>
                  </Box>
                </>
              </TokenTable>
            </Box>
          </>
        )}
      />
    </>
  );
};

export default FarmCard;
