import { TroveHistoryData } from "../TroveHistory";
// import getCurrentICR from "./getCurrentICR";
// import getRedemptionData from "./getRedemptionData";
// import getTotalValueChange from "./getTotalValueChange";

export const getCalculatedTroveHistoryDay = async (
  web3: any,
  userID: string,
  data: any,
  safetyRatios: any
) => {
  // console.log('safetyRatios', safetyRatios)

  // Change this to calculateAmounts = amounts * their underlyingPerReceipt

  const calculatedTroveHistoryData: TroveHistoryData = {
    amounts: data.amounts,
    realAmounts: data.realAmounts,
    values: data.values,
    amountsIn: data.amountsIn,
    realAmountsIn: data.realAmountsIn,
    valuesIn: data.valuesIn,
    amountsOut: data.amountsOut,
    realAmountsOut: data.realAmountsOut,
    valuesOut: data.valuesOut,
    vaultData: null,
    YUSDchange: data.YUSDchange,
    blockNum: data.blockNum,
    currentICR: data.currentICR,
    totalValue: data.totalValue,
    totalValueChange: data.valueChange,
    debt: data.debt,
    tokens: data.tokens,
    collsIn: data.collsIn,
    collsOut: data.collsOut,
    operation: data.operation,
    transaction: data.transaction,
    isDebtIncrease: data.isDebtIncrease,
    timestamp: data.timestamp,
  };
  // if (calculatedTroveHistoryData.operation == "redeemCollateral") {
  //   if (calculatedTroveHistoryData.debt != 0) {
  //     calculatedTroveHistoryData.currentICR = await getCurrentICR(
  //       web3,
  //       userID,
  //       calculatedTroveHistoryData.blockNum
  //     );
  //   }
  //   const redemptionData = await getRedemptionData(
  //     calculatedTroveHistoryData.transaction
  //   );
  //   const redeemedTokens = [];
  //   const redeemedAmounts = [];
  //   for (let i = 0; i < redemptionData.tokens.length; i++) {
  //     if (redemptionData.amounts[i] > 0) {
  //       redeemedTokens.push(redemptionData.tokens[i]);
  //       redeemedAmounts.push(redemptionData.amounts[i]);
  //     }
  //   }
  //   calculatedTroveHistoryData.collsOut = redeemedTokens;
  //   calculatedTroveHistoryData.amountsOut = redeemedAmounts;
  //   calculatedTroveHistoryData.YUSDchange = Number(
  //     redemptionData.attemptedYUSDAmount
  //   );
  //   calculatedTroveHistoryData.totalValueChange = await getTotalValueChange(
  //     [],
  //     [],
  //     redemptionData.tokens,
  //     redemptionData.amounts,
  //     vaultData,
  //     safetyRatios
  //   );
  //   calculatedTroveHistoryData.isDebtIncrease = false;
  // }
  return calculatedTroveHistoryData;
};
