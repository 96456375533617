import React, { useState } from "react";
import {
  Flex,
  Text,
  Button,
  Tooltip as ChakraTooltip,
  Link,
  useClipboard,
  Box,
} from "@chakra-ui/react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
} from "@chakra-ui/modal";
import { ExternalLinkIcon, CopyIcon, CheckIcon } from "@chakra-ui/icons";
import Icon from "../../Icon";
import CoinAmount from "../../CoinAmount";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { format, formatWithDecimals } from "../../../Utils/number";
import { useLiquitySelector } from "@yeti/lib-react";
import { formatWalletAddress } from "./../../../Utils/string";
import { walletConnectConnector } from "../../../connectors/injectedConnector";
import { useWeb3React } from "@web3-react/core";

const selector = ({
  yusdBalance,
  yetiBalance,
  veYETIStaked,
}: LiquityStoreState) => ({
  yusdBalance,
  yetiBalance,
  veYETIStaked,
});

export type UserDetailsDropdownProps = {
  isOpen: boolean;
  onClose: () => void;
};

const UserDetailsDropdown: React.FC<UserDetailsDropdownProps> = ({
  isOpen,
  onClose,
}) => {
  const { deactivate, connector } = useWeb3React<unknown>();

  const {
    liquity: {
      connection: { addresses },
    },
  } = useLiquity();
  const { account } = useLiquity();
  const { yusdBalance, yetiBalance, veYETIStaked } =
    useLiquitySelector(selector);
  const [copyValue, setCopyValue] = useState(account);
  const { hasCopied, onCopy } = useClipboard(copyValue);

  const totalVeYeti: number = formatWithDecimals(veYETIStaked.veYETITotal, 36);

  const addToken = (params: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const func = window.ethereum.request;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    func({ method: "wallet_watchAsset", params }) //@ts-ignore
      .then(() => setLog([...log, "Success, Token added!"]))
      .catch((error: Error) => setLog([...log, `Error: ${error.message}`]));
  };

  async function deactivateWallet() {
    try {
      if (
        (await walletConnectConnector.getProvider()) != undefined &&
        (await walletConnectConnector.getProvider()).connected == true
      ) {
        walletConnectConnector.close();
      } else {
        deactivate();
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  const [log, setLog] = useState<string[]>([]);

  const addYusdToken = () => {
    addToken({
      type: "ERC20",
      options: {
        address: addresses["yusdToken"],
        symbol: "YUSD",
        decimals: 18,
        image: "https://i.ibb.co/DCL8fhg/yusd-Token.png",
      },
    });
  };

  const CircleIcon = (props: any) => (
    <Icon viewBox="0 0 200 200" {...props}>
      <path
        fill="currentColor"
        d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
      />
    </Icon>
  );

  const addYetiToken = () => {
    addToken({
      type: "ERC20",
      options: {
        address: addresses["yetiToken"],
        symbol: "YETI",
        decimals: 18,
        image: "https://i.ibb.co/fvT5sMy/YETI-LOGO.png",
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg="surface" color="text-primary">
          <Box display="flex" gap="8px" alignItems="center">
            <CircleIcon color="#26B571" boxSize="8px" />
            <Text textStyle="number_base">
              {formatWalletAddress(account, 6, 4, 13)}
            </Text>
          </Box>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody bg="surface" color="text-primary">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              onClick={onCopy}
              fontWeight="semibold"
              fontSize={14}
              color="text-secondary"
              _hover={{ cursor: "pointer" }}
            >
              {hasCopied ? "Copied" : "Copy address"}
              {hasCopied ? <CheckIcon ml={2} /> : <CopyIcon ml={2} />}
            </Text>
            <Link
              href={`https://snowtrace.io/address/${account}`}
              isExternal
              fontWeight="semibold"
              fontSize={14}
              color="text-secondary"
            >
              View on explorer
              <ExternalLinkIcon ml={2} />
            </Link>
          </Box>
          <Flex justifyContent="space-between" mt={8}>
            <Text textStyle="subheading" color="text-secondary">
              Asset
            </Text>
            <Text textStyle="subheading" color="text-secondary">
              Wallet Balance
            </Text>
          </Flex>
          <Flex justifyContent="space-between" mt={5}>
            <ChakraTooltip
              label={"Add YUSD token to Wallet"}
              placement="top"
              bg="surface"
            >
              <Flex
                align="center"
                mr={10}
                onClick={addYusdToken}
                _hover={{ cursor: "pointer" }}
              >
                <Icon iconName={"YUSD"} h={6} w={6} />
                <Text textStyle="body2" ml={3}>
                  {"YUSD"}
                </Text>
              </Flex>
            </ChakraTooltip>
            <CoinAmount token={"YUSD"} amount={format(yusdBalance)} />
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <ChakraTooltip
              label={"Add YETI token to Wallet"}
              placement="top"
              bg="surface"
            >
              <Flex
                align="center"
                mr={10}
                onClick={addYetiToken}
                _hover={{ cursor: "pointer" }}
              >
                <Icon iconName={"YETI"} h={6} w={6} />
                <Text textStyle="body2" ml={3}>
                  {"YETI"}
                </Text>
              </Flex>
            </ChakraTooltip>
            <CoinAmount token={"YETI"} amount={format(yetiBalance)} />
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Flex align="center" mr={10}>
              <Icon iconName={"YETI"} h={6} w={6} />
              <Text textStyle="body2" ml={3}>
                {"veYETI"}
              </Text>
            </Flex>
            <CoinAmount
              noCurrencyConvert={true}
              token={"veYETI"}
              amount={format(totalVeYeti)}
            />
          </Flex>
          <Button
            variant="newPrimary"
            mb="8px"
            mt={6}
            width="100%"
            onClick={deactivate}
          >
            Disconnect
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UserDetailsDropdown;
