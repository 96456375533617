import React from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { getNum } from "../../../Utils/number";
import Icon from "../../../Components/Icon";
import { useState } from "react";
import { useEffect } from "react";

const PLPPool: React.FC = () => {
  const [APRData, setAPRData] = useState({
    USDC: {
      YETI: { value: 0 },
      PTPBase: { value: 0 },
      TotalBase: { value: 0 },
      Vector: {
        PTP: { value: 0 },
        VTX: { value: 0 },
      },
    },
    YUSD: {
      YETI: { value: 0 },
      PTPBase: { value: 0 },
      TotalBase: { value: 0 },
      Vector: {
        PTP: { value: 0 },
        VTX: { value: 0 },
      },
    },
  });

  useEffect(() => {
    const url = "https://api.yeti.finance/v1/PLPPool";
    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
        });
        setAPRData(await response.json());
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Box
        layerStyle="card"
        w={{ sm: "full", md: "340px" }}
        textStyle="number_base"
        p="24px"
      >
        <Box display="flex" flexDir="column">
          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px"
            borderColor="border"
            pb="24px"
          >
            <Box>
              <Text textStyle="subtitle3">
                <Icon iconName={"YETI"} h={6} w={6} mb={1} />{" "}
                <Icon iconName={"PTP"} h={6} w={6} ml={-1} mr={1} />
                YUSD / USDC Platypus Pool
              </Text>
              <Text textStyle="asset" color="green.300" pt="8px">
                Earn LP fees
              </Text>
            </Box>
          </Box>

          {/* Deposit YUSD container */}
          <Box alignItems="center" pt="24px" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              pb="16px"
              borderBottom="1px"
              borderColor="border"
            >
              <Box display="flex" flexDir="column">
                <Text textStyle="subheading" color="text-secondary">
                  Deposit:
                </Text>
                <Box alignItems="center" pt="8px" display="flex">
                  <Icon iconName={"YUSD"} h="24px" w="24px" />
                  <Text pl="8px">YUSD</Text>
                </Box>
              </Box>

              <Box display="flex" flexDir="column" alignItems="flex-end">
                <Text textStyle="subheading" color="text-secondary">
                  Rewards
                </Text>
                <Box alignItems="center" display="flex" pt="8px">
                  <Icon iconName={"YETI"} h="24px" w="24px" />

                  {/* <Text textStyle="subtitle3" color="#9B9EAC" fontWeight="normal">
                  YETI APR:
                </Text> */}
                  <Text textStyle="subtitle3" fontWeight="normal" ml="8px">
                    {getNum(APRData.YUSD.YETI.value * 100, 2)}%
                  </Text>
                </Box>

                <Box alignItems="center" display="flex" pt="8px">
                  <Icon iconName={"PTP"} h="24px" w="24px" />

                  {/* <Text textStyle="subtitle3" color="#9B9EAC" fontWeight="normal">
                  YETI APR:
                </Text> */}
                  <Text textStyle="subtitle3" fontWeight="normal" ml="8px">
                    {getNum(
                      (APRData.YUSD.Vector.PTP.value +
                        APRData.YUSD.PTPBase.value) *
                        100 *
                        0.82,
                      2
                    )}
                    %
                  </Text>
                </Box>

                <Text pt="8px" fontWeight="600" color="green.400">
                  {getNum(APRData.YUSD.TotalBase.value * 100, 2)}%
                </Text>
              </Box>
            </Box>
          </Box>

          {/* Deposit YUSD container */}
          <Box alignItems="center" pt="24px" justifyContent="space-between">
            <Box
              display="flex"
              justifyContent="space-between"
              pb="16px"
              borderBottom="1px"
              borderColor="border"
            >
              <Box display="flex" flexDir="column">
                <Text textStyle="subheading" color="text-secondary">
                  Deposit:
                </Text>
                <Box alignItems="center" pt="8px" display="flex">
                  <Icon iconName={"USDC"} h="24px" w="24px" />
                  <Text pl="8px">USDC</Text>
                </Box>
              </Box>

              <Box display="flex" flexDir="column" alignItems="flex-end">
                <Text textStyle="subheading" color="text-secondary">
                  Rewards
                </Text>
                <Box alignItems="center" display="flex" pt="8px">
                  <Icon iconName={"YETI"} h="24px" w="24px" />

                  {/* <Text textStyle="subtitle3" color="#9B9EAC" fontWeight="normal">
                  YETI APR:
                </Text> */}
                  <Text textStyle="subtitle3" fontWeight="normal" ml="8px">
                    {getNum(APRData.USDC.YETI.value * 100, 2)}%
                  </Text>
                </Box>

                <Box alignItems="center" display="flex" pt="8px">
                  <Icon iconName={"PTP"} h="24px" w="24px" />

                  {/* <Text textStyle="subtitle3" color="#9B9EAC" fontWeight="normal">
                  YETI APR:
                </Text> */}
                  <Text textStyle="subtitle3" fontWeight="normal" ml="8px">
                    {getNum(
                      (APRData.USDC.Vector.PTP.value +
                        APRData.USDC.PTPBase.value) *
                        100 *
                        0.82,
                      2
                    )}
                    %
                  </Text>
                </Box>

                <Text pt="8px" fontWeight="600" color="green.400">
                  {getNum(APRData.USDC.TotalBase.value * 100, 2)}%
                </Text>
              </Box>
            </Box>
          </Box>

          <Box display="flex">
            <Button variant="surface" w="full" mt="32px">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://app.platypus.finance/pool?pool_group=alt"}
                style={{
                  outline: "none",
                  textDecoration: "none",
                  fontSize: "14px",
                }}
              >
                Stake on Platypus
              </a>
            </Button>
          </Box>
        </Box>
      </Box>
      {/* <Box layerStyle="card" flex={1}>
        <Center alignItems="center" mb={7}>
          <Text textStyle="title3">
            <Icon iconName={"YUSD"} h={10} w={10} /> YUSD / <Icon iconName={"USDC"} h={9} w={9} /> USDC Platypus Pool {" "}
            <Tooltip>Deposit on Vector Finance</Tooltip>
          </Text>
        </Center>
        <Flex mb={7}>
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="bold">
              Deposit <Icon iconName={"YUSD"} h={6} w={6} /> YUSD:
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="normal">
            <Icon iconName={"YETI"} h={6} w={6} /> YETI APR: {getNum(APRData.YUSD.YETI.value * 100, 2)}%
          </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="normal">
            <Icon iconName={"PTP"} h={6} w={6} /> PTP APR: {getNum((APRData.YUSD.Vector.PTP.value + APRData.YUSD.PTPBase.value) * 100 * 0.82, 2)}%
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="normal">
            <Icon iconName={"VTX"} h={6} w={6} /> VTX APR: {getNum(APRData.YUSD.Vector.VTX.value * 100, 2)}%
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Tag bgColor="secondary.400">
              <Text textStyle="subtitle1">
                Total APR: {getNum((APRData.YUSD.YETI.value + APRData.YUSD.Vector.PTP.value + APRData.YUSD.Vector.VTX.value) * 100, 2)}%
              </Text>
            </Tag>
          </Flex>

          <Spacer />

        </Flex>
        <Flex mb={7}>
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="bold">
              Deposit <Icon iconName={"USDC"} h={6} w={6} /> USDC:
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="normal">
            <Icon iconName={"YETI"} h={6} w={6} /> YETI APR: {getNum(APRData.USDC.YETI.value * 100, 2)}%
          </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="normal">
            <Icon iconName={"PTP"} h={6} w={6} /> PTP APR: {getNum((APRData.USDC.Vector.PTP.value + APRData.USDC.PTPBase.value) * 100 * 0.82, 2)}%
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Text textStyle="subtitle1" fontWeight="normal">
            <Icon iconName={"VTX"} h={6} w={6} /> VTX APR: {getNum(APRData.USDC.Vector.VTX.value * 100, 2)}%
            </Text>
          </Flex>
          <Spacer />
          <Flex alignItems="center" ml={5}>
            <Tag bgColor="secondary.400">
              <Text textStyle="subtitle1">
                Total APR: {getNum((APRData.USDC.YETI.value + APRData.USDC.Vector.PTP.value + APRData.USDC.Vector.VTX.value) * 100, 2)}%
              </Text>
            </Tag>
          </Flex>

          <Spacer />

        </Flex>

        <Flex alignItems="center">
          <Spacer />
          <Button colorScheme="brand"> <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://vectorfinance.io/stake"}
            style={{ outline: "none", textDecoration: "none" }}
          >
            Stake on Vector
          </a> </Button>
          <Spacer />
        </Flex>
      </Box> */}
    </>
  );
};

export default PLPPool;
