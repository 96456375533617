import { Box, Text } from "@chakra-ui/react";
import React from "react";

export type TermsProps = {
  disconnected?: boolean;
};

const Terms: React.FC<TermsProps> = ({ disconnected = false }) => {
  return (
    <>
      <Box
        w="full"
        h="320px"
        bg="#141724"
        position="absolute"
        zIndex="-1"
        left="0"
        right="0"
        top="0"
      />
      <Text color="white" textStyle="display_md" borderColor="#31354F" pb="8px">
        Terms of Use
      </Text>
      <Box mt="48px" display="flex" flexDir="column">
        {/* <SystemSummary /> */}
        <Text fontSize="20px">Disclaimer: Risks of Using Protocol</Text>
        <Text fontSize="15px" color="text-primary" mt="16px">
          Yeti Finance is a novel, decentralized borrowing protocol that allows
          users to deposit assets and borrow the protocol’s native stablecoin,
          YUSD, against them. The Yeti Finance protocol is made up of both
          proprietary and free, public, and open-source software. Your use of
          Yeti Finance involves various risks, including, but not limited, to
          losses while digital assets are deposited into Yeti Finance via smart
          contract or economic exploits, and losses due to liquidations and
          redemptions. Before borrowing, staking, or liquidity providing you
          should fully review our technical documentation to understand how the
          Yeti Finance protocol works.
        </Text>
        <Text fontSize="15px" color="text-primary" mt="16px">
          While the Yeti Finance Decentralized Finance Protocol has been
          thoroughly audited by multiple independent software security firms and
          undergone third-party economic analysis, there remains a risk that
          assets deposited into the protocol as well as the YUSD and YETI tokens
          may suffer complete and permanent economic loss should the protocol’s
          technical or economic mechanisms suffer catastrophic failure.
        </Text>
        <Text fontSize="15px" color="text-primary" mt="16px">
          USE AT YOUR OWN RISK: THE YETI FINANCE PROTOCOL IS PROVIDED “AS IS”,
          WITHOUT WARRANTIES OF ANY KIND. No developer or entity involved in
          creating the YETI FINANCE will be liable for any claims or damages
          whatsoever associated with your use, inability to use, or your
          interaction with other users of the Yeti Finance protocol, including
          any direct, indirect, incidental, special, exemplary, punitive or
          consequential damages, or loss of profits, cryptocurrencies, tokens,
          or anything else of value. Yeti reserves the right to modify the terms
          of the Yeti protocol at any time for any reason.
        </Text>
      </Box>
    </>
  );
};

export default Terms;
