import React, { useEffect, useState } from "react";
import {
  Flex,
  Button,
  Input,
  Link,
  NumberInput,
  NumberInputField,
  Box,
  Text,
  Spacer,
  useTheme,
  useDisclosure,
} from "@chakra-ui/react";
import { Icon, TokenTable, Loader } from "../../../Components";
import { Decimal, LiquityStoreState, TroveMappings } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import tokenData, { tokenDataMappingA } from "../../../TokenData";
import { format, getNum, formatWithDecimals } from "../../../Utils/number";
import setupWeb3 from "./utils/setupWeb3";
import getTroveHistory from "./utils/getTroveHistory";
import getTokenName from "./utils/getTokenName";
import { getCalculatedTroveHistoryDay } from "./utils/getCalculatedTroveHistoryDay";
import TroveSummaryModal from "./TroveSummary/TroveSummaryModal";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const selector = ({
  trove,
  prices,
  tokenBalances,
  total,
  safetyRatios,
  recoveryRatios,
  YUSDPrice,
  icr,
  decimals,
  farm,
  yusdInStabilityPool,
  underlyingPerReceiptRatios,
}: LiquityStoreState) => ({
  trove,
  prices,
  tokenBalances,
  total,
  safetyRatios,
  recoveryRatios,
  YUSDPrice,
  icr,
  decimals,
  farm,
  yusdInStabilityPool,
  underlyingPerReceiptRatios,
});

export type TroveHistoryData = {
  tokens: string[];
  amounts: number[];
  realAmounts: number[];
  values: number[];
  collsIn: string[];
  amountsIn: number[];
  realAmountsIn: number[];
  valuesIn: number[];
  collsOut: string[];
  amountsOut: number[];
  realAmountsOut: number[];
  valuesOut: number[];
  currentICR: number;
  YUSDchange: number;
  blockNum: number;
  totalValue: number;
  totalValueChange: number;
  debt: number;
  isDebtIncrease: boolean;
  operation: string;
  transaction: string;
  timestamp: number;
  vaultData: any;
};

export type DayHistory = {
  day: string;
  data: Array<TroveHistoryData>;
};

const TroveHistory: React.FC = () => {
  const [web3, setWeb3Provider] = useState<any>(null);

  useEffect(() => {
    const getWeb3Provider = async () => {
      // console.log('setting web3')
      setWeb3Provider((await setupWeb3()).avax_web3);
    };
    getWeb3Provider();
  }, []);

  const { account } = useLiquity();

  // const userID = "0x7cdb2a7107b49230197bb620ef23f99ac3574133"

  const [idx, setIdx] = useState<number>(0);

  const [maxIdx, setMaxIdx] = useState<number>(0);

  const [pageLength, setPageLength] = useState<number>(-1);

  const dataLengthPerPage = 10;

  const [loading, setLoading] = useState<boolean>(true);

  const [fetchedHistory, setFetchedHistory] = useState<Array<any>>([]);

  const [indexedHistory, setIndexedHistory] = useState<
    Map<number, Array<TroveHistoryData>>
  >(new Map<number, Array<TroveHistoryData>>());

  const [cachedHistory, setCachedHistory] = useState<Map<number, Array<any>>>(
    new Map<number, Array<any>>()
  );

  // Get Full Trove History

  const getIndexedTroveHistory = async (userID: string) => {
    cachedHistory.clear();

    const array: any = await getTroveHistory(userID);

    setFetchedHistory(array);

    // console.log('got array', array)

    return array;
  };

  const onClickLeft = () => {
    if (idx > 0) {
      setIdx(idx - 1);
      setIdxInput(idx);
    }
  };

  const onClickRight = () => {
    if (idx + 1 < pageLength) {
      setIdx(idx + 1);
      setIdxInput(idx + 2);
      if (idx + 1 > maxIdx) {
        setMaxIdx(idx + 1);
      }
    }
  };

  const [userID, setuserID] = useState<string>(account);

  useEffect(() => {
    const getNextHistoryBatch = async () => {
      setLoading(true);

      const getSetFullHistory = async () => {
        if (loading) {
          const fetchedHistoryResult = await getIndexedTroveHistory(userID);
          return fetchedHistoryResult;
        }
        return fetchedHistory;
      };

      const getIndexedHistory = async () => {
        if (indexedHistory.size > 0) {
          return indexedHistory;
        }
        // console.log('fetching new data')
        const rawHistory = new Map<number, Array<TroveHistoryData>>();
        const fullHistory = await getSetFullHistory();
        let index = 0;
        let dataCount = 0;
        let batch: Array<TroveHistoryData> = [];
        for (let i = 0; i < fullHistory.length; i++) {
          const dayHistory = fullHistory[i][1];
          for (let j = 0; j < dayHistory.length; j++) {
            if (dataCount >= dataLengthPerPage) {
              rawHistory.set(index, batch);
              index += 1;
              batch = [];
              dataCount = 0;
            }
            batch.push(dayHistory[j]);
            dataCount += 1;
          }
        }
        if (batch.length > 0) {
          rawHistory.set(index, batch);
        }
        // console.log('setting page length', rawHistory.size)
        setPageLength(rawHistory.size);
        setIndexedHistory(rawHistory);
        return rawHistory;
      };

      const rawHistory = await getIndexedHistory();

      const currentBatch: Array<TroveHistoryData> =
        new Array<TroveHistoryData>();

      if (rawHistory.size == 0) {
        console.log("No data");
        setLoading(false);
        return;
      }

      const rawBatch: any = rawHistory.get(idx);
      // console.log('rawBatch', rawBatch)
      for (let j = 0; j < rawBatch.length; j++) {
        const dayEntry = await getCalculatedTroveHistoryDay(
          web3,
          userID,
          rawBatch[j],
          safetyRatios
        );
        // console.log("day Entry", dayEntry);
        currentBatch.push(dayEntry);
      }

      // console.log('updating cachedHistory', cachedHistory.set(idx, currentBatch))

      // console.log('should display', cachedHistory.get(idx))

      setCachedHistory(cachedHistory.set(idx, currentBatch));

      setLoading(false);
    };

    // console.log(loading, cachedHistory.has(idx))

    if (!cachedHistory.has(idx) || loading) {
      // console.log('fetching data', cachedHistory)

      getNextHistoryBatch().then(() => setLoading(false));
    }
  }, [idx, userID]);

  const [idxInput, setIdxInput] = useState<number>(1);

  const handleChange = (idxInput: any) => {
    setIdxInput(Number(idxInput));
  };

  const onClickGo = () => {
    // console.log('click Go', idxInput - 1, pageLength)
    if (idxInput - 1 < pageLength) {
      setIdx(idxInput - 1);
      if (idxInput - 1 > maxIdx) {
        // console.log('click go max', idxInput - 1)
        setMaxIdx(idxInput - 1);
      }
    }
  };

  const {
    trove,
    prices,
    tokenBalances,
    total,
    safetyRatios,
    recoveryRatios,
    YUSDPrice,
    icr,
    decimals,
    farm,
    yusdInStabilityPool,
    underlyingPerReceiptRatios,
  } = useLiquitySelector(selector);
  const { liquity } = useLiquity();
  const { yeti } = useTheme();

  // console.log('YUSDPrice', +String(YUSDPrice))
  tokenData.map(
    (token) =>
      (token["troveBalance"] = formatWithDecimals(
        trove.collaterals[token.address],
        decimals[token.address].toNumber()
      ))
  );
  tokenData.map(
    (token) =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[
          token.underlying == "" ? token.address : token.underlying
        ],
        token.underlyingDecimals
      ))
  );

  const depositedCollateral = tokenData.filter(
    (token) => token.walletBalance !== 0 || token.troveBalance !== 0
  );

  const heldCollateral = tokenData.filter((token) => token.troveBalance !== 0);

  const collateralizationRatio = format(icr) * 100;

  const totalBorrowed = format(trove.debt["debt"]);

  const ratioMapping: TroveMappings = {};
  const [ratios, setRatios] = useState<TroveMappings>(ratioMapping);

  let vcValue = 0;
  let usdValue = 0;
  let stableVC = 0;
  let stableUSD = 0;
  tokenData.map((token) => {
    const curBal: Decimal = trove.collaterals[token.address];
    let vc: number;
    let usd: number;
    const safetyRatio = format(safetyRatios[token.address]);
    const dec = decimals[token.address].toNumber();
    if (curBal != undefined) {
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(curBal, dec);
      // console.log(token.token +'ddd', vc)
      vcValue += vc;
      usd = format(prices[token.address]) * formatWithDecimals(curBal, dec);
      usdValue += usd;
    } else {
      vc =
        format(prices[token.address]) *
        safetyRatio *
        formatWithDecimals(trove.collaterals[token.address], dec);
      vcValue += vc;

      usd =
        format(prices[token.address]) *
        formatWithDecimals(trove.collaterals[token.address], dec);
      usdValue += usd;
    }
    if (token.isStable) {
      stableVC += vc;
      stableUSD += usd;
    }
  });
  // console.log('vcValue summary', vcValue)

  const troveHealth =
    stableVC * 1.1 > totalBorrowed && stableVC / vcValue > 0.99
      ? 200 - (200 - 110) * Math.exp((-1 / 9) * (collateralizationRatio - 110))
      : collateralizationRatio;

  const getCollateralRatioDisplay = (collateralRatio: number) => {
    if (collateralRatio < 125) {
      return ["RedThermometer", "red.500"];
    } else if (collateralRatio < 165) {
      return ["YellowThermometer", "yellow.500"];
    } else {
      return ["GreenThermometer", "green.500"];
    }
  };

  const getTroveRiskynessMsg = () => {
    const riskeyness =
      ((vcValue - stableVC) / (totalBorrowed - stableVC)) * 100;
    const precentStable = (stableVC / vcValue) * 100;
    let safteyLable: string;
    let amountRoom: string;
    if (collateralizationRatio === 0) {
      return "";
    }
    if (stableUSD > totalBorrowed) {
      if ((collateralizationRatio * precentStable) / 100 < 112) {
        safteyLable = "risky";
        amountRoom = "not much";
      } else if ((collateralizationRatio * precentStable) / 100 < 114) {
        safteyLable = "medium risk";
        amountRoom = "some";
      } else {
        safteyLable = "safe";
        amountRoom = "a lot of";
      }
    } else if (riskeyness < 130) {
      safteyLable = "risky";
      amountRoom = "not much";
    } else if (riskeyness < 170) {
      safteyLable = "low-medium risk";
      amountRoom = "some";
    } else {
      safteyLable = "safe";
      amountRoom = "a lot of";
    }
    return `Your trove is comprised of ${precentStable.toFixed(3)}% stables${
      riskeyness > 0
        ? `, with an ICR without stable coins of ${riskeyness.toFixed(3)}%.`
        : "."
    } We deem this as ${safteyLable} since there is ${amountRoom} room for your collateral prices to fall before reaching the liquidation threshold of 110%.`;
  };

  let totalSystemVC = 0;
  let totalSystemUSD = 0;
  let totalStableUSD = 0;

  Object.keys(total.collaterals).map((address) => {
    const amountUSD = format(
      total.collaterals[address]
        .mul(10 ** (18 - format(decimals[address])))
        .mul(prices[address])
        .div(1e18)
    );
    totalSystemVC += amountUSD * format(recoveryRatios[address]);
    totalSystemUSD += amountUSD;
    if (
      tokenDataMappingA[address] !== undefined &&
      tokenDataMappingA[address].isStable
    ) {
      totalStableUSD += amountUSD;
    }
  });
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  // console.log('cachedHistory', cachedHistory)

  const [summaryData, setSummaryData] = useState<any>(null);

  const {
    isOpen: isSummaryOpen,
    onOpen: onSummaryOpen,
    onClose: onSummaryClose,
  } = useDisclosure();

  function trclick(history: any) {
    // console.log('setting summary', history)
    setSummaryData(history);
    onSummaryOpen();
    // console.log('clicked')
  }

  const getFormattedDate = (date: Date) => {
    // console.log('date', date.toLocaleString() )
    const options: any = {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
    };
    const string = date.toLocaleString("en-US", options).split(" ");
    return (
      string[0].slice(0, 3) +
      " " +
      string[1] +
      ", " +
      string[3] +
      " " +
      string[4]
    );
  };

  const getTransactionType = (
    operation: string,
    collsIn: string[],
    collsOut: string[],
    debt: number
  ) => {
    switch (operation) {
      case "liquidateInNormalMode":
        return "Liquidated";
      case "adjustTrove":
        if (collsIn.length == 0 && collsOut.length == 0) {
          return "Change Debt";
        }
        return "Adjust Portfolio";
      case "redeemCollateral":
        if (debt == 0) {
          return "Closed by Redemption";
        }
        return "Partially Redeemed";
      case "openTrove":
        return "Open Trove";
      case "closeTrove":
        return "Close Trove";
      default:
        return "Undefined";
    }
  };

  const [idInput, setIdInput] = useState<string>("");

  const handleIdChange = (event: any) => {
    // console.log('id change')
    setIdInput(event.target.value);
  };

  const onClickSearch = () => {
    // console.log('search')
    setLoading(true);
    if (idInput == "") {
      setuserID(account);
    } else {
      indexedHistory.clear();
      // console.log('searching!')
      setuserID(idInput);
    }
    setIdx(0);
    setIdxInput(1);
  };

  // console.log('history', cachedHistory)

  return (
    <>
      {summaryData != null && (
        <TroveSummaryModal
          isOpen={isSummaryOpen}
          onClose={onSummaryClose}
          data={summaryData}
        />
      )}

      <Box w="full" layerStyle="card">
        <Box display="flex" justifyContent="space-between" p="24px">
          <Box display="flex" w="full">
            <Text textStyle="card_header" mb={4}>
              Transaction history
            </Text>
          </Box>
          <Box gap="16px" display="flex" w="full">
            <Input
              textStyle="text_sm"
              w="full"
              placeholder={"Account: ".concat(account)}
              value={idInput}
              onChange={handleIdChange}
            />{" "}
            <Button
              variant="surface"
              onClick={onClickSearch}
              disabled={pageLength > 0 && !cachedHistory.has(idx)}
            >
              {" "}
              <Text textStyle="text_md"> {"Search"} </Text>{" "}
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          pb="16px"
          px="24px"
          textStyle="text_sm"
          color="text-secondary"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex">
            <Box display="flex">
              <Text>Event</Text>
            </Box>
          </Box>
          <Box display="flex">
            <Box w="120px" display="flex" justifyContent="flex-end">
              <Text>Asset Changes</Text>
            </Box>
            <Box w="160px" display="flex" justifyContent="flex-end">
              <Text>Deposit Change</Text>
            </Box>
            <Box w="160px" display="flex" justifyContent="flex-end">
              <Text>Final Collateral Balance</Text>
            </Box>
            <Box w="180px" display="flex" justifyContent="flex-end">
              <Text>Final Debt</Text>
            </Box>
            <Box w="140px" display="flex" justifyContent="flex-end">
              <Text>ICR</Text>
            </Box>
            <Box w="220px" display="flex" justifyContent="flex-end">
              <Text>Date</Text>
            </Box>
            <Box w="40px" />
          </Box>
          {/* <Hide below="md">
            <Box w="300px">
              <Text>Wallet</Text>
            </Box>
          </Hide> */}
        </Box>

        {pageLength == 0 ? (
          <Box
            py="64px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
            borderTop="1px"
            borderColor="border"
          >
            <Text textStyle="text_md">No borrow history yet</Text>
          </Box>
        ) : (
          <>
            {!cachedHistory.has(idx) ? (
              <Flex flexDirection="column" alignItems="center" gap={4}>
                <Loader />
                <Text textStyle="text_sm" color="text-secondary">
                  Fetching Data...{" "}
                </Text>
              </Flex>
            ) : (
              <>
                <TokenTable
                // headers={[
                //   "Transaction Type",
                //   "Asset Changes",
                //   "Collateral Change",
                //   "Final Collateral Value ($)",
                //   "Final Debt",
                //   "ICR (%)",
                //   "Date",
                // ]}
                // width={7}
                >
                  {cachedHistory.get(idx)!.map((history) => (
                    <Box
                      display="flex"
                      w="full"
                      key={history}
                      layerStyle="tableRow"
                      onClick={() => trclick(history)}
                      _hover={{ bg: "surface-hover" }}
                      // fontSize="15px"
                      alignItems="center"
                      h="72px"
                      borderTop="1px"
                      textStyle="number_base"
                      borderColor="border"
                      justifyContent="space-between"
                      cursor="pointer"
                    >
                      <Box display="flex">
                        <Box display="flex" alignItems="center">
                          <Text>
                            {" "}
                            {getTransactionType(
                              history["operation"],
                              history["collsIn"],
                              history["collsOut"],
                              Number(history["debt"])
                            )}{" "}
                          </Text>
                        </Box>
                      </Box>
                      <Box display="flex">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="80px"
                        >
                          {history["collsIn"].map((token: string) => {
                            return (
                              <Icon
                                key={token}
                                iconName={getTokenName(token)}
                                h={5}
                                w={5}
                                mr={3}
                              />
                            );
                          })}
                          {history["collsOut"].map((token: string) => {
                            return (
                              <Icon
                                key={token}
                                iconName={getTokenName(token)}
                                h={5}
                                w={5}
                                mr={3}
                              />
                            );
                          })}
                          {/* <Text>{history['totalValueChange']} YUSD</Text> */}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="160px"
                        >
                          {history["totalValueChange"] == 0 ? (
                            <Flex> -- </Flex>
                          ) : (
                            <Flex>
                              {history["totalValueChange"] > 0 ? (
                                <Text
                                  color="success"
                                  textAlign={["right", "left"]}
                                >
                                  {" "}
                                  +$
                                  {Number(history["totalValueChange"])
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Text>
                              ) : (
                                <Text
                                  textStyle="subtitle3"
                                  color="red.400"
                                  textAlign={["right", "left"]}
                                >
                                  {" "}
                                  -$
                                  {(-1 * Number(history["totalValueChange"]))
                                    .toFixed(2)
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </Text>
                              )}
                            </Flex>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="160px"
                        >
                          $
                          {Number(history["totalValue"])
                            .toFixed()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="180px"
                        >
                          {Number(history["debt"] * 10 ** -18)
                            .toFixed()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          YUSD
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="140px"
                        >
                          {getNum(history["currentICR"] * 10 ** -16, 2)}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="220px"
                        >
                          {getFormattedDate(
                            new Date(history["timestamp"] * 1000)
                          )}
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                          w="40px"
                        >
                          <Link
                            color="text-secondary"
                            pl="4px"
                            textDecorationLine="underline"
                            _hover={{ color: "primary-default" }}
                            textStyle="subtitle3"
                            href={
                              "https://snowtrace.io/tx/" +
                              history["transaction"]
                            }
                            isExternal
                          >
                            <ExternalLinkIcon mb={1}> </ExternalLinkIcon>
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </TokenTable>
              </>
            )}
          </>
        )}
        {pageLength > 0 && (
          <Flex mt={3} mb={3} px="24px" justifyContent="space-between">
            <Flex alignItems="center">
              <Text textStyle="text_md" mr={2}>
                {" "}
                Page
              </Text>
              <NumberInput
                size="s"
                maxW="40px"
                mr="2"
                value={idxInput}
                min={1}
                max={pageLength}
                onChange={handleChange}
              >
                {" "}
                <NumberInputField
                  textAlign="center"
                  textStyle="text_md"
                ></NumberInputField>
              </NumberInput>
              <Text textStyle="text_md" mr={2}>
                {" "}
                of {pageLength}{" "}
              </Text>
            </Flex>
            <Button
              mr={2}
              variant="surface"
              onClick={onClickGo}
              disabled={!loading && !cachedHistory.has(idx)}
            >
              {" "}
              <Text textStyle="text_md"> {"Go"} </Text>{" "}
            </Button>

            <Spacer />
            <Button
              mr={2}
              variant="surface"
              width="80px"
              onClick={onClickLeft}
              disabled={idx == 0 || !cachedHistory.has(idx)}
            >
              {" "}
              <Text> Previous </Text>{" "}
            </Button>
            <Button
              variant="surface"
              width="80px"
              onClick={onClickRight}
              disabled={!cachedHistory.has(idx) || idx + 1 >= pageLength}
            >
              {" "}
              <Text> Next </Text>{" "}
            </Button>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default TroveHistory;
