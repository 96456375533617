import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import StatColumn from "../StatColumn";
import { LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format, getNum } from "../../../Utils/number";
import { tokenDataMappingA } from "../../../TokenData";

const selector = ({
  yusdInStabilityPool,
  YUSDPrice,
  prices,
  farm,
  total,
  decimals,
  boostedFarm,
  recoveryRatios,
}: LiquityStoreState) => ({
  yusdInStabilityPool,
  YUSDPrice,
  prices,
  farm,
  total,
  decimals,
  boostedFarm,
  recoveryRatios,
});

const SystemSummary: React.FC = () => {
  const {
    yusdInStabilityPool,
    YUSDPrice,
    prices,
    farm,
    total,
    decimals,
    boostedFarm,
    recoveryRatios,
  } = useLiquitySelector(selector);

  let totalSystemVC = 0;
  let totalSystemUSD = 0;
  let totalStableUSD = 0;

  Object.keys(total.collaterals).map((address) => {
    const amountUSD = format(
      total.collaterals[address]
        .mul(10 ** (18 - format(decimals[address])))
        .mul(prices[address])
        .div(1e18)
    );
    totalSystemVC += amountUSD * format(recoveryRatios[address]);
    totalSystemUSD += amountUSD;
    if (
      tokenDataMappingA[address] !== undefined &&
      tokenDataMappingA[address].isStable
    ) {
      totalStableUSD += amountUSD;
    }
  });
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  // TODO
  const LPPrice = format(YUSDPrice);
  const TVL =
    totalSystemUSD +
    format(farm.totalLPStaked.add(boostedFarm.totalLPStaked)) * LPPrice +
    format(yusdInStabilityPool);

  const [CurvePoolData, setCurvePoolData] = useState({
    liquidity: {
      value: 0,
      usd: 0,
    },
  });

  const [PLPPoolData, setPLPPoolData] = useState({
    USDC: {
      Deposits: {
        value: 0,
        usd: 0,
      },
    },
    YUSD: {
      Deposits: {
        value: 0,
        usd: 0,
      },
    },
  });

  useEffect(() => {
    const curvePoolUrl = "https://api.yeti.finance/v1/CurvePool";
    const plpPoolUrl = "https://api.yeti.finance/v1/PLPPool";
    const fetchData = async () => {
      try {
        const curveResponse = await fetch(curvePoolUrl, {
          method: "GET",
          mode: "cors",
        });
        const plpResponse = await fetch(plpPoolUrl, {
          method: "GET",
          mode: "cors",
        });
        setCurvePoolData(await curveResponse.json());
        setPLPPoolData(await plpResponse.json());
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, []);

  return (
    // <Box layerStyle="base" flex={1} px={2}>
    //   <Text textStyle="title3" textAlign={["center", "left"]} pb={6} px={5}>
    //     System Overview
    //   </Text>
    //   <Text textStyle="subtitle1" textAlign={["center", "left"]} pb={6} px={5}>
    //     Total Value Locked:
    //   </Text>
    //   <Flex px={6} mb={2}>
    //     <Text textStyle="subtitle2" fontWeight="normal" >
    //       System Total Collateral:
    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle2" fontWeight="normal">${getNum(totalSystemUSD, 4)}</Text>
    //   </Flex>
    //   <Flex px={6} mb={2}>
    //     <Text textStyle="subtitle2" fontWeight="normal">
    //       Stability Pool Deposits:
    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle2" fontWeight="normal">${getNum(format(yusdInStabilityPool), 4)}</Text>
    //   </Flex>
    //   <Flex px={6} mb={2}>
    //     <Text textStyle="subtitle2" fontWeight="normal">
    //       Curve YUSD LP Token Staked:
    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle2" fontWeight="normal">${getNum(format(farm.totalLPStaked.add(boostedFarm.totalLPStaked)) * LPPrice, 4)}</Text>
    //   </Flex>
    //   <Flex px={6} >
    //     <Text textStyle="subtitle1" >
    //       TVL:
    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle1">${getNum(TVL, 4)}</Text>
    //   </Flex>

    //   <Text textStyle="subtitle1" textAlign={["center", "left"]} pb={6} px={5} mt={5}>
    //     Stablecoin Liquidity:
    //   </Text>
    //   <Flex px={6} mb={2}>
    //     <Text textStyle="subtitle2" fontWeight="normal" as="u">
    //       <a
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           href={"https://avax.curve.fi/factory/69"}
    //           style={{ outline: "none", textDecoration: "none" }}
    //         >
    //           Curve YUSD Pool:
    //       </a>

    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle2" fontWeight="normal">${getNum(CurvePoolData.liquidity.usd, 4)}</Text>
    //   </Flex>
    //   <Flex px={6} mb={2}>
    //     <Text textStyle="subtitle2" fontWeight="normal" as="u">
    //      <a
    //           target="_blank"
    //           rel="noopener noreferrer"
    //           href={"https://app.platypus.finance/pool?pool_group=alt"}
    //           style={{ outline: "none", textDecoration: "none" }}
    //         >
    //          Platypus YUSD/USDC Pool:
    //       </a>

    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle2" fontWeight="normal">${getNum(PLPPoolData.USDC.Deposits.usd + PLPPoolData.YUSD.Deposits.usd, 4)}</Text>
    //   </Flex>
    //   <Flex px={6} >
    //     <Text textStyle="subtitle1" >
    //       Total:
    //     </Text>
    //     <Spacer />
    //     <Text textStyle="subtitle1">${getNum(CurvePoolData.liquidity.usd + PLPPoolData.USDC.Deposits.usd + PLPPoolData.YUSD.Deposits.usd, 4)}</Text>
    //   </Flex>
    // </Box>
    <Box layerStyle="card" p="24px">
      <Box display="flex" flexDir="column" gap="16px">
        <Box display="flex" gap="16px">
          <Box display="flex" w="full">
            <StatColumn
              iconName="Bank"
              amount={`$${getNum(TVL, 4)}`}
              color={"green.500"}
              description="Total Value Locked"
            />
          </Box>
          <Box display="flex" w="full">
            <StatColumn
              iconName="MoneyStack"
              amount={`$${getNum(totalSystemUSD, 4)}`}
              description="Total System Collateral"
            />
          </Box>
          <Box display="flex" w="full">
            <StatColumn
              iconName="MoneyStack"
              amount={`$${getNum(
                format(farm.totalLPStaked.add(boostedFarm.totalLPStaked)) *
                  LPPrice,
                4
              )}`}
              description="Curve YUSD LP Tokens Staked"
            />
          </Box>
          <Box display="flex" w="full">
            <StatColumn
              iconName="MoneyStack"
              amount={`$${getNum(format(yusdInStabilityPool), 4)}`}
              description="Stability Pool Deposits"
            />
          </Box>
        </Box>
        <Box display="flex" gap="16px">
          <Box display="flex" w="full">
            <StatColumn
              iconName="MoneyStack"
              amount={`${(totalSystemRatio * 100).toFixed(3)}%`}
              description="System Collateral Ratio"
              textSize={"14"}
            />
          </Box>
          <Box display="flex" w="full">
            <StatColumn
              iconName="Bank"
              amount={`${getNum((totalStableUSD / totalSystemUSD) * 100, 3)}%`}
              description="System Stablecoin %"
            />
          </Box>
          <Box display="flex" w="full">
            <StatColumn
              iconName="Bank"
              amount={`$${getNum(CurvePoolData.liquidity.usd, 4)}`}
              link={"https://avax.curve.fi/factory/69"}
              description="Curve YUSD Pool"
            />
          </Box>
          <Box display="flex" w="full">
            <StatColumn
              iconName="Bank"
              amount={`$${getNum(
                PLPPoolData.USDC.Deposits.usd + PLPPoolData.YUSD.Deposits.usd,
                4
              )}`}
              link={"https://app.platypus.finance/pool?pool_group=alt"}
              description="Platypus YUSD/USDC Pool"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SystemSummary;
