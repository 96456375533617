import { createIcon } from "@chakra-ui/react";

const qiAVAX = createIcon({
  displayName: "qiAVAX",
  viewBox: "150 100 1800 1800",
  path: (
    <>
      <g>
        <image
          xmlns="http://www.w3.org/2000/svg"
          width="3563"
          height="3564"
          transform="translate(144.76 144.72) scale(0.48)"
          href="https://i.ibb.co/8YrtJFc/benqi-circle.png"
        />
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="993.59"
          cy="999.85"
          r="642.06"
          fill="#fff"
        />
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M1161.47,1002.05c15.86-27.4,41.45-27.4,57.31,0l98.76,173.37c15.86,27.39,2.89,49.74-28.83,49.74h-199c-31.36,0-44.34-22.35-28.84-49.74Zm-191-333.76c15.86-27.4,41.09-27.4,57,0l22,39.64,51.9,91.19a94.71,94.71,0,0,1,0,82.54L927.19,1183.35a91.55,91.55,0,0,1-71.36,41.81H711.29c-31.72,0-44.69-22-28.83-49.74Z"
          fill="#00b2ec"
        />
      </g>
    </>
  ),
});
export default qiAVAX;
