import React, { useEffect, useState } from "react";
import { Box, useDisclosure, Button, Text } from "@chakra-ui/react";
import { Decimal, LiquityStoreState } from "@yeti/lib-base";
import { useLiquitySelector } from "@yeti/lib-react";
import { format, getNum } from "../../../Utils/number";
// import { getNewEstimatedWeeklyRewardsAmount } from "../VEStakeCard/veStakeCard.utils";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { useTransactionFunction } from "../../../Components/Transaction";
// import StatColumn from "../../../PageComponents/Dashboard/StatColumn";

const selector = ({ veYETIStaked }: LiquityStoreState) => ({
  veYETIStaked,
});

const VEEmissions: React.FC = () => {
  const { veYETIStaked } = useLiquitySelector(selector);
  const yetiStaked = format(veYETIStaked.yetiStake);
  const yetiStakedOnFarm = format(veYETIStaked.yetiStakeOnFarm);
  const totalYeti: Decimal = veYETIStaked.totalYeti;
  const yetiEarned: Decimal = veYETIStaked.yetiEarned;
  const totalStaked: number = format(veYETIStaked.totalUserYeti);

  const { liquity } = useLiquity();

  const [value, setValue] = useState<Record<string, any>>({});
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));

  // Use Effect for getting the rewardEarned from Yeti Emissions.
  useEffect(() => {
    liquity
      .getEstimatedVeYetiRewards(format(totalYeti), 604800)
      .then((num) => setReward(num));
  }, [value.stakeInput, totalYeti]);

  let stakeShare: number;
  if (
    veYETIStaked.totalUserYeti != undefined &&
    veYETIStaked.totalYeti != undefined
  ) {
    stakeShare =
      format(veYETIStaked.totalUserYeti.div(veYETIStaked.totalYeti)) * 100;
  } else {
    stakeShare = 0;
  }

  const [sendTransaction] = useTransactionFunction(
    "claim-pending-YETI",
    liquity.send.getVeYetiStakeReward.bind(liquity.send)
  );

  const {
    isOpen: isPoolRewardsOpen,
    onOpen: onPoolRewardsOpen,
    onClose: onPoolRewardsClose,
  } = useDisclosure();

  const yetiAPR: number = +(
    ((+String(reward) * 52 * 2) / format(totalYeti)) *
    100
  ).toFixed(3);

  return (
    <Box layerStyle="card" p="24px">
      <Box display="flex" w="full" alignItems="center">
        <Box
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          w="full"
        >
          <Text textStyle="subheading" color="gray.500">
            YETI accrued
          </Text>
          <Text textStyle="number_md" color="text-primary">
            {getNum(format(yetiEarned), 4)}
          </Text>
        </Box>
        {/* <StatColumn
          iconName="Bank"
          amount={`${getNum(format(yetiEarned), 4)} YETI`}
          description="Claimable Rewards"
        /> */}
        <Button
          ml="16px"
          variant="surface"
          disabled={format(yetiEarned) === 0 && true}
          fontSize={14}
          onClick={() => sendTransaction()}
        >
          Claim Rewards
        </Button>
      </Box>
      {/* <Box borderBottom="1px" borderColor="border" pb="16px">
        <Text textStyle="card_header">
          Staking Summary{" "}
        </Text>
        </Box>
      <Box display="flex" mb="16px" gap="24px">
        <StatColumn
          iconName="Bank"
          amount={`${getNum(totalStaked, 3)} YETI`}
          description="Total Staked"
        />
        <StatColumn
          iconName="MoneyStack"
          amount={
            stakeShare >= 0.001 ? `${getNum(stakeShare, 3)}%` : "< 0.001%"
          }
          description="Staking Share"
        />
        <StatColumn
          iconName="MoneyStack"
          amount={`${getNum(yetiAPR, 3)}%`}
          color={"green.500"}
          description="YETI Reward APR"
        />
        <StatColumn
          iconName="MoneyStack"
          amount={`${getNum(
            format(
              getNewEstimatedWeeklyRewardsAmount(
                0,
                yetiStakedOnFarm,
                format(reward),
                true,
                format(totalYeti)
              )
            ),
            3
          )} YETI`}
          description="Weekly Reward Rate"
        />
      </Box> */}
    </Box>
  );
};

export default VEEmissions;
